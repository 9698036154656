import { ModalContext } from 'HOCs/withModalProvider'
import classNames from 'classnames'
import Icon from 'components/Icons/Icon'
import { MODAL_TYPES } from 'config/constants/constants'
import { status } from 'config/constants/lessonStatus'
import ROUTES from 'config/constants/routes'
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import { handleLessonsExpiredDate } from 'utils/handleExpiredDate'

const LessonPreview = memo((props) => {
  const {
    name,
    accessStatus,
    expiredAt,
    image,
    description,
    id,
    slug,
    previousLessonSlug,
    buyLesson,
    isDashboardPreview,
    lastViewedLessonsId,
    isWatched,
    createdAt,
  } = props
  const { t, i18n } = useTranslation()
  const { onOpen: openModal } = useContext(ModalContext)
  // Add a new property to check if the lesson is new
  const daysSinceCreated =
    (new Date().getTime() - new Date(createdAt).getTime()) /
    (1000 * 60 * 60 * 24)

  // Check if the lesson was created within the last 30 days
  const isNew = daysSinceCreated <= 30
  const availablePreview =
    accessStatus === status.available && !isDashboardPreview
  const isCurrentLesson = lastViewedLessonsId === id
  const testingRequiredPreview =
    accessStatus === status.testingRequired && !isDashboardPreview
  const purchaseRequiredPreview =
    accessStatus === status.purchaseRequired && !isDashboardPreview
  const dashboardPreview =
    (accessStatus === status.available ||
      accessStatus === status.testingRequired) &&
    isDashboardPreview

  const lessonRef = useRef(null)

  useEffect(() => {
    if (isCurrentLesson && lessonRef.current) {
      lessonRef.current.scrollIntoView({
        behavior: 'smooth', // Optional: defines the transition animation
        block: 'nearest', // Optional: defines vertical alignment
        inline: 'start' // Optional: defines horizontal alignment
      })
    }
  }, [isCurrentLesson])

  const willBeAvailable = (() => {
    if (accessStatus === status.premiumRequired) {
      const sevenDaysAfterCreated = new Date(createdAt)
      sevenDaysAfterCreated.setDate(sevenDaysAfterCreated.getDate() + 7)
      return sevenDaysAfterCreated.toLocaleDateString(i18n.language, {
        day: 'numeric',
        month: 'long',
      })
    }
    return ''
  })()


  const navigate = useNavigate()

  const previewImage = useMemo(
    () => `${process.env.REACT_APP_API_URL}${image}`,
    [image]
  )

  const toRequiredTesting = useCallback(
    (event) => {
      event.stopPropagation()
      navigate(`${ROUTES.lessonDetail}/${previousLessonSlug}`)
    },
    [previousLessonSlug]
  )

  const toLesson = useCallback(
    (event) => {
      event.stopPropagation()
      navigate(`${ROUTES.lessonDetail}/${slug}`)
    },
    [slug]
  )

  switch (true) {
  case availablePreview: {
    return (
      <article
        className="article other-pos"
        ref={isCurrentLesson ? lessonRef : null}
      >
        <div className={classNames('article__cnt', {
          isCurrent: isCurrentLesson,
          watched: isWatched && !isCurrentLesson
        })}
        onClick={toLesson}
        >
          <div className={'article__img-wrap pointer-events_none'}>
            <img
              src={previewImage}
              width="295"
              height="176"
              alt="Lesson preview"
            />
            {isNew && <div className="new-badge">NEW</div>}
          </div>
          <div className="article__right-side pointer-events_none">
            <div className="article__wrap">
              <div className="article__title title">
                <h3>{name}</h3>
              </div>
              <div className="article__desc">
                <ReactMarkdown>{description}</ReactMarkdown>
              </div>
            </div>
            <div className="status status_available">
              {handleLessonsExpiredDate(expiredAt, t)}
            </div>
          </div>
        </div>
      </article>
    )
  }
  case testingRequiredPreview: {
    return (
      <article className="article other-pos blocked">
        <div className="article__cnt" onClick={toLesson}>
          <div className="article__img-wrap ">
            <div className="blocked-icon">
              <Icon blocked />
            </div>
            <img
              src={previewImage}
              width="295"
              height="176"
              alt="Lesson preview"
            />
            {isNew && <div className="new-badge">NEW</div>}
          </div>
          <div className="article__right-side">
            <div className="article__wrap">
              <div className="article__title title">
                <h3>{name}</h3>
              </div>
              <p className="article__desc">{description}</p>
            </div>
            <button
              type="button"
              className="status status_get-tested"
              onClick={toRequiredTesting}
            >
              <Trans i18nKey="lesson-preview-testing-required" />
            </button>
          </div>
        </div>
      </article>
    )
  }
  case purchaseRequiredPreview: {
    return (
      <article className="article blocked unpaid">
        <div className="article__cnt">
          <div className="article__img-wrap">
            <div className="blocked-icon">
              <Icon blocked />
            </div>
            <img
              src={previewImage}
              width="295"
              height="176"
              alt="Lesson preview"
            />
            {isNew && <div className="new-badge">NEW</div>}
          </div>
          <div className="article__right-side">
            <div className="article__wrap">
              <div className="article__title title">
                <h3>{name}</h3>
              </div>
              <p className="article__desc">{description}</p>
            </div>
            <div className="article__btn-wrap">
              <button
                type="button"
                className="btn btn_shamrock"
                onClick={buyLesson}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ marginRight: '8px' }}
                >
                  <circle cx="9" cy="21" r="1"></circle>
                  <circle cx="20" cy="21" r="1"></circle>
                  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 1.98-1.64L23 4H6"></path>
                </svg>
                <Trans i18nKey="word-buy" />
              </button>
            </div>
          </div>
        </div>
      </article>
    )
  }
  case dashboardPreview: {
    return (
      <article className="article" onClick={toLesson}>
        <div
          className={`article__img-wrap onDashboard${isCurrentLesson ? ' isCurrent' : ''
          }`}
        >

          <img src={previewImage} width="295"
            height="176" alt="" />
          {isNew && <div className="new-badge">NEW</div>}
        </div>

        <div className="article__right-side">
          <div className="article__title title">
            <h3>{name}</h3>
          </div>
          <p className="article__desc">{description}</p>
          {accessStatus === status.available && (
            <div className="status status_available">
              {handleLessonsExpiredDate(expiredAt, t)}
            </div>
          )}
          {accessStatus === status.testingRequired && (
            <button
              type="button"
              className="status status_get-tested"
              onClick={toRequiredTesting}
            >
              {t('lesson-preview-testing-required')}
            </button>
          )}

        </div>
      </article>
    )
  }
  default:
    return (
      <article className="article" onClick={
        (e) => { openModal(e, MODAL_TYPES.no_subscription) }
      }>
        <div className={'article__img-wrap onDashboard'}>
          <img src={previewImage} width="400" height="239" alt="" />

          <div className="blocked-icon">
            <Icon blocked />
            {isNew && <div className="new-badge">NEW</div>}
          </div>
        </div>

        <div className="article__right-side">
          <div className="article__wrap">
            <div className="article__title title">
              <h3>{name}</h3>
            </div>
            <p className="article__desc">{description}</p>
            <div className="status status_unavailable">
              {accessStatus === status.premiumRequired ? (
                t('lesson-preview-premium-required', { date: willBeAvailable })) :
                (handleLessonsExpiredDate(expiredAt, t)
                )}
            </div>
          </div>
        </div>
      </article >
    )
  }
})

export default LessonPreview
