export const fetchQuery = async (key) => {
  try {
    const response = await fetch(`https://botfather.dev/webhook/user_rating/${key}`
    )

    const data = await response.json()
    console.log(data)
    return data

  } catch (error) {
    console.log(error)
  }
}