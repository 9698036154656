import React from 'react'
import { useIsFetching } from 'react-query'
import Preloader from 'components/Preloader/Preloader'

const withPreloader = () => (Component) => {
  return (props) => {
    const isFetching = Boolean(useIsFetching())

    return (
      <>
        {isFetching && <Preloader />}
        <Component {...props} />
      </>
    )
  }
}

export default withPreloader
