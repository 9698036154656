import React, { memo, useContext } from 'react'
import { ModalContext } from 'HOCs/withModalProvider'
import { MODAL_TYPES } from 'config/constants/constants'
import {Trans, useTranslation} from 'react-i18next'

const HeaderNoAuth = memo(() => {
  const { onOpen: openModal } = useContext(ModalContext)
  return (
    <header className="header-not-authorized">
      <div className="container header-not-authorized__container">
        <div className="btns-wrap">
          <button type="button" className="btn" onClick={(event) => openModal(event, MODAL_TYPES.login)}>
            <Trans i18nKey="login"/>
          </button>

          <button type="button" className="btn btn_pattensBlue" onClick={(event) => openModal(event, MODAL_TYPES.signin)}>
            <Trans i18nKey="registration"/>
          </button>
        </div>
      </div>
    </header>
  )
})

export default HeaderNoAuth
