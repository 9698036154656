import { ModalContext } from 'HOCs/withModalProvider'
import { MODAL_TYPES } from 'config/constants/constants'
import { TariffsOptions } from 'pages/Tariffs/Tariffs'
import React, { forwardRef, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TariffSection = forwardRef((props, ref) => {
  const { registrationData, tariffs } = props
  const { t } = useTranslation()
  const { onOpen: openModal } = useContext(ModalContext)
  const [selectedBillingCycle, setSelectedBillingCycle] = useState('weekly')
  return (
    <section className="registration section-padding" ref={ref}>
      <div className="registration__container container">
        <div className="registration__title title">
          <h2>{registrationData.title}</h2>
        </div>
        <div className="registration__text" dangerouslySetInnerHTML={{ __html: t(registrationData.description) }} />
        {
          tariffs && (
            <TariffsOptions
              tariffs={tariffs}
              selectedBillingCycle={selectedBillingCycle}
              setSelectedBillingCycle={setSelectedBillingCycle}
              handleBuyTariff={(event) => openModal(event, MODAL_TYPES.signin)}
            />)
        }
      </div>
    </section>
  )
})

export default TariffSection