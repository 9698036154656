import { useQuery } from 'react-query'
import { useApi } from 'services/api/index'
import { newsKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetNews = (page, numberItems) => {
  const { news } = useApi()

  return useQuery(newsKeys.news({ page, numberItems }), () => news.getNews(page, numberItems), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    keepPreviousData: true
  })
}

export const useGetNewsDetails = (slug) => {
  const { news } = useApi()

  return useQuery(newsKeys.newDetails(slug), () => news.getNewsDetails(slug), {
    select: (res) => res.data.data,
    onError: (error) => logger(error),
    keepPreviousData: true
  })
}
