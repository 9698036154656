export const lessonVideoUrl = (id, language) => {
  if (language) {
    return `${process.env.REACT_APP_API_URL}/api/lessons/${id}/video?language=${language}`
  } else {
    return `${process.env.REACT_APP_API_URL}/api/lessons/${id}/video`
  }
}

export const streamVideoUrl = (id) => {
  return `${process.env.REACT_APP_API_URL}/api/streams/${id}/video`
}
