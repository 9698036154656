import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Input = memo((props) => {
  const {
    className,
    title,
    placeholder,
    id,
    name,
    type = 'text',
    value,
    onChange,
    hasError,
    errorMessage,
  } = props

  return (
    <div className="data__item">
      {title && <p>{title}</p>}
      <div className={classNames('input-wrap', { error: hasError })}>
        <input
          className={className}
          placeholder={placeholder}
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
        />
        {hasError && <div className="error__text">{errorMessage}</div>}
      </div>
    </div>
  )
})

Input.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
}

Input.defaultProps = {
  placeholder: '',
  value: '',
}

export default Input
