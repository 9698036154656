import {useMutation, useQuery} from 'react-query'
import {useApi} from 'services/api/index'
import {lessonsKeys} from 'services/queryClient/queryKeys'
import logger from 'services/logger'
import {useNavigate} from 'react-router-dom'
import ROUTES from 'config/constants/routes'

export const useGetLessons = (params) => {
  const {lessons} = useApi()

  return useQuery(lessonsKeys.lessons(params), () => lessons.getLessons(params), {
    select: (res) => res.data.data,
    onError: (error) => logger(error),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: localStorage.getItem('token') ? true : false
  })
}

export const useGetLessonsById = (id) => {
  const {lessons} = useApi()

  return useQuery(lessonsKeys.lessonsById(id), () => lessons.getLessonsById(id), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: id ? true : false
  })
}

export const useGetLesson = (slug) => {
  const {lessons} = useApi()

  return useQuery(lessonsKeys.lesson(slug), () => lessons.getLesson(slug), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: localStorage.getItem('token') ? true : false
  })
}


export const useGetTestResults = (nextLessonSlug, onTestPassed) => {
  const navigate = useNavigate()
  const {lessons} = useApi()

  return useMutation(lessons.getTestResults, {
    retry: false,
    onError: (error) => logger(error),
    onSuccess: async (res) => {
      if (res?.data?.allCorrect === true) {
        // Call the onTestPassed function if the test results are successful
        if (typeof onTestPassed === 'function') {
          onTestPassed()
        }

        // Redirect the user to the next lesson
        setTimeout(() => {
          navigate(`${ROUTES.lessonDetail}/${nextLessonSlug}`)
        }, 1000)
      }
    },
  })
}


export const useBuyLesson = () => {
  const {lessons} = useApi()

  return useMutation(lessons.buyLesson, {
    retry: false,
    onError: (error) => logger(error)
  })
}
