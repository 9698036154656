import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from 'config/constants/routes'
import useWindowDimension from 'hooks/useWindowDimension'
import {Trans, useTranslation} from 'react-i18next'

const LinkTelegramNotification = memo(() => {
  const { width } = useWindowDimension()
  return (
    <div className="link-telegram-notification">
      <Trans i18nKey="link-telegram-notification-text"/>

      {width >= 600 ? (
        <Link to={`${ROUTES.settings}`} className="btn btn_pattensRed">
          <Trans i18nKey="link-telegram-notification-button"/>
        </Link>
      ) : (
        <Link to={`${ROUTES.settings}`} className="btn-arrow btn-arrow_right" />
      )}
    </div>
  )
})

export default LinkTelegramNotification
