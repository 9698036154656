import ROUTES from 'config/constants/routes'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import TariffArrowSvg from './TariffArrowSvg'


const StarSvg = ({ fillColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={fillColor}
    width="16"
    height="16"
  >
    <path
      d="M9.049 2.927c.158-.361.542-.361.7 0l1.535 3.516a1 1 0 00.753.546l3.788.32a.5.5 0 01.277.848l-2.862 2.634a1 1 0 00-.291.944l.682 3.814a.5.5 0 01-.725.593l-3.343-1.814a1 1 0 00-.934 0l-3.343 1.813a.5.5 0 01-.725-.592l.682-3.814a1 1 0 00-.291-.944L.693 8.156a.5.5 0 01.277-.848l3.788-.32a1 1 0 00.753-.546L7.049 2.927z"
    />
  </svg>
)


const TariffLink = ({ currentTariff, setIsMenuOpen }) => {
  const { t } = useTranslation()

  return (
    <div className="tariff-wrap">
      <Link to={ROUTES.tariffs} className="tariff tariff-wrap__tariff" onClick={() => setIsMenuOpen(false)}>
        <p className="tariff__name">{
          currentTariff?.name || t('tariffs-header-epmty-name')

        }</p>
      </Link>
      <Link to={ROUTES.tariffs} onClick={() => setIsMenuOpen(false)} className="tariff-wrap__link">
        <TariffArrowSvg />
        <div className="btn btn_shamrock tariff-wrap__btn"
          onClick={() => setIsMenuOpen(false)}>
          <Trans i18nKey="tariffs-header-button" />
        </div>
      </Link>
    </div>
  )
}


export default TariffLink