import React, { memo, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useWindowDimension from 'hooks/useWindowDimension'
import ROUTES from 'config/constants/routes'
import {Trans, useTranslation} from 'react-i18next'


const Footer = memo(() => {
  const ref = useRef()
  const windowDimensions = useWindowDimension()
  const [placeholderHeight, setPlaceholderHeight] = useState()

  useEffect(() => {
    setPlaceholderHeight(ref?.current?.clientHeight)
  }, [windowDimensions.width])

  return (
    <>
      <div className="footer_placeholder" style={{ height: placeholderHeight }} />
      <footer className="footer section-padding" ref={ref}>
        <div className="footer__container container">
          <a href="mailto:latandcosta@gmail.com" className="footer__mail">
            latandcosta@gmail.com
          </a>
          <div className="links">
            <div className="support">
              <Link to={ROUTES.faq}>FAQ</Link>
              <Link to={ROUTES.termsOfService}>
                <Trans i18nKey="terms-of-service"/>
              </Link>
              <Link to={ROUTES.refundPolicy}>
                <Trans i18nKey="refund-policy"/>
              </Link>
              <Link to={ROUTES.privacyPolicy}>
                <Trans i18nKey="privacy-policy"/>
              </Link>
              <Link to={ROUTES.paymentSecurityPolicy}>
                <Trans i18nKey="payment-security-policy"/>
              </Link>
            </div>
            <div className="social-networks">
              <div className="social-networks__top">
                <a href="https://t.me/latand" target="_blank" rel="noreferrer">
                  Telegram
                </a>
                <a href="https://t.me/botfatherdev" target="_blank" rel="noreferrer">
                  <Trans i18nKey="telegram-channel"/>
                </a>
              </div>
              <div className="social-networks__bottom">
                <a href="https://t.me/latand" target="_blank" rel="noreferrer">
                  <Trans i18nKey="support"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
})

export default Footer
