import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { Footer } from 'components/Footer/index'
import { Header } from 'components/Header/index'
import AboutAuthorSection from './AboutAuthorSection'
import AdvertisingSection from './AdvertisingSection'
import CourseProve from './CourseProve'
import IntroSection from './IntroSection'
import LevelSection from './LevelSection'
import ReviewsSection from './ReviewsSection'
import RoadmapSection from './RoadmapSection'
import TariffSection from './TariffSection'

import { ModalContext } from 'HOCs/withModalProvider'
import withNoAuth from 'HOCs/withNoAuth'
import TrustBox from 'components/TrustPilot/TrustPilotWidget'
import { useGetLandingData } from 'hooks/api/useLanding'
import { useSearchParams } from 'react-router-dom'
import { getInvitingCode } from 'utils/getInvitingCode'

function Main() {
  const { data } = useGetLandingData()
  const {
    cta,
    courseProve,
    intro,
    level,
    levels,
    registration,
    roadmap,
    teacherInfo,
    tariffs,
    comments,
    review,
  } = data ?? {}

  const { onOpen: openModal, setData: setData } = useContext(ModalContext)

  const location = useLocation()

  const adRef = useRef()
  const tariffRef = useRef()
  const authorRef = useRef()
  const reviewRef = useRef()

  const [searchParams, setSearchParams] = useSearchParams()
  const utm_source = searchParams.get('utm_source')
  const utm_medium = searchParams.get('utm_medium')

  const scrollTo = (name, closeMenu) => {
    const config = { behavior: 'smooth' }
    if (name === 'ad') adRef.current.scrollIntoView(config)
    if (name === 'tariff') tariffRef.current.scrollIntoView(config)
    if (name === 'author') authorRef.current.scrollIntoView(config)
    if (name === 'review') reviewRef.current.scrollIntoView(config)
    closeMenu()
  }

  useEffect(() => {
    const { hasInvitingCode, invitingCode } = getInvitingCode(location)
    if (hasInvitingCode && data) {
      setData({ invitingCode })
    }
    if (utm_source) {
      setData({ utm_source })

      if (utm_medium) {
        setData({ utm_medium, utm_source })
      }
    }
    // openModal(null, MODAL_TYPES.signin)
  }, [data, location])

  return (
    <>
      <Header onScrollTo={scrollTo} />
      {data && (
        <div className="main">
          <TrustBox />
          <IntroSection data={intro} />
          <LevelSection title={level} levels={levels} />
          <CourseProve data={courseProve} />
          <RoadmapSection data={roadmap} />
          <TariffSection
            registrationData={registration}
            ref={tariffRef}
            tariffs={tariffs}
          />
          <AboutAuthorSection data={teacherInfo} ref={authorRef} />
          <ReviewsSection ref={reviewRef} review={review} comments={comments} />
          <AdvertisingSection data={cta} ref={adRef} />
        </div>
      )}
      <Footer />
    </>
  )
}

export default withNoAuth()(Main)
