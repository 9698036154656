import React, { forwardRef, useContext } from 'react'

import { ModalContext } from 'HOCs/withModalProvider'
import planeImg from 'assets/images/main-page/order-block/plane.png'
import { MODAL_TYPES } from 'config/constants/constants'
import { useTranslation } from 'react-i18next'

const AdvertisingSection = forwardRef((props, ref) => {
  const { onOpen: openModal } = useContext(ModalContext)
  const { t } = useTranslation()

  return (
    <section className="order-block" ref={ref}>
      <div className="order-block__container container">
        <div className="order-block__img-wrap">
          <img src={planeImg} width="308" height="178" alt="plane" />
        </div>
        <div className="order-block__cnt">
          <div className="order-block__title title">
            <h3>{t(props.data.title)}</h3>
          </div>
          <div dangerouslySetInnerHTML={{ __html: t(props.data.description) }} />
        </div>
        <div className="order-block__btn-wrap">
          <button type="button" className="btn btn_border-pacificBlue" onClick={(event) => openModal(event, MODAL_TYPES.ad)}>
            {t(props.data.buttonName)}
          </button>
        </div>
      </div>
    </section>
  )
})

export default AdvertisingSection
