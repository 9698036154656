import React from 'react'
import {
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { compose } from 'redux'
import './assets/styles/index.scss'

import withCommonData from 'HOCs/withCommonData'
import withModalProvider from 'HOCs/withModalProvider'
import withPreloader from 'HOCs/withPreloader'
import withSettingsProvider from 'HOCs/withSettingsProvider'
import ROUTES from 'config/constants/routes'
import history from 'services/history'

import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import AffiliateProgram from 'pages/AffiliateProgram/AffiliateProgram'
import Auth from 'pages/Auth/Auth'
import Balance from 'pages/Balance/Balance'
import Coupon from 'pages/Coupon/Coupon'
import CourseProgram from 'pages/CourseProgram/CourseProgram'
import Dashboard from 'pages/Dashboard/Dashboard'
import FAQ from 'pages/FAQ/FAQ'
import TestComponent from 'pages/IntroTest/IntroTest'
import VideoPage from 'pages/IntroTest/Onboarding'
import Lesson from 'pages/Lesson/Lesson'
import Lessons from 'pages/Lessons/Lessons'
import Main from 'pages/Main/Main'
import News from 'pages/News/News'
import NewsDetails from 'pages/NewsDetails/NewsDetails'
import NotFound from 'pages/NotFound/NotFound'
import Notification from 'pages/Notification/Notification'
import PaymentSecurityPolicy from 'pages/PaymentSecurityPolicy/PaymentSecurityPolicy'
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy'
import Ratings from 'pages/Rating/Ratings/Ratings'
import RefundPolicy from 'pages/RefundPolicy/RefundPolicy'
import Setting from 'pages/Setting/Setting'
import StreamDetail from 'pages/StreamDetail/StreamDetail'
import Streams from 'pages/Streams/Streams'
import Tariffs from 'pages/Tariffs/Tariffs'
import TermsOfService from 'pages/TermsOfService/TermsOfService'
import Webinars from 'pages/Webinars/Webinars'
import { useTranslation } from 'react-i18next'
import './i18n'
import moment from './services/moment'

function App() {
  // if (process.env.NODE_ENV === 'development') {
  // localStorage.setItem('token', process.env.REACT_APP_AUTH_TOKEN)
  // }
  const { i18n } = useTranslation()
  moment.locale(i18n.language)

  return (
    <HistoryRouter history={history}>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.main}>
          <Route path="" element={<Main />} />
          <Route path=":code" element={<Main />} />
        </Route>

        <Route path={ROUTES.faq} element={<FAQ />} />
        <Route path={ROUTES.termsOfService} element={<TermsOfService />} />
        <Route path={ROUTES.refundPolicy} element={<RefundPolicy />} />
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />
        <Route
          path={ROUTES.paymentSecurityPolicy}
          element={<PaymentSecurityPolicy />}
        />
        <Route path={ROUTES.courseProgram} element={<CourseProgram />} />

        <Route path={ROUTES.dashboard} element={<Dashboard />} />
        <Route path={ROUTES.lessons}>
          <Route path="" element={<Lessons />} />
          <Route path=":type" element={<Lessons />} />
        </Route>
        <Route path={`${ROUTES.lessonDetail}/:slug`} element={<Lesson />} />
        <Route path={ROUTES.tariffs} element={<Tariffs />} />
        <Route path={ROUTES.settings} element={<Setting />} />
        <Route path={ROUTES.rating} element={<Ratings />} />

        <Route path={`${ROUTES.coupons}/:code`} element={<Coupon />} />
        <Route path={`${ROUTES.webinars}`} element={<Webinars />} />
        <Route path={`${ROUTES.news}`}>
          <Route index path="" element={<News />} />
          <Route path=":slug" element={<NewsDetails />} />
        </Route>
        <Route path={`${ROUTES.blog}`}>
          <Route index path="" element={<News />} />
          <Route path=":slug" element={<NewsDetails />} />
        </Route>
        <Route path={`${ROUTES.streams}`}>
          <Route index path="" element={<Streams />} />
          <Route path=":slug" element={<StreamDetail />} />
        </Route>

        <Route path={`${ROUTES.notification}`} element={<Notification />} />
        <Route
          path={`${ROUTES.affiliateProgram}`}
          element={<AffiliateProgram />}
        />
        <Route path={`${ROUTES.balance}`} element={<Balance />} />

        <Route path={ROUTES.introTest} element={<TestComponent />} />
        <Route path={ROUTES.onBoarding} element={<VideoPage />} />

        <Route path={ROUTES.auth} element={<Auth />} />
        <Route path={ROUTES.notFound} element={<NotFound />} />
        <Route
          path={ROUTES.noMatch}
          element={<Navigate replace to={ROUTES.notFound} />}
        />
      </Routes>
    </HistoryRouter>
  )
}

export default compose(
  withModalProvider(),
  withSettingsProvider(),
  withPreloader(),
  withCommonData()
)(App)
