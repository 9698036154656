import { useQuery } from 'react-query'
import { useApi } from 'services/api/index'
import { landingKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetLandingData = () => {
  const { landing } = useApi()

  return useQuery(landingKeys.landing(), () => landing.getLandingData(), {
    select: (res) => ({
      ...res?.data?.data,
      tariffs: res?.data?.tariffs,
      comments: res?.data?.reviews,
    }),
    onError: (error) => logger(error),
  })
}
