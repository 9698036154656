import React, { useState, useRef } from 'react'
import classNames from 'classnames'

import { useGetAffiliatePrograme, useGetAffiliateProgrameAbout, useTransferBonusMoney } from 'hooks/api/useAccount'
import useOutsideClick from 'hooks/useOutsideClick'
import useInputState, { normalizeOptions } from 'hooks/useInputState'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { NotAuthContent } from 'components/NoContent'
import Copy from 'components/Copy/Copy'

function AffiliateProgram({ isAuth }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [inputValue, handleOnChange, setInputValue] = useInputState(normalizeOptions.onlyNumbers)

  const openPopup = () => setIsPopupOpen(true)
  const closePopup = () => setIsPopupOpen(false)

  const onSuccessTransfer = () => {
    closePopup()
    setInputValue('')
  }

  const { data: affiliateProgram } = useGetAffiliatePrograme()
  const { data: affiliateProgramAbout } = useGetAffiliateProgrameAbout()
  const { mutate: transferBonusMoney } = useTransferBonusMoney(onSuccessTransfer, closePopup)

  const affiliateLink = `${process.env.REACT_APP_API_URL}/inviting_code=${affiliateProgram?.invitingCode}`

  const handleBonusTransfer = () => {
    if (inputValue === null || inputValue === '') return
    transferBonusMoney({ amount: inputValue })
  }

  const ref = useRef()
  useOutsideClick(ref, () => {
    if (isPopupOpen) closePopup()
  })

  return isAuth ? (
    <section className="affiliate-program">
      <div className="container">
        <div className="title">
          <h1>Партнерская программа</h1>
        </div>

        <Copy label={affiliateLink} value={affiliateLink} />

        {affiliateProgramAbout && (
          <div className="affiliate-program__desc" dangerouslySetInnerHTML={{ __html: affiliateProgramAbout.description }} />
        )}

        <div className="affiliate-program__inf">
          <p>
            К-сть <strong>{affiliateProgram?.referralsCount}</strong>
          </p>
          <p>
            Рефералов
            <strong>
              {affiliateProgram?.referralBonuses} <span>$</span>
            </strong>
          </p>
        </div>

        <div className="btn-wrap">
          <button type="button" onClick={openPopup} className="btn btn_pattensBlueStrong">
            Перевод на баланс
          </button>
        </div>
      </div>

      <div className={classNames('pop-up', { active: isPopupOpen })}>
        <div className="pop-up__wrap" ref={ref}>
          <div className="title pop-up__title">
            <h2>Перевод бонусов на баланс</h2>
          </div>
          <div className="pop-up__cnt">
            <p>
              Вы можете перевести деньги с бонусного счета на основной баланс. Введите сумму и нажмите <strong>Перевести</strong>
            </p>
          </div>
          <div className="entry-field">
            <p>Сумма</p>
            <div className="input-wrap">
              <input type="text" inputMode="numeric" value={inputValue} onChange={handleOnChange} />
              <span className="input-wrap__currency">$</span>
            </div>
          </div>
          <div className="btns-wrap">
            <button type="button" onClick={closePopup}>
              Отмена
            </button>
            <button type="button" className="btn btn_pattensBlue" onClick={handleBonusTransfer}>
              Перевести
            </button>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <NotAuthContent />
  )
}

export default withDashboardLayout()(AffiliateProgram)
