import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { useGetRefundPolicyData } from 'hooks/api/useOtherRequests'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { articleMarkdown } from 'config/markdownConfig'

function RefundPolicy() {
  const { data } = useGetRefundPolicyData()

  return (
    <>
      {data && (
        <section className="article-details">
          <div className="article-details__container container">
            <div className="article-details__title title">
              <h1>{data.data.title}</h1>
            </div>
            <time className="article-details__public-date public-date">{data.data.date}</time>
            <div className="article-desc">
              <ReactMarkdown
                components={articleMarkdown}
                remarkPlugins={[remarkToc]}
                rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                children={data.data.content}
                // skipHtml={true}
                linkTarget={(href, children, title) => {
                  if (href[0] !== '#') return '_blank'
                }}
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default withDashboardLayout()(RefundPolicy)
