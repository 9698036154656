import React, { memo } from 'react'
import { Link } from 'react-router-dom'

const FooterWebinar = memo(() => {
  return (
    <footer className="footer-webinar">
      <div className="container">
        <div className="footer-webinar__title title">
          <h2>Записаться на бесплатный вебинар</h2>
          gi{' '}
        </div>
        <form className="recording">
          <div className="recording__fields-wrap">
            <div className="recording__wrap">
              <p>Email</p>
              <div className="input-wrap">
                <input type="email" />
              </div>
            </div>
            <div className="recording__wrap">
              <div className="input-wrap">
                <input type="email" />
              </div>
            </div>
          </div>
          <div className="recording__btn-wrap">
            <button type="submit" className="btn btn_pattensBlueOpacity">
              Хочу на вебинар
            </button>
          </div>
        </form>
      </div>
    </footer>
  )
})

export default FooterWebinar
