import { useQuery, useMutation } from 'react-query'
import { useApi } from 'services/api/index'
import { webinarsKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetWebinars = () => {
  const { webinars } = useApi()

  return useQuery(webinarsKeys.webinars(), () => webinars.getWebinars(), {
    select: (res) => res.data.data,
    onError: (error) => logger(error),
    // keepPreviousData: true,
    // refetchOnWindowFocus: false,
    // enabled: localStorage.getItem('token') ? true : false,
  })
}
