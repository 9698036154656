export const MODAL_TYPES = {
  login: 'LOGIN',
  signin: 'SIGNIN',
  ad: 'AD',
  invoice: 'INVOICE',
  buyLesson: 'BUY_LESSON',
  search: 'SEARCH',
  loader: 'LOADER',
  no_subscription: 'NO_SUBSCRIPTION',
}

export const PROFILE_FORM_FIELD = {
  firstName: { id: 'firstName', name: 'firstName' },
  lastName: { id: 'lastName', name: 'lastName' },
  email: { id: 'email', name: 'email' },
  timezone: { id: 'timezone', name: 'timezone' },
  birthDay: { id: 'birthDay', name: 'birthDay' },
  birthMonth: { id: 'birthMonth', name: 'birthMonth' },
  birthYear: { id: 'birthYear', name: 'birthYear' },
  language: { id: 'language', name: 'language' },
}

export const LANGUAGES = [
  {
    label: 'Українська',
    value: 'uk-UA'
  },
  {
    label: 'Русский',
    value: 'ru-RU'
  },
]


export const TARIFF_KEYS = {
  basic: 'basic-tariff',
  premium: 'premium-tariff',
}
