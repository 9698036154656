const ROUTES = {
  main: '/',
  auth: '/auth/:service/callback',

  dashboard: '/dashboard',
  lessons: '/dashboard/lessons',
  lessonDetail: '/dashboard/lesson',
  tariffs: '/dashboard/tariffs',
  settings: '/dashboard/settings',
  rating: '/dashboard/rating',

  faq: '/blog/faq',
  termsOfService: '/blog/terms-of-service',
  refundPolicy: '/blog/refund-policy',
  privacyPolicy: '/blog/privacy-policy',
  paymentSecurityPolicy: '/blog/payment-security-policy',
  courseProgram: '/blog/course-program',

  news: '/news',
  newsDetail: '/news',
  blog: '/blog',
  notification: '/notification',
  affiliateProgram: '/affiliate-program',
  balance: '/balance',
  webinars: '/webinars',
  streams: '/streams',
  coupons: '/coupons',
  introTest: '/intro-test',
  onBoarding: '/on-boarding',

  notFound: '/404',
  noMatch: '*',
}

export default ROUTES
