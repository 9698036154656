export const normalizeUserName = (firstName, lastName, email) => {
  const name = {
    firstName: '',
    lastName: '',
    fullName: ''
  }

  if (firstName && lastName && firstName.length !== 0 && lastName.length !== 0) {
    name.firstName = firstName
    name.lastName = lastName
    name.fullName = `${firstName} ${lastName}`
  }
  if (firstName && !lastName && firstName.length !== 0) {
    name.firstName = firstName
    name.fullName = firstName
  }
  if (!firstName && lastName && lastName.length !== 0) {
    name.lastName = lastName
    name.fullName = lastName
  }
  if (!firstName && !lastName && email && email.length !== 0) {
    const nameFromEmail = email.substring(0, email.indexOf('@'))
    name.firstName = nameFromEmail
    name.fullName = nameFromEmail
  }

  return name
}
