import Modal from 'components/Modal/Modal'
import { default as React, createContext, useCallback, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'

const ModalContext = createContext(null)

const withModalProvider = () => (Component) => {
  return function (props) {
    const [isOpen, setIsOpen] = useState(false)
    const [type, setType] = useState()
    const [data, setData] = useState() //additional data
    const [onSubmit, setSubmitCallback] = useState() // submit callback

    const onClose = useCallback(() => setIsOpen(false), [setIsOpen])

    const onOpen = useCallback(
      (event, type) => {
        if (event) event.stopPropagation()
        setType(type)
        setIsOpen(true)
      },
      [setType, setIsOpen]
    )

    const nodeRef = useRef(null)

    return (
      <ModalContext.Provider value={{ onClose, onOpen, setData, setSubmitCallback }}>
        <Component {...props} />
        <Transition in={isOpen} timeout={500} nodeRef={nodeRef}>
          {(state) => <Modal state={state} type={type} isOpen={isOpen} onClose={onClose} data={data} onSubmit={onSubmit} />}
        </Transition>
      </ModalContext.Provider>
    )
  }
}

export { ModalContext, withModalProvider as default }

