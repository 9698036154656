import { Slide, toast } from 'react-toastify'

const notifyStatus = {
  success: 'success',
  error: 'error'
}

const useToastNotification = (pauseOnHover, closeOnClick) => {
  const notify = (label, status) =>
    toast(<div className="toast-label">{label}</div>, {
      className: `custom-toast-notification custom-toast-notification_${status}`,
      position: 'top-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: closeOnClick || true,
      pauseOnHover: pauseOnHover || false,
      draggable: false,
      progress: 0,
      transition: Slide,
      closeButton: false,
      pauseOnFocusLoss: false
    })

  return { notify, notifyStatus }
}

export default useToastNotification
