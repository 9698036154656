import { transformApiValidation } from 'utils/transformApiValidation'

function handleRequest(config) {
  return config
}

function handleResponse(response, onLogout, on404, notifyOnSuccess, notifyOnError) {
  const errors = transformApiValidation(response.data)

  const handleErrors = (errors) => errors.forEach((error) => notifyOnError(error.message))

  // no error
  if (response.status < 400) {
    if (response.status === 200 && response.data.message) {
      notifyOnSuccess(response.data.message)
    }
    return response
  }

  switch (response.status) {
  // validation error
  case 400:
    handleErrors(errors)

    return Promise.reject({
      data: response,
      status: response.status
    })

    // auth error
  case 401:
    onLogout()

    return Promise.reject({
      data: response,
      status: response.status
    })

  case 403:
    handleErrors(errors)

    return Promise.reject({
      data: response,
      status: response.status
    })

  case 404:
    return on404()

    // user action error
  case 422:
    handleErrors(errors)

    return Promise.reject({
      data: response,
      status: response.status
    })

    // common error
  default:
    handleErrors(errors)

    return Promise.reject({
      data: response,
      status: response.status
    })
  }
}

export { handleRequest, handleResponse }
