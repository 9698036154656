import axios from 'axios'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  'uk-UA': {
    translation: {
      'dashboard-news-title': 'Новини та інформація',
      'dashboard-news-list': 'Список новин',
      'link-telegram-notification-text':
        'Необхідно прив\'язати Telegram аккаунт',
      'link-telegram-notification-button': 'Прив\'язати',
      'dashboard-go-to-lessons': 'До уроків',
      'word-lessons': 'уроки',
      'my-balance': 'Мій баланс',
      'pay-text': 'Введіть суму поповнення:',
      'coupon-text-1': 'Вітаю! Ви отримуєте свою знижку в розмірі ',
      'coupon-activate-text': 'Для активации скидки нажмите кнопку',
      'word-activate': 'Активувати',
      'coupon-activate-text-2':
        'Щоб її активувати - Вам необхідно зареєструватися та авторизуватися на сайті. Після авторизації - натиснути кнопку',
      'coupon-text-2': 'Знижкою можна буде скористатися до ',
      'word-authorization': 'Авторизація',
      'lesson-testing-required':
        'Вам необхідно пройти тестування в попередніх уроках, щоб розблокувати цей урок',
      'lesson-next-lesson': 'Наступний урок',
      'lesson-previous-lesson': 'Попередній урок',
      'word-send': 'Відправити',
      'lesson-preview-testing-required': 'Пройдіть тестування',
      'word-buy': 'Купити',
      'expired-date-null':
        'Термін підписки минув. Для перегляду необхідно придбати тариф.',
      'dashboard-accessible': 'Доступно',
      'dashboard-accessible-until': 'Доступно до',
      'word-more': 'Детальніше',
      'error404-text-1':
        'Схоже щоось пішло не так! Сторінка, яку ви запитуєте, не існує.',
      'error404-text-2':
        'Можливо був неправильний запит, сторінка була видалена, або був введений неправильний адрес в адресній стрічці.',
      'error404-button': 'Повернутися на головну',
      'rating-members': 'Рейтинг Учасників',
      'rating-place-of': ' місце з ',
      'rating-points': 'Бали',
      'rating-portfolio': 'За портфоліо',
      'rating-progress': 'Прогрес',
      'word-change-photo': 'Змінити фото',
      'site-setting-custom-cursor': 'Кастомний курсор',
      'site-setting-dark-theme': 'Темна тема сайту',
      'site-setting-pycharm-coupon': 'Купон на Pycharm Pro:',
      'site-setting-pycharm-coupon-get': 'Отримати купон',
      'error-email': 'Невірний email',
      'profile-first-name': 'Ім\'я',
      'profile-last-name': 'Прізвище',
      'profile-timezone': 'Часовий пояс',
      'profile-birthday': 'День народження',
      'profile-save': 'Зберегти',
      'site-setting-unlink': 'Відв\'язати',
      'site-setting-change': 'Змінити',
      'site-setting-your-telegram-id': 'Твій Telegram ID',
      'site-setting-telegram-id': 'Telegram ID',
      'word-streams': 'Етери',
      'word-buy-access': 'Купити доступ',
      'subscribe-ether': 'Підписка на Етер',
      'buy-ether': 'Покупка Етера',
      'subscribe-ether-text-1':
        'Ви можете отримати доступ до цього етеру безкоштовно. У вас залишилося ще ',
      'subscribe-ether-text-2': ' безкоштовних етера. Для підписки натисніть',
      'word-subscribe': 'Підписатися',
      'buy-ether-text-1':
        'Ви можете купити будь-який етер. Вартість поточного етера вказана нижче. З вашого балансу буде знята вказана сума. Для покупки натисніть',
      'word-amount': 'Сума',
      'word-cancel': 'Скасувати',
      'access-purchased': 'Доступ придбано',
      'link-to-chat-with-ether': 'Посилання на чат з етером',
      'stream-was': 'Етер проходив {{date}}',
      'stream-starts': 'Початок {{date}}',
      'tariffs-title': 'Тарифи - що це?',
      'tariffs-text-1':
        'Тарифи - це пакети уроків та послуг, які ви можете придбати на нашому сайті.',
      'tariffs-text-2':
        'В залежності від тарифу, ви отримуєте доступ до уроків на різний період часу, а також різному рівню індивідуального супроводу.',
      'tariffs-text-3':
        'Чим вище тариф, тим довше ви можете користуватися уроками, більше додаткових матеріалів і більше підтримки від нашої команди ви отримуєте.',
      'tariffs-title-2': 'Як придбати тариф?',
      'tariffs-text-4':
        'Для того, щоб придбати тариф, вам необхідно вибрати один з запропонованих тарифів',
      'tariffs-text-5': '',
      'purchaseInstruction-6':
        'Після цього натисніть кнопку <1>Купити</1> під обраним тарифом.',
      'tariffs-learn-more': 'Детальніше',
      'tariffs-active': 'Активний',
      'tariffs-not-available': 'Не доступний',

      'terms-of-service': 'Публічна оферта',
      'refund-policy': 'Правила Повернення Коштів',
      'privacy-policy': 'Політика Конфіденційності',
      'payment-security-policy': 'Політика Безпеки Платежів',
      'telegram-channel': 'Канал в Telegram',
      support: 'Техпідтримка',
      'order-bot': 'Замовити бота',
      'course-author': 'Автор курсу',
      reviews: 'Відгуки',
      registration: 'Реєстрація',
      login: 'Увійти',
      'course-program': 'Програма курсу',
      'header-settings': 'Налаштування',
      'header-affiliate-program': 'Партнерська програма',
      'header-balance': 'Баланс',
      'header-logout': 'Вийти',
      'login-modal': 'Увійти з: ',
      'registration-modal': 'Зареєструйтесь з: ',
      'order-bot-modal': 'Вирішили замовити бота?',
      'order-bot-modal-text':
        'Складайте технічне завдання та відправляйте його ',
      'invoice-modal-text':
        'В новій вкладці відкрився рахунок на оплату, якщо цього не сталося, перейдіть за цим посиланням: {{link}}',
      'waiting-for-payment': 'Очікуємо оплату',
      'after-payment-refresh-page':
        'Після оплати оновіть сторінку, або зачекайте її автоматичного оновлення!',
      'lesson-cost': 'Вартість уроку ',
      'Щоб купити натисніть <1>Купити</1>. З вашого рахунку буде знята необхідна сума.':
        'Щоб купити натисніть <1>Купити</1>. З вашого рахунку буде знята необхідна сума.',
      'unauthorized-message':
        'Ця сторінка доступна тільки авторизованим користувачам! 😉',
      'return-to-main-page': 'Повернутися на стартову сторінку',
      'unauthorized-message-no-access':
        'У вас немає доступу до цієї сторінки! 😉',
      'notification-empty': 'У вас немає нових повідомлень',
      'notification-show-all': 'Показати все',

      'sidebar-main': 'Головна',
      'sidebar-lessons': 'До уроків',
      'sidebar-rating': 'Рейтинг',
      'sidebar-ethers': 'Етери',
      'sidebar-news': 'Новини',
      'tariff-upgrade': 'Покращити',
      'replenish-button': 'Поповнити',

      'my-rating-title': 'Мій рейтинг',
      'rating-total': 'Всього ',
      'notification-unread':
        'У вас {{unreadNotificationsNumber}} нових повідомлень',
      'notification-load-more': 'Завантажити ще',
      'profile-language': 'Мова сайту',
      'search-modal': 'Пошук',
      'search-placeholder': 'Введіть запит',
      'tariff-forever': '/назавжди',
      'tariff-monthly': '/місяць',
      'review-text-action': 'Залишити відгук',
      'review-text-section': 'Дивитися відгуки на',
      Send: 'Відправити',
      'Type a message...': 'Напишіть повідомлення...',
      Close: 'Закрити',
      'You need to buy a tariff to use this feature':
        'Вам необхідно придбати 3 тариф і вище, щоб скористатися цією функцією',
      'onboarding.title': 'Вітаю на курсі по розробці ботів!',
      'onboarding.returnLink':
        'Ви завжди можете повернутися до цієї сторінки за посиланням (натисніть на нього):',
      'onboarding.copiedLink': 'Посилання скопійовано в буфер обміну!',
      'onboarding.text': `
Тепер, коли ми з'ясували, наскільки ви володієте Python, 
давайте разом помандруємо порталом курсу. 

Тут ви знайдете безліч відеоуроків та посібників, 
які допоможуть вам. 
А якщо у вас виникнуть питання, то вам на допомогу прийде
**Штучний Інтелект**, або наша **команда підтримки**.

Наша структурована дорожня карта допоможе вам пройти кожен крок, 
від основ **механіки API ботів** до нюансів взаємодії з користувачами, 
побудови **Бази Даних та розгортання ботів на серверах**.

Ознайомтесь з наступними відео, щоб дізнатися більше про те, як
користуватися платформою:
`,
      'onboarding.button-dashboard': 'Перейти на головну',
      'onboarding.button-tariffs': 'Отримати повний доступ',
      'word-correct-answer': 'Вірна відповідь',
      'word-wrong-answer': 'Невірна відповідь',
      'tariffs-header-button': 'Тарифи',
      'tariffs-header-epmty-name': 'Придбати тариф',
      'no-subscription-modal': '😥 Підписка відсутня',
      'buy-subscription': 'Придбати від 1$',
      'no-subscription-modal-text': 'Для того, щоб отримати доступ до всіх уроків, вам необхідно придбати будь який тариф.',
      'coupon-activate': 'Активувати купон',
      'will-be-activated': 'Буде активовано {{date}}',
      'Базовые': 'Базові',
      'Дополнительные': 'Додаткові',
      'Бонусные': 'Бонусні',
      'Заработок в Ботах': 'Заробіток в Ботах',
      'Обучение Разработке Telegram Ботов': 'Навчання розробці Telegram-ботів',
      'Обучение Разработке <br /> Telegram Ботов': 'Навчання розробці <br /> Telegram-ботів',
      'Нужен бот для Telegram, но обучение не актуально?': 'Потрібен бот для Telegram, але навчання не актуальне?',
      'Тебе нужен этот курс если ты': 'Тобі потрібен цей курс, якщо ти',
      'Новичок': 'Початківець',
      'Новичок, но шаришь': 'Новачок, та з розумінням',
      'Продвинутый': 'Продвинутий',
      'Профи': 'Професіонал',
      'На моём курсе ты': 'На моєму курсі ти',
      'А что после курса?': 'А що після курсу?',
      'Автор курса': 'Автор курсу',
      'Что говорят ученики': 'Що говорять учні',
      'Хочешь научиться создавать ботов?': 'Хочеш навчитися створювати ботів?',
      'Также ты можешь заказать готового бота': 'Також ти можеш замовити готового бота',
      'Листай ниже': 'Прокручуй вниз',
      'Не знаешь ничего о создании ботов': 'Не знаєш нічого про створення ботів',
      'Только начал изучать язык программирования Python': 'Тільки почав вивчати мову програмування Python',
      'Горишь идеей и готов много учиться': 'Палаєш ідеєю і готовий багато вчитися',
      'Планируешь вырасти в продвинутого разработчика': 'Плануєш стати продвинутим розробником',
      'Что-то знаешь про разработку, но не углублялся': 'Щось знаєш про розробку, але не деталізувався',
      'Хочешь освоить тему полностью': 'Хочеш освоїти тему повністю',
      'Имеешь план по заработку в этой профессии': 'Маєш план заробітку в цій професії',
      'Хорошо знаешь Python': 'Добре знаєш Python',
      'Владеешь разработкой ботов, но разбираешься не во всем': 'Володієш розробкою ботів, але не в усьому розбираєшся',
      'Хочешь углубить знания и прокачать скилл': 'Хочеш поглибити знання і підняти свої навички',
      'Уже берёшь первые заказы и готов расширить возможности': 'Вже береш перші замовлення і готовий розширити можливості',
      'Знаешь в совершенстве Python': 'Володієш Python вдосконало',
      'Создаёшь ботов одной левой': 'Створюєш ботів легко',
      'Есть пара нюансов, в которых хочешь разобраться': 'Є пара нюансів, в яких хочеш розібратися',
      'Освоишь принципы работы с самой продвинутой библиотекой AIOgram, с базами данных, и как создать свой сервер': 'Освоїш принципи роботи з найбільш розвиненою бібліотекою AIOgram, з базами даних і як створити свій сервер',
      'Научишься с нуля создавать бота на Python и работать с API Telegram': 'Навчишся з нуля створювати бота на Python і працювати з API Telegram',
      'Разберёшься как создавать многоуровневое меню, пагинацию, а также как отправлять сообщения по таймеру': 'Розберешся, як створювати багаторівневе меню, пагінацію, а також як надсилати повідомлення за таймером',
      'Познакомишься с прекрасной IDE Pycharm, сможешь создавать простейшую веб-админку на Django и связывать ее со своими ботами, и научишься работать с Docker': 'Познайомишся з чудовим IDE Pycharm, зможеш створювати просту веб-адмінку на Django і пов\'язувати її зі своїми ботами, і навчишся працювати з Docker',
      'Будешь участвовать в рейтинге лучших студентов и получать свои первые заказы': 'Будеш брати участь у рейтингу найкращих студентів і отримувати свої перші замовлення',
      'Берёшь \r\nзаказы': 'Береш \r\nзамовлення',
      'Делаешь \r\nпервые кейсы': 'Робиш \r\nперші кейси',
      'Растёшь и повышаешь \r\nстоимость услуг': 'Зростаєш і підвищуєш \r\nвартість послуг',
      'Увлекаюсь программированием более 9 лет': 'Захоплююся програмуванням більше 9 років',
      'Закончил компьютерную академию с отличием, изучал С++, С, Python': 'Закінчив комп\'ютерну академію з відзнакою, вивчав С++, С, Python',
      'Получил MSc Engineering Project Management в Великобритании': 'Отримав MSc з управління інженерними проектами в Великобританії',
      'Программирую на Python с 2017 года': 'Програмую на Python з 2017 року',
      'Заработал на разработке ботов более $18000': 'Заробив на розробці ботів понад $18000',
      'Ответственно отношусь к раскрытию каждой темы в курсе': 'Відповідально ставлюся до розкриття кожної теми в курсі',
      'Помогаю ученикам даже после окончания курса': 'Допомагаю учням навіть після завершення курсу',
      'Не лью воду в лекциях и показываю поэтапную работу на примерах': 'Не набиваю воду на лекціях і показую роботу крок за кроком на прикладах',
      'Постоянно обновляю курс и добавляю полезные материалы': 'Постійно оновлюю курс і додаю корисні матеріали',
      '<p>Telegram-боты &mdash; новая и многофункциональная часть бизнес-процессов. Через них автоматизируют внутренние процессы, общаются с клиентами, собирают данные или что-то продают. Вы можете пройти этот курс и научиться программировать ботов.&nbsp;</p>\r\n': '<p>Telegram-боти &mdash; нова і багатофункціональна частина бізнес-процесів. Через них автоматизують внутрішні процеси, спілкуються з клієнтами, збирають дані або щось продають. Ви можете пройти цей курс і навчитися програмувати ботів.</p>\r\n',
      '<p>Telegram-боты &mdash; новая и многофункциональная часть бизнес-процессов. Через них автоматизируют внутренние процессы, общаются с клиентами, собирают данные или что-то продают.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>Ты можешь пройти этот курс и научиться программировать ботов собственными руками.</p>\r\n\r\n<p>Регистрируйся, чтобы начать смотреть бесплатные уроки прямо сейчас!</p>\r\n': '<p>Telegram-боти &mdash; нова і багатофункціональна частина бізнес-процесів. Через них автоматизують внутрішні процеси, спілкуються з клієнтами, збирають дані або щось продають.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>Ти можеш пройти цей курс і навчитися програмувати ботів власноруч.</p>\r\n\r\n<p>Реєструйся, щоб почати дивитися безкоштовні уроки прямо зараз!</p>\r\n',
      '<p>Выбери исполнителя для своего заказа из топ лучших студентов</p>\r\n': '<p>Вибери виконавця для свого замовлення з топ найкращих студентів</p>\r\n',
      '<p>На сегодня, уже <strong>30</strong>&nbsp;лучших учеников получили лично от меня <strong>35+</strong>&nbsp;заказов и заработали более <strong>10000 $</strong></p>\r\n': '<p>На сьогодні, вже <strong>30</strong>&nbsp;кращих учнів отримали особисто від мене <strong>35+</strong>&nbsp;замовлень і заробили більше <strong>10000 $</strong></p>\r\n',
      '<p>На данный момент мой курс уже проходят более 13<strong>000</strong>&nbsp;человек</p>\r\n': '<p>На даний момент мій курс вже проходять більше 13<strong>000</strong>&nbsp;людей</p>\r\n',
      '<p>Готов приступить к обучению? Тогда выбирай формат, который тебе подходит</p>\r\n': '<p>Готовий розпочати навчання? Тоді обирай формат, який тобі підходить</p>\r\n',
      'Что ещё': 'Що ще',
      'Заказать бота': 'Замовити бота',
      'Регистрация': 'Реєстрація',
      'lesson-preview-premium-required': 'Попередній перегляд уроків доступний тільки для Premium підписки. Урок відкриється {{date}}',
      'lesson-dubbed-options': '🔊 Вибрати озвучку',
      'agree-to-conditions': 'Реєструючись на платформі, ви автоматично погоджуєтесь з умовами <0>Користувацької угоди</0> та <1>Політики конфіденційності</1>',
      'login-banner-header': 'Починай навчатись всього за 1$ 🎁',
      'ai-chat-button': 'Чат з ШІ',
      'ai-chat-header': 'Чат з Штучним Інтелектом',

    },
  },
  'ru-RU': {
    translation: {
      'dashboard-news-title': 'Новости и информация',
      'dashboard-news-list': 'Список новостей',
      'link-telegram-notification-text':
        'Необходимо привязать Telegram аккаунт',
      'link-telegram-notification-button': 'Привязать',
      'dashboard-go-to-lessons': 'К урокам',
      'word-lessons': 'уроки',
      'my-balance': 'Мой баланс',
      'pay-text': 'Введите сумму пополнения:',
      'coupon-text-1': 'Поздравляю! Вы получаете свою скидку в размере ',
      'coupon-activate-text': 'Для активации скидки нажмите кнопку',
      'word-activate': 'Активировать',
      'coupon-activate-text-2':
        'Чтобы ее активировать - Вам необходимо зарегистрироваться и авторизоваться на сайте. После авторизации - нажать кнопку',
      'coupon-text-2': 'Скидкой можно будет воспользоваться до ',
      'word-authorization': 'Авторизация',
      'lesson-testing-required':
        'Вам необходимо пройти тестирование в предыдущих уроках, чтобы разблокировать этот урок',
      'lesson-next-lesson': 'Следующий урок',
      'lesson-previous-lesson': 'Предыдущий урок',
      'word-send': 'Отправить',
      'lesson-preview-testing-required': 'Пройдите тестирование',
      'word-buy': 'Купить',
      'expired-date-null':
        'Срок подписки истек. Для просмотра необходимо приобрести тариф.',
      'dashboard-accessible': 'Доступно',
      'dashboard-accessible-until': 'Доступно до',
      'word-more': 'Подробнее',
      'error404-text-1':
        'Кажется что-то пошло не так! Страница, которую вы запрашиваете, не существует.',
      'error404-text-2':
        'Возможно был неправильный запрос, страница была удалена, или был введен неверный адрес в адресной строке.',
      'error404-button': 'Вернуться на главную',
      'rating-members': 'Рейтинг Участников',
      'rating-place-of': 'место из ',
      'rating-points': 'Баллы',
      'rating-portfolio': 'За портфолио',
      'rating-progress': 'Прогресс',
      'word-change-photo': 'Изменить фото',
      'site-setting-custom-cursor': 'Кастомный курсор',
      'site-setting-dark-theme': 'Темная тема сайта',
      'site-setting-pycharm-coupon': 'Купон на Pycharm Pro:',
      'site-setting-pycharm-coupon-get': 'Получить купон',
      'error-email': 'Неверный email',
      'profile-first-name': 'Имя',
      'profile-last-name': 'Фамилия',
      'profile-timezone': 'Часовой пояс',
      'profile-birthday': 'День рождения',
      'profile-save': 'Сохранить',
      'site-setting-unlink': 'Отвязать',
      'site-setting-change': 'Изменить',
      'site-setting-your-telegram-id': 'Твой Telegram ID',
      'site-setting-telegram-id': 'Telegram ID',
      'word-streams': 'Эфиры',
      'word-buy-access': 'Купить доступ',
      'subscribe-ether': 'Подписка на Эфир',
      'buy-ether': 'Покупка Эфира',
      'subscribe-ether-text-1':
        'Вы можете получить доступ к этому эфиру бесплатно. У вас осталось еще ',
      'subscribe-ether-text-2': ' бесплатных эфира. Для подписки нажмите',
      'word-subscribe': 'Подписаться',
      'buy-ether-text-1':
        'Вы можете купить любой эфир. Стоимость текущего эфира указана ниже. С вашего баланса будет снята указанная сумма. Для покупки нажмите',
      'word-amount': 'Сумма',
      'word-cancel': 'Отмена',
      'access-purchased': 'Доступ приобретен',
      'link-to-chat-with-ether': 'Ссылка на чат с эфиром',
      'stream-was': 'Эфир проходил {{date}}',
      'stream-starts': 'Начало {{date}}',
      'tariffs-title': 'Тарифы - что это?',
      'tariffs-text-1':
        'Тарифы - это пакеты уроков и услуг, которые вы можете приобрести на нашем сайте.',
      'tariffs-text-2':
        'В зависимости от тарифа, вы получаете доступ к урокам на разный период времени, а также разному уровню индивидуального сопровождения.',
      'tariffs-text-3':
        'Чем выше тариф, тем дольше вы можете пользоваться уроками, больше дополнительных материалов и больше поддержки от нашей команды вы получаете.',
      'tariffs-title-2': 'Как приобрести тариф?',
      'tariffs-text-4':
        'Для того, чтобы приобрести тариф, вам необходимо выбрать один из предложенных вариантов и ',
      'tariffs-text-5': 'пополнить баланс на эту сумму (или вперед на несколько месяцев) на этой странице.',
      'purchaseInstruction-6':
        'После этого, вернитесь на эту страницу и нажмите кнопку <1>Купить</1> под выбранным тарифом.',
      'tariffs-learn-more': 'Подробнее',
      'tariffs-active': 'Активен',
      'tariffs-not-available': 'Не доступно',

      'terms-of-service': 'Пользовательское соглашение',
      'refund-policy': 'Правила Возврата Средств',
      'privacy-policy': 'Политика Конфиденциальности',
      'payment-security-policy': 'Политика Безопасности Платежей',
      'telegram-channel': 'Канал в Telegram',
      support: 'Техподдержка',
      'order-bot': 'Заказать бота',
      'course-author': 'Автор курса',
      reviews: 'Отзывы',
      registration: 'Регистрация',
      login: 'Войти',
      'course-program': 'Программа курса',

      'header-settings': 'Настройки',
      'header-affiliate-program': 'Партнерская программа',
      'header-balance': 'Баланс',
      'header-logout': 'Выйти',
      'login-modal': 'Войдите с помощью: ',
      'registration-modal': 'Зарегистрируйтесь с помощью: ',
      'order-bot-modal': 'Решили заказать бота?',
      'order-bot-modal-text':
        'Составляйте техническое задание и отправляйте его ',
      'invoice-modal-text':
        'В новой вкладке открылся счет на оплату, если этого не произошло, перейдите по этой ссылке: {{link}}',
      'waiting-for-payment': 'Ожидаем оплату',
      'after-payment-refresh-page':
        'После оплаты обновите страницу, или дождитесь ее автоматического обновления!',
      'lesson-cost': 'Стоимость урока ',
      'Щоб купити натисніть <1>Купити</1>. З вашого рахунку буде знята необхідна сума.':
        'Чтобы купить нажмите <1>Купить</1>. С вашего счета будет снята необходимая сумма.',
      'unauthorized-message':
        'Эта страница доступна только авторизованным пользователям! 😉',
      'return-to-main-page': 'Вернуться на стартовую страницу',
      'unauthorized-message-no-access': 'У вас нет доступа к этой странице! 😉',
      'notification-empty': 'У вас нет новых уведомлений',
      'notification-show-all': 'Показать все',
      'sidebar-main': 'Главная',
      'sidebar-lessons': 'К урокам',
      'sidebar-rating': 'Рейтинг',
      'sidebar-ethers': 'Эфиры',
      'sidebar-news': 'Новости',
      'tariff-upgrade': 'Улучшить',
      'replenish-button': 'Пополнить',
      'my-rating-title': 'Мой рейтинг',
      'rating-total': 'Всего ',
      'notification-unread':
        'У вас {{unreadNotificationsNumber}} новых уведомлений',
      'notification-load-more': 'Загрузить еще',
      'profile-language': 'Язык сайта',
      'search-modal': 'Поиск',
      'search-placeholder': 'Введите запрос',
      'tariff-forever': '/навсегда',
      'tariff-monthly': '/месяц',
      'review-text-action': 'Оставить отзыв',
      'review-text-section': 'Смотреть отзывы на',
      Send: 'Отправить',
      'Type a message...': 'Напишите сообщение...',
      Close: 'Закрыть',
      'You need to buy a tariff to use this feature':
        'Вам необходимо приобрести 3 тариф и выше, чтобы воспользоваться этой функцией',
      'onboarding.title': 'Приветствую на курсе по разработке ботов!',
      'onboarding.returnLink':
        'Вы всегда можете вернуться на эту страницу по ссылке (нажмите на нее):',
      'onboarding.copiedLink': 'Ссылка скопирована в буфер обмена!',
      'onboarding.text': `
Теперь, когда мы выяснили, насколько вы владеете **Python**, 
давайте вместе пройдемся по порталу курса. 

Здесь вы найдете множество видеоуроков и руководств, 
которые помогут вам. 
А если у вас возникнут вопросы, то вам на помощь придет
**Искусственный Интеллект**, или наша **команда поддержки**.

Наша структурированная дорожная карта поможет вам пройти каждый шаг, 
от основ **механики API ботов** до нюансов взаимодействия с пользователями, 
**построения Базы Данных и развертывания ботов на серверах**.

Ознакомьтесь со следующими видео, чтобы узнать больше о том, как
пользоваться платформой:
      `,
      'onboarding.button-dashboard': 'Перейти на главную',
      'onboarding.button-tariffs': 'Получить полный доступ',
      'word-correct-answer': 'Верный ответ',
      'word-wrong-answer': 'Неверный ответ',
      'tariffs-header-button': 'Тарифы',
      'tariffs-header-epmty-name': 'Купить тариф',
      'no-subscription-modal': '😥 Подписка отсутствует',
      'buy-subscription': 'Купить от 1$',
      'no-subscription-modal-text': 'Для того, чтобы получить доступ ко всем урокам, вам необходимо приобрести любой тариф.',
      'coupon-activate': 'Активировать купон',
      'Щотижнева': 'Еженедельная',
      'Щорічна': 'Ежегодная',
      'Статус': 'Статус',
      '🟢 Активний': '🟢 Активный',
      '🔴 Закінчився': '🔴 Закончился',
      '⏳ Закінчується': '⏳ Заканчивается',
      '💳 Очікується оплата': '💳 Ожидается оплата',
      '🚫 Неактивний': '🚫 Неактивный',
      'Цикл оплати': 'Цикл оплаты',
      'тиждень': 'неделя',
      'рік': 'год',
      'Буде змінений': 'Будет изменен',
      'Закінчується': 'Заканчивается',
      'Наступна оплата': 'Следующий платеж',
      'Скасувати підписку': 'Отменить подписку',
      'Не використано днів': 'Не использовано дней',
      'Відновити підписку': 'Восстановить подписку',
      'Наступний тариф': 'Следующий тариф',
      'will-be-activated': 'Будет активирован {{ date }}',
      'Понизити тариф': 'Понизить тариф',
      'Перший тиждень -89%': 'Первая неделя -89%',
      'Перший місяць -48%': 'Первый месяц -48%',
      'Для тих, хто звик навчатись самостійно': 'Для тех, кто привык учиться самостоятельно',
      'Для тих, хто прагне індивідуального підходу': 'Для тех, кто хочет индивидуального подхода',
      'Програма лояльності*': 'Программа лояльности*',
      'Доступ до всіх уроків*': 'Доступ ко всем урокам*',
      'Чат з Штучним Інтелектом': 'Чат с Искусственным Интеллектом',
      'Pycharm Professional на 6 міс.': 'Pycharm Professional на 6 мес.',
      'Домашні завдання і рейтинг': 'Домашние задания и рейтинг',
      'Закритий чат з студентами': 'Закрытый чат со студентами',
      'Код до уроків': 'Код к урокам',
      'Допомога з працевлаштуванням': 'Помощь с трудоустройством',
      '**Всі переваги тарифу Basic**': 'Все преимущества тарифа Basic',
      'Персональний чат з менторами і автором курсу': 'Персональный чат с менторами и автором курса',
      'Щомісячна відеоконсультація з менторами або автором': 'Ежемесячная видеоконсультация с менторами или автором',
      'Попередній доступ до нових матеріалів': 'Предварительный доступ к новым материалам',
      'Сертифікат по закінченню курсу': 'Сертификат по окончанию курса',
      '1й тиждень': '1-я неделя',
      'Від 2 тижня': 'Со 2 недели',
      'Від 9 тижня': 'С 9 недели',
      'Перші 4 тижні': 'Первые 4 недели',
      'Від 5-го тижня': 'С 5 недели',
      'Від 9-го тижня': 'С 9 недели',
      'Від 13-го тижня': 'С 13 недели',
      'Базовые': 'Базовые',
      'Дополнительные': 'Дополнительные',
      'Бонусные': 'Бонусные',
      'Заработок в Ботах': 'Заработок в Ботах',
      'Обучение Разработке Telegram Ботов': 'Обучение Разработке Telegram Ботов',
      'Обучение Разработке <br /> Telegram Ботов': 'Обучение Разработке <br /> Telegram Ботов',
      'Нужен бот для Telegram, но обучение не актуально?': 'Нужен бот для Telegram, но обучение не актуально?',
      'Тебе нужен этот курс если ты': 'Тебе нужен этот курс если ты',
      'Новичок': 'Новичок',
      'Новичок, но шаришь': 'Новичок, но шаришь',
      'Продвинутый': 'Продвинутый',
      'Профи': 'Профи',
      'На моём курсе ты': 'На моём курсе ты',
      'А что после курса?': 'А что после курса?',
      'Автор курса': 'Автор курса',
      'Что говорят ученики': 'Что говорят ученики',
      'Хочешь научиться создавать ботов?': 'Хочешь научиться создавать ботов?',
      'Также ты можешь заказать готового бота': 'Также ты можешь заказать готового бота',
      'Листай ниже': 'Листай ниже',
      'Не знаешь ничего о создании ботов': 'Не знаешь ничего о создании ботов',
      'Только начал изучать язык программирования Python': 'Только начал изучать язык программирования Python',
      'Горишь идеей и готов много учиться': 'Горишь идеей и готов много учиться',
      'Планируешь вырасти в продвинутого разработчика': 'Планируешь вырасти в продвинутого разработчика',
      'Что-то знаешь про разработку, но не углублялся': 'Что-то знаешь про разработку, но не углублялся',
      'Хочешь освоить тему полностью': 'Хочешь освоить тему полностью',
      'Имеешь план по заработку в этой профессии': 'Имеешь план по заработку в этой профессии',
      'Хорошо знаешь Python': 'Хорошо знаешь Python',
      'Владеешь разработкой ботов, но разбираешься не во всем': 'Владеешь разработкой ботов, но разбираешься не во всем',
      'Хочешь углубить знания и прокачать скилл': 'Хочешь углубить знания и прокачать скилл',
      'Уже берёшь первые заказы и готов расширить возможности': 'Уже берёшь первые заказы и готов расширить возможности',
      'Знаешь в совершенстве Python': 'Знаешь в совершенстве Python',
      'Создаёшь ботов одной левой': 'Создаёшь ботов одной левой',
      'Есть пара нюансов, в которых хочешь разобраться': 'Есть пара нюансов, в которых хочешь разобраться',
      'Освоишь принципы работы с самой продвинутой библиотекой AIOgram, с базами данных, и как создать свой сервер': 'Освоишь принципы работы с самой продвинутой библиотекой AIOgram, с базами данных, и как создать свой сервер',
      'Научишься с нуля создавать бота на Python и работать с API Telegram': 'Научишься с нуля создавать бота на Python и работать с API Telegram',
      'Разберёшься как создавать многоуровневое меню, пагинацию, а также как отправлять сообщения по таймеру': 'Разберёшься как создавать многоуровневое меню, пагинацию, а также как отправлять сообщения по таймеру',
      'Познакомишься с прекрасной IDE Pycharm, сможешь создавать простейшую веб-админку на Django и связывать ее со своими ботами, и научишься работать с Docker': 'Познакомишься с прекрасной IDE Pycharm, сможешь создавать простейшую веб-админку на Django и связывать ее со своими ботами, и научишься работать с Docker',
      'Будешь участвовать в рейтинге лучших студентов и получать свои первые заказы': 'Будешь участвовать в рейтинге лучших студентов и получать свои первые заказы',
      'Берёшь \r\nзаказы': 'Берёшь \r\nзаказы',
      'Делаешь \r\nпервые кейсы': 'Делаешь \r\nпервые кейсы',
      'Растёшь и повышаешь \r\nстоимость услуг': 'Растёшь и повышаешь \r\nстоимость услуг',
      'Увлекаюсь программированием более 9 лет': 'Увлекаюсь программированием более 9 лет',
      'Закончил компьютерную академию с отличием, изучал С++, С, Python': 'Закончил компьютерную академию с отличием, изучал С++, С, Python',
      'Получил MSc Engineering Project Management в Великобритании': 'Получил MSc Engineering Project Management в Великобритании',
      'Программирую на Python с 2017 года': 'Программирую на Python с 2017 года',
      'Заработал на разработке ботов более $18000': 'Заработал на разработке ботов более $18000',
      'Ответственно отношусь к раскрытию каждой темы в курсе': 'Ответственно отношусь к раскрытию каждой темы в курсе',
      'Помогаю ученикам даже после окончания курса': 'Помогаю ученикам даже после окончания курса',
      'Не лью воду в лекциях и показываю поэтапную работу на примерах': 'Не лью воду в лекциях и показываю поэтапную работу на примерах',
      'Постоянно обновляю курс и добавляю полезные материалы': 'Постоянно обновляю курс и добавляю полезные материалы',
      '<p>Telegram-боты &mdash; новая и многофункциональная часть бизнес-процессов. Через них автоматизируют внутренние процессы, общаются с клиентами, собирают данные или что-то продают. Вы можете пройти этот курс и научиться программировать ботов.&nbsp;</p>\r\n': '<p>Telegram-боты &mdash; новая и многофункциональная часть бизнес-процессов. Через них автоматизируют внутренние процессы, общаются с клиентами, собирают данные или что-то продают. Вы можете пройти этот курс и научиться программировать ботов.&nbsp;</p>\r\n',
      '<p>Telegram-боты &mdash; новая и многофункциональная часть бизнес-процессов. Через них автоматизируют внутренние процессы, общаются с клиентами, собирают данные или что-то продают.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>Ты можешь пройти этот курс и научиться программировать ботов собственными руками.</p>\r\n\r\n<p>Регистрируйся, чтобы начать смотреть бесплатные уроки прямо сейчас!</p>\r\n': '<p>Telegram-боты &mdash; новая и многофункциональная часть бизнес-процессов. Через них автоматизируют внутренние процессы, общаются с клиентами, собирают данные или что-то продают.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>Ты можешь пройти этот курс и научиться программировать ботов собственными руками.</p>\r\n\r\n<p>Регистрируйся, чтобы начать смотреть бесплатные уроки прямо сейчас!</p>\r\n',
      '<p>Выбери исполнителя для своего заказа из топ лучших студентов</p>\r\n': '<p>Выбери исполнителя для своего заказа из топ лучших студентов</p>\r\n',
      '<p>На сегодня, уже <strong>30</strong>&nbsp;лучших учеников получили лично от меня <strong>35+</strong>&nbsp;заказов и заработали более <strong>10000 $</strong></p>\r\n': '<p>На сегодня, уже <strong>30</strong>&nbsp;лучших учеников получили лично от меня <strong>35+</strong>&nbsp;заказов и заработали более <strong>10000 $</strong></p>\r\n',
      '<p>На данный момент мой курс уже проходят более 13<strong>000</strong>&nbsp;человек</p>\r\n': '<p>На данный момент мой курс уже проходят более 13<strong>000</strong>&nbsp;человек</p>\r\n',
      '<p>Готов приступить к обучению? Тогда выбирай формат, который тебе подходит</p>\r\n': '<p>Готов приступить к обучению? Тогда выбирай формат, который тебе подходит</p>\r\n',
      'Что ещё': 'Что ещё',
      'Заказать бота': 'Заказать бота',
      'Регистрация': 'Регистрация',
      'lesson-preview-premium-required': 'Предпросмотр уроков доступен только для Premium подписок. Урок отроется: {{date}}',
      'lesson-dubbed-options': '🔊 Выбрать озвучку',
      'За замовчуванням': 'По умолчанию',
      'agree-to-conditions': 'Регистрируясь на платформе, вы автоматически соглашаетесь с условиями <0>Пользовательского соглашения</0> и <1>Политики конфиденциальности</1>',
      'login-banner-header': 'Начинай обучение всего за 1$ 🎁',
      'Переглядай навчальні відео та вивчай теоретичний матеріал': 'Смотри обучающие видео и изучай теоретический материал',
      '✅ Виконуй практичні завдання': '✅ Выполняй практические задания',
      '✅ Приєднуйтесь до нашого чату в Telegram для обговорення та підтримки': '✅ Присоединяйтесь к нашему чату в Telegram для обсуждения и поддержки',
      'ai-chat-button': 'Чат с ИИ',
      'ai-chat-header': 'Чат с Искусственным Интеллектом',
    },
  },
  // Add more languages
}
const fallbackLanguage = 'uk-UA'
function setLanguageBasedOnLocation() {
  const cachedLanguage = localStorage.getItem('language')

  if (cachedLanguage) {
    i18n.changeLanguage(cachedLanguage)
    return
  }

  axios
    .get('https://ipapi.co/json/') // Replace with your chosen geolocation API
    .then((response) => {
      const countryCode = response.data.country_code
      const postSovietCountries = [
        'RU',
        'BY',
        'MD',
        'GE',
        'AM',
        'AZ',
        'KZ',
        'UZ',
        'TM',
        'KG',
        'TJ',
        'EE', // Estonia
        'LV', // Latvia
        'LT', // Lithuania
      ]
      let language

      if (countryCode === 'UA') {
        language = 'uk-UA'
      } else if (postSovietCountries.includes(countryCode)) {
        language = 'ru-RU'
      } else {
        language = fallbackLanguage
      }

      i18n.changeLanguage(language)
      localStorage.setItem('language', language)
    })
    .catch((error) => {
      console.error('Error getting location: ', error)
      i18n.changeLanguage(fallbackLanguage)
      localStorage.setItem('language', fallbackLanguage)
    })
}

i18n.use(initReactI18next).init({
  resources,
  lng: fallbackLanguage,
  fallbackLng: fallbackLanguage,
  interpolation: {
    escapeValue: false,
  },
})

setLanguageBasedOnLocation()

export default i18n
