import classNames from 'classnames'
import Icon from 'components/Icons/Icon'
import React, { memo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import ReactTooltip from 'react-tooltip'
import { formatDate } from 'utils/formatDate'
import './TariffItem.scss'

const Tooltip = ({ children, text }) => (
  <>
    <div className="tooltip-wrapper" data-tip={text}>
      {children}
    </div>
    <ReactTooltip place="top" effect="solid" backgroundColor="#004f71" />
  </>
)


const TariffButton = ({
  landingButtonName,
  isLanding,
  hasBuyButton,
  handleBuy,
  isActiveTariff,
  handleStopSubscription,
  canDowngrade,
  handleDowngrade,
  isPending,
  pendingDate,
  userSubcription,
}) => {

  const handleClickLanging = () => {
  }

  if (isLanding) {
    return (
      <div className="option-cnt__btn-wrap">
        <button
          type="button"
          className="btn__tariff"
          onClick={handleClickLanging}
        >
          {landingButtonName}
        </button>
      </div>
    )
  }
  if (isPending && pendingDate) {
    return (
      <div className="option-cnt__btn-wrap">
        <button
          type="button"
          className="btn__tariff btn__tariff--inactive"
        >
          <Trans i18nKey="will-be-activated">
            Буде активовано {{ date: formatDate(pendingDate) }}
          </Trans>
        </button>

      </div>
    )
  }

  if (hasBuyButton) {
    return (
      <div className="option-cnt__btn-wrap">
        <button
          type="button"
          className="btn__tariff"
          onClick={handleBuy}
        >
          <Trans i18nKey="word-buy" />
        </button>
      </div>
    )
  }
  if (isActiveTariff && userSubcription?.status === 'active') {
    return (
      <div className="option-cnt__btn-wrap">
        <button
          type="button"
          className="btn__tariff btn__tariff--active"
          onClick={handleStopSubscription}
        >
          <Trans>
            Скасувати підписку
          </Trans>
        </button>
      </div>
    )
  }
  if (isActiveTariff && userSubcription?.status === 'to_expire') {
    return (
      <div className="option-cnt__btn-wrap">
        <button
          type="button"
          className="btn__tariff btn__tariff"
          onClick={handleBuy}
        >
          <Trans>
            Відновити підписку
          </Trans>
        </button>
      </div>
    )
  }


  if (canDowngrade) {
    return (
      <div className="option-cnt__btn-wrap">
        <button
          type="button"
          className="btn__tariff btn__tariff--inactive"
          onClick={handleDowngrade}
        >
          <Trans>
            Понизити тариф
          </Trans>
        </button>
      </div>
    )
  }
}

const TariffItem = memo((props) => {
  const {
    options,
    name,
    description,
    tariffKey,
    buttonName,
    price,
    number,
    handleBuy,
    handleStopSubscription,
    handleDowngrade,
    isLanding,
    isActiveTariff,
    hasBuyButton,
    canDowngrade,
    stages,
    currentStage,
    billingCycle,
    userSubcription,
    isPending,
    pendingDate,
  } = props
  if (!stages || stages.length === 0) return null
  const { t } = useTranslation()

  const basicStage = stages[1] ? stages[1] : stages[0]
  const userStage =
    stages.find((stage) => stage.id === currentStage?.id) || stages[0]
  const landingButtonName =
    number === 1 ? buttonName : `${buttonName} ${userStage.price}$`

  let fullPrice
  if (billingCycle === 'yearly') {
    fullPrice = price * 12
  } else {
    fullPrice = basicStage.price
  }
  const cycleText = billingCycle === 'yearly' ? t('рік') : t('тиждень')

  const calcDiscount = (stagePrice) => {
    if (billingCycle === 'yearly') {
      return Math.round(((fullPrice - stagePrice) / fullPrice) * 100)
    } else {
      return Math.round(((fullPrice - stagePrice) / fullPrice) * 100)
    }
  }

  const [calendarOpened, setCalendarOpened] = useState(false)
  let trialText
  if (billingCycle === 'weekly') {
    trialText =
      tariffKey === 'basic-tariff'
        ? <Trans>Перший тиждень -89%</Trans>
        : <Trans>Перший місяць -48%</Trans>
  } else if (billingCycle === 'yearly') {
    trialText = tariffKey === 'basic-tariff' ? '-56%' : '-40%'
  }


  return (
    <div className="option-list__wrap">
      <div className="option">
        <div className="option__title-block">
          <div className="option-title">
            <h3>{name}</h3>
            <div className="stage__trial">{trialText}</div>
          </div>
          <div className="option__description description">
            <p><Trans>{description}</Trans></p>
          </div>

          <div className="option-price-block">
            <div className="option-price">
              {fullPrice !== userStage.price && (
                <span className="option-price__val">${fullPrice}</span>
              )}
              <strong className="option-price__discount">{`$${userStage.price}`}</strong>
              <p className="option-price__text">
                {userStage.billing_cycle === 'weekly'
                  ? ` / ${cycleText}`
                  : userStage.billing_cycle === 'yearly'
                    ? ` / ${cycleText}`
                    : null}
              </p>
            </div>
            {billingCycle !== 'yearly' && (
              <button
                className="option-calendar-button"
                onClick={() => setCalendarOpened(!calendarOpened)}
              >
                <span className="option-calendar-button__text">
                  <Trans>
                    Програма лояльності*
                  </Trans>
                </span>
                <Icon calendar />
              </button>
            )}
          </div>
          {stages && stages.length > 1 && (
            <div className={classNames('timeline', { opened: calendarOpened })}>
              {stages.map((stage) => {
                const discount = calcDiscount(stage.price)
                const isCurrent = userStage.id === stage.id

                return (
                  <div
                    key={stage.id}
                    className={classNames('stage', { active: isCurrent })}
                  >
                    <div className="stage-description">
                      <div className="stage-description__text">
                        <Trans>
                          {stage.description}
                        </Trans>
                      </div>
                      {discount > 0 && (
                        <div className="stage-description__discount">
                          -{discount}%
                        </div>
                      )}
                    </div>

                    <div className="stage-price">
                      <div className="stage-price__val">${stage.price}</div>
                      <div className="cycle-text">/ {cycleText}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {isLanding && <Trans i18nKey="tariffs-learn-more" />}
        <TariffButton
          handleBuy={handleBuy}
          handleStopSubscription={handleStopSubscription}
          handleDowngrade={handleDowngrade}
          isLanding={isLanding}
          landingButtonName={landingButtonName}
          isActiveTariff={isActiveTariff}
          hasBuyButton={hasBuyButton}
          canDowngrade={canDowngrade}
          isPending={isPending}
          pendingDate={pendingDate}
          userSubcription={userSubcription}
        />

        <ul className="list">
          {options.map((option, index) => (
            <li className="list__item" key={index}>
              <Icon tick />
              <Tooltip text={option.tooltip}>
                <ReactMarkdown>
                  {t(option.text)}
                </ReactMarkdown>
              </Tooltip>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
})

export default TariffItem
