import { useState, useEffect } from 'react'

const useCursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null })
  const [onHover, setOnHover] = useState(false)

  useEffect(() => {
    const mouseMoveHandler = (event) => {
      event.stopPropagation()
      const { clientX, clientY } = event
      const element = event.target.tagName

      setMousePosition({ x: clientX, y: clientY })

      if (element === 'A' || element === 'BUTTON') {
        setOnHover(true)
      } else {
        setOnHover(false)
      }
    }

    document.addEventListener('mousemove', mouseMoveHandler)

    return () => document.removeEventListener('mousemove', mouseMoveHandler)
  }, [onHover])

  return { mousePosition, onHover, setOnHover }
}

export default useCursor
