import React, { memo } from 'react'
import TelegramLoginButton from 'react-telegram-login'

import Icon from 'components/Icons/Icon'
import { useAddTelegram, useRemoveTelegram, useUnlinkGithub } from 'hooks/api/useAccount'
import useWindowDimensions from 'hooks/useWindowDimension'
import { githubGetUserUrl } from 'config/authRedirectUrl'
import {Trans, useTranslation} from 'react-i18next'

const SocialSettings = memo(({ githubId, telegramId }) => {
  const { width } = useWindowDimensions()

  const handleTelegramResponse = ({ id, first_name, last_name }) => addTelegram({ id, first_name, last_name })

  const { mutate: addTelegram } = useAddTelegram()
  const { mutate: removeTelegram } = useRemoveTelegram()
  const { mutate: unlinkGithub } = useUnlinkGithub()

  return (
    <div className="site-setting">
      <div className="site-setting__wrap">
        <div>
          <span className="site-setting__label">{width > 480 ? 'Твой Github ID:' : 'Github ID:'}</span>
          <span className="site-setting__value">{githubId || ' - '}</span>
        </div>
        {githubId ? (
          <button type="button" className="btn btn_border-pacificBlue" onClick={unlinkGithub}>
            {/*{width > 480 ? t('site-setting-unlink') : t('site-setting-change')}*/}
            {width > 480 ? <Trans i18nKey="site-setting-unlink"/> : <Trans i18nKey="site-setting-change"/>}
          </button>
        ) : (
          <a href={githubGetUserUrl()} className="github-btn">
            <Icon github />
          </a>
        )}
      </div>
      <div className="site-setting__wrap">
        <div>
          <span className="site-setting__label">
            {/*{ width > 480 ? t('site-setting-your-telegram-id') : t('site-setting-telegram-id')}*/}
            {width > 480 ? <Trans i18nKey="site-setting-your-telegram-id"/> : <Trans i18nKey="site-setting-telegram-id"/>}
          </span>
          <span className="site-setting__value">{telegramId || ' - '}</span>
        </div>
        {telegramId ? (
          <button type="button" className="btn btn_border-pacificBlue" onClick={removeTelegram}>
            {/*{width > 480 ? t('site-setting-unlink') : t('site-setting-change')}*/}
            {width > 480 ? <Trans i18nKey="site-setting-unlink"/> : <Trans i18nKey="site-setting-change"/>}
          </button>
        ) : (
          <TelegramLoginButton
            botName="aiogram_course_bot"
            usePic={false}
            className="telegram-login"
            buttonSize={width > 480 ? 'large' : 'small'}
            dataOnauth={handleTelegramResponse}
          />
        )}
      </div>
    </div>
  )
})

export default SocialSettings
