import React from 'react'
import './TrustPilotWidget.scss'
import { useTranslation, Trans } from 'react-i18next'

const TrustBox = () => {
  const { t } = useTranslation()
  return (
    <a
      className="trustpilot-widget"
      href="https://www.trustpilot.com/review/botfather.dev"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="trustpilot-widget__header">
        <Trans i18nKey="review-text-action" />
      </div>
      <div className="trustpilot-widget__body">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="trustpilot-widget__star--icon"
        >
          <path d="M12 2.5L14.06 8.5L20.5 9.27L15.5 13.5L16.5 20L12 16.5L7.5 20L8.5 13.5L3.5 9.27L9.94 8.5L12 2.5Z" />
        </svg>
        <div className="trustpilot-widget__footer">Trustpilot</div>
      </div>
    </a>
  )
}

export const TrustBoxReviewSection = () => {
  const { t } = useTranslation()
  return (
    <a
      className="trustpilot-widget-section"
      href="https://www.trustpilot.com/review/botfather.dev"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="trustpilot-widget__header">
        <Trans i18nKey="review-text-section" />
      </div>
      <div className="trustpilot-widget__body">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="trustpilot-widget__star--icon"
        >
          <path d="M12 2.5L14.06 8.5L20.5 9.27L15.5 13.5L16.5 20L12 16.5L7.5 20L8.5 13.5L3.5 9.27L9.94 8.5L12 2.5Z" />
        </svg>
        <div className="trustpilot-widget__footer">Trustpilot</div>
      </div>
    </a>
  )
}

export default TrustBox
