import { ModalContext } from 'HOCs/withModalProvider'
import { MODAL_TYPES } from 'config/constants/constants'
import { useContext, useEffect } from 'react'

const useInvoiceModal = (link, isSuccess, onCloseCallback) => {
  const { onOpen: openModal, setData: setModalData } = useContext(ModalContext)

  useEffect(() => {
    if (isSuccess) {
      console.log('link', link)
      const windowFeatures = 'popup,right=0,top=0,width=600,height=600'

      setModalData({ link, onCloseCallback })
      openModal(null, MODAL_TYPES.invoice)
      window.open(link, '_blank', windowFeatures)
    }
  }, [isSuccess, setModalData, openModal])
}

export default useInvoiceModal
