import React, {useState} from 'react'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import {useAddFunds, useGetAccountSettings, useGetCurrencies} from 'hooks/api/useAccount'
import useInputState from 'hooks/useInputState'
import useInvoiceModal from 'hooks/useInvoiceModal'
import {NotAuthContent} from 'components/NoContent'
import {Trans, useTranslation} from 'react-i18next'

function Balance({isAuth}) {
  const {data} = useGetAccountSettings()

  const {isSuccess, data: addFundsData, mutate: addFunds} = useAddFunds()
  const {data: currenciesArr} = useGetCurrencies()

  const balance = data?.balance

  const [inputValue, handleOnChange] = useInputState()
  const [selectedCurrency, setSelectedCurrency] = useState('usd')
  const {i18n} = useTranslation()
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency)
  }
  // Filter the currencies array to only include the desired currencies
  const filteredCurrenciesArr = currenciesArr?.filter((currency) => ['usd', 'btc', 'usdttrc20', 'eth', 'ltc'].includes(currency))
  useInvoiceModal(addFundsData?.data?.url, isSuccess)
  return !isAuth ? (
    <NotAuthContent/>
  ) : (
    <section className="wallet">
      <div className="container wallet__container">
        <div className="title wallet__title">
          <h1><Trans i18nKey="my-balance"/></h1>
        </div>
        <div className="wallet__balance balance">
          {balance}
          <span className="balance__currency">$
          </span>
        </div>
        <div className="wallet__data-item">
          {i18n.language === 'ru-RU' && (
            <>
              <p>
                                Для граждан РФ/Беларуси оплата в USD невозможна, выбирайте оплату в криптовалюте.
              </p>
              <p>
                                Для покупки криптовалюты с помощью <strong>простого перевода на карту</strong> можете
                                использовать бота <a href='https://t.me/CryptoBot?start=r-966380-market'>@CryptoBot</a>
              </p>
              <p>
                <a href='https://telegra.ph/Pokupka-Kriptovalyuty-v-CryptoBot-12-20'>Инструкция по
                                    покупке
                                    криптовалюты в @CryptoBot</a>
              </p>
            </>
          )
          }
          <p><Trans i18nKey="pay-text"/></p>

          <div className="input-wrap">
            <input type="text" value={inputValue} onChange={handleOnChange} className='balance'/>
            <span className="input-wrap__currency"> {
              selectedCurrency !== 'usd'
                ? `$ в (${selectedCurrency.toUpperCase()})`
                : '$'
            }
            </span>
          </div>
          <div style={{display: 'flex', flexWrap: 'nowrap', marginTop: '10px'}}>
            {currenciesArr && filteredCurrenciesArr?.map((currency) => (
              <button
                key={currency}
                onClick={() => handleCurrencyChange(currency)}
                className={'btn btn_pattensBlue' + (currency === selectedCurrency ? 'Strong selected' : '')}
                style={{marginRight: '8px', width: 'unset'}}
              >
                {
                  currency === 'usdttrc20'
                    ? 'USDT'
                    :
                    currency.toUpperCase()
                }
              </button>
            ))}
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn btn_pattensBlueStrong"
            onClick={() => addFunds({amount: inputValue, currency: selectedCurrency})}
          >
            <Trans i18nKey="replenish-button"/>
          </button>
        </div>
      </div>
    </section>
  )
}

export default withDashboardLayout()(Balance)
