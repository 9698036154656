import React, { memo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { Slide, toast } from 'react-toastify'

const Copy = memo(({ value, label, button = true }) => {
  const notify = () =>
    toast(
      <>
        <div className="toast-label">Скопировано</div>
      </>,
      {
        className: 'toast-copy-notification',
        position: 'top-right',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        transition: Slide,
        closeButton: false,
        pauseOnFocusLoss: false
      }
    )

  return (
    <div className="copy-clipboard">
      {button ? (<>
        <Link to="" className="" onClick={(e) => e.preventDefault()}>
          {label}
        </Link>
        <CopyToClipboard text={value} onCopy={() => notify()}>
          <button type="button" className="btn btn_pattensBlue">
            Копировать
          </button>
        </CopyToClipboard>
      </>) :
        (
          <CopyToClipboard text={value} onCopy={() => notify()}>
            <span className="copy-clipboard__label">{label}</span>
          </CopyToClipboard>
        )
      }
    </div>)
})

export default Copy
