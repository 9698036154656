export const createRedirectUrl = (baseURL, invitingCode, couponCode, utm_source, utm_medium, current_language) => {
  let redirectUrl = baseURL

  if (invitingCode) {
    redirectUrl += `inviting_code=${invitingCode}&`
  }
  if (couponCode) {
    redirectUrl += `coupon_code=${couponCode}&`
  }
  if (utm_source) {
    redirectUrl += `utm_source=${utm_source}&`
  }
  if (utm_medium) {
    redirectUrl += `utm_medium=${utm_medium}&`
  }
  if (current_language) {
    redirectUrl += `current_language=${current_language}&`
  }
  return redirectUrl.slice(0, -1)
}

export const googleRedirectUrl = (invitingCode, couponCode, utm_source, utm_medium, current_language) => {
  let baseURL = `${process.env.REACT_APP_API_URL}/api/auth/google/redirect?`
  return createRedirectUrl(baseURL, invitingCode, couponCode, utm_source, utm_medium, current_language)
}

export const githubRedirectUrl = (invitingCode, couponCode, utm_source, utm_medium, current_language) => {
  let baseURL = `${process.env.REACT_APP_API_URL}/api/auth/github/redirect?`
  return createRedirectUrl(baseURL, invitingCode, couponCode, utm_source, utm_medium, current_language)
}
export const githubGetUserUrl = () => {
  return `${process.env.REACT_APP_API_URL}/api/account/github/redirect`
}

