import CursorOutline from 'components/CursorOutline/CursorOutline'
import { useGetAccountSettings, useUpdateSettings } from 'hooks/api/useAccount'
import { useToken } from 'hooks/api/useAuth'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SettingsContext = createContext(null)

const withSettingsProvider = () => (Component) => {
  return function (props) {
    const { data: token } = useToken()
    const { mutate: updateSettings } = useUpdateSettings()
    const { data, refetch: getAccountData } = useGetAccountSettings(true)

    const { t, i18n } = useTranslation()

    const apiCursor = Boolean(data?.settings?.custom_cursor)
    const LSCursor = JSON.parse(localStorage.getItem('cursor'))

    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'dark'
      : 'light'
    // Local storage theme
    const LSTheme = localStorage.getItem('theme')
    // API theme
    const apiTheme = data?.settings?.dark_mode ? 'dark' : 'light'
    // Initial theme determination
    const initialTheme = LSTheme || apiTheme || systemTheme
    const [theme, setTheme] = useState(initialTheme)
    const [cursor, setCursor] = useState(LSCursor)

    useEffect(() => {
      if (token) getAccountData()
    }, [token, getAccountData])

    useEffect(() => {
      if (token && data) {
        localStorage.setItem('theme', apiTheme)
        localStorage.setItem('cursor', apiCursor)
        setTheme(apiTheme)
        setCursor(apiCursor)
      }
      if (!token) {
        setTheme(LSTheme || systemTheme || 'light')
        setCursor(LSCursor)
      }
    }, [data, token, apiTheme, apiCursor, setTheme, setCursor])

    useEffect(() => {
      if (data?.language) {
        i18n.changeLanguage(data.language)
      }
    }, [data, i18n])

    const onThemeChange = useCallback(() => {
      const data = {
        settings: {
          custom_cursor: cursor,
          dark_mode: null,
        },
      }
      setTheme(theme === 'light' ? 'dark' : 'light')
      localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light')
      data.settings.dark_mode = theme === 'light' ? true : false

      updateSettings(data)
    }, [theme, setTheme, updateSettings])

    const onCursorChange = useCallback(() => {
      const data = {
        settings: {
          custom_cursor: null,
          dark_mode: theme === 'dark',
        },
      }
      if (cursor) {
        setCursor(false)
        localStorage.setItem('cursor', 'false')
        data.settings.custom_cursor = false
      } else {
        setCursor(true)
        localStorage.setItem('cursor', 'true')
        data.settings.custom_cursor = true
      }
      updateSettings(data)
    }, [cursor, setCursor, updateSettings])

    document.querySelector('#root').setAttribute('data-theme', theme)

    return (
      <div className="global-wrapper" data-theme={theme}>
        {cursor && <CursorOutline />}
        {theme === 'dark' && <div className="backgroundDarkTheme" />}

        <SettingsContext.Provider
          value={{
            cursor,
            theme,
            onThemeChange,
            onCursorChange,
            accountData: data,
          }}
        >
          <Component {...props} />
        </SettingsContext.Provider>
      </div>
    )
  }
}

export { SettingsContext, withSettingsProvider as default }

