import Axios from 'axios'
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import ROUTES from 'config/constants/routes'
import useToastNotification from 'hooks/useToastNotification'
import history from 'services/history'
import { handleRequest, handleResponse } from './config'

export const ApiContext = createContext(undefined)

export const ApiProvider = ({ children }) => {
  const { notify, notifyStatus } = useToastNotification()
  const notifyOnSuccess = useCallback(
    (message) => notify(message, notifyStatus.success),
    [notify]
  )
  const notifyOnError = useCallback(
    (message) => notify(message, notifyStatus.error),
    [notify]
  )

  const queryClient = useQueryClient()

  const getToken = useCallback(() => {
    const token = localStorage.getItem('token')
    if (!token) return null
    return `Bearer ${token}`
  }, [])

  const api = useMemo(() => {
    return Axios.create({
      timeout: 10000,
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
      headers: {
        Authorization: getToken('token'),
      },
      validateStatus: (status) => status,
    })
  }, [getToken])

  const fastApi = useMemo(() => {
    return Axios.create({
      timeout: 10000,
      baseURL: `${process.env.REACT_APP_API_URL}/webhook`,
      headers: {
        Authorization: getToken('token'),
      },
      validateStatus: (status) => status,
    })
  }, [getToken])


  api.interceptors.request.use(handleRequest)
  fastApi.interceptors.request.use(handleRequest)
  api.interceptors.response.use((response) =>
    handleResponse(response, logout, on404, notifyOnSuccess, notifyOnError)
  )
  fastApi.interceptors.response.use((response) =>
    handleResponse(response, logout, on404, notifyOnSuccess, notifyOnError)
  )

  const on404 = useCallback(() => history.push(ROUTES.notFound), [])

  const setToken = useCallback(
    (token) => {
      if (!token) {
        localStorage.removeItem('token')
        delete api.defaults.headers.Authorization
        delete fastApi.defaults.headers.Authorization
        return
      }
      localStorage.setItem('token', token)
      api.defaults.headers.Authorization = `Bearer ${token}`
      fastApi.defaults.headers.Authorization = `Bearer ${token}`
    },
    [api.defaults.headers, fastApi.defaults.headers]
  )

  const logout = useCallback(() => {
    localStorage.clear()
    queryClient.invalidateQueries()
    delete api.defaults.headers.Authorization
    delete fastApi.defaults.headers.Authorization
    return
  }, [api.defaults.headers,
    fastApi.defaults.headers,
    queryClient])

  const accountRequests = {
    getAccountSettings: () => api.get('/account/info'),
    updateProfile: (data) => api.patch('/account/profile/edit', data),
    updateSettings: (data) => api.patch('/account/settings/edit', data),

    addTelegram: (id) => api.patch('/account/telegram/link', id),
    removeTelegram: () => api.patch('/account/telegram/unlink'),
    linkGithub: (code) => api.post('/account/github/callback/check', code),
    unlinkGithub: () => api.patch('/account/github/unlink'),

    uploadAvatar: (data) =>
      api.patch('/account/profile/edit', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    getNotificationData: ({ pageParam = { items: 4, page: 1 } }) => {
      return api.get(
        `/account/notifications/list?page=${pageParam.page}&items=${pageParam.items}`
      )
    },
    readNotification: (id) => api.post('/account/notifications/read', id),
    getAffiliatePrograme: () => api.get('/account/affiliate-program'),
    getAffiliateProgrameAbout: () => api.get('/pages/affiliate-program'),
    transferBonusMoney: (amount) =>
      api.post('/account/balance/transfer-bonus-money', amount),
    addFunds: (amount) => api.post('/account/balance/add-funds', amount),
    getCurrencies: () => api.get('/account/balance/get-currencies'),
    getPycharmCoupon: () => api.get('/pycharm_coupons/get'),
  }
  const landingRequests = {
    getLandingData: () => api.get('/pages/landing'),
  }
  const dashboardRequests = {
    getDashboardData: () => api.get('/pages/dashboard'),
  }
  const tariffRequests = {
    getTariffsData: () => api.get('/tariffs/list'),
    buyTariff: (data) => fastApi.post('/tariffs/buy', data),
    subscribe: () => api.post('/account/balance/subscribe'),
    stopSubscription: (data) => fastApi.post('/tariffs/stop', data),
  }
  const lessonsRequests = {
    getLessons: () => api.get('/lessons/list'),
    getLessonsById: (id) => api.get(`/lessons/list?category_id=${id}`),
    getLesson: (slug) => api.get(`/lessons/${slug}`),
    getTestResults: (data) =>
      api.post(`/lessons/${data.id}/check`, { answers: data.answers }),
    buyLesson: (data) => api.post('/lessons/buy', data),
  }
  const webinarsRequests = {
    getWebinars: () => api.get('/webinars/list'),
  }
  const authRequests = {
    authWithGoogle: (code) => api.post('/auth/google/callback/check', code),
    authWithGithub: (code) => api.post('/auth/github/callback/check', code),
  }
  const newsRequests = {
    getNews: (page = 1, numberItems = 1000) =>
      api.get(`/articles/list?page=${page}&items=${numberItems}`),
    getNewsDetails: (slug) => api.get(`/articles/${slug}`),
  }
  const streamsRequests = {
    getStreams: (page = 1, numberItems = 1000) =>
      api.get(`/streams/list?page=${page}&items=${numberItems}`),
    getStreamDetails: (slug) => api.get(`/streams/${slug}`),
    buyStream: (id) => api.post('/streams/buy', id),
    subscribeStream: (id) => api.post('/streams/subscribe', id),
  }
  const otherRequests = {
    getBanners: () => api.get('/banners/list'),
    getCouponData: (code) => api.get(`/coupons/${code}`),
    activateCoupon: (code) => api.post('/coupons/activate', code),
    getFAQData: () => api.get('/pages/faq'),
    getTermsOfServiceData: () => api.get('/pages/terms-of-service'),
    getRefundPolicyData: () => api.get('/pages/refund-policy'),
    getPrivacyPolicyData: () => api.get('/pages/privacy-policy'),
    getPaymentSecurityPolicyData: () =>
      api.get('/pages/payment-security-policy'),
    getCourseProgram: () => api.get('/pages/course-program'),
    gptRequest: (data) => api.post('/webhook/gpt_request', data),
  }

  const value = {
    landing: landingRequests,
    auth: authRequests,
    account: accountRequests,
    lessons: lessonsRequests,
    tariff: tariffRequests,
    news: newsRequests,
    webinars: webinarsRequests,
    streams: streamsRequests,
    dashboard: dashboardRequests,
    other: otherRequests,
    setToken,
    logout,
    getToken,
  }

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export const useApi = () => useContext(ApiContext)
