import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useApi } from 'services/api/index'
import logger from 'services/logger'
import { tariffKeys } from 'services/queryClient/queryKeys'

export const useGetTariffsData = () => {
  const { tariff } = useApi()

  return useQuery(tariffKeys.tariffs(), () => tariff.getTariffsData(), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })
}

export const useBuyTariff = () => {
  const { tariff } = useApi()
  const [isLoading, setIsLoading] = useState(false) // Add isLoading state

  const mutation = useMutation(tariff.buyTariff, {
    retry: false,
    select: (res) => res.data,
    onError: (error) => logger(error),
    onMutate: () => setIsLoading(true), // Set isLoading to true before the request
    onSettled: () => setIsLoading(false) // Set isLoading to false after the request
  })

  return {
    isLoading,
    ...mutation
  }
}

export const useStopSubscription = () => {
  const { tariff } = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const mutation = useMutation(tariff.stopSubscription, {
    retry: false,
    select: (res) => res.data,
    onError: (error) => logger(error),
    onMutate: () => setIsLoading(true),
    onSettled: () => setIsLoading(false)
  })

  return {
    isLoading,
    ...mutation
  }
}

export const useDowngrade = () => {
  const { tariff } = useApi()

  return useMutation(tariff.downgrade, {
    retry: false,
    onError: (error) => logger(error)
  })
}