import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useLocation, Navigate } from 'react-router-dom'

import { useAuthWithGoogle, useAuthWithGithub } from 'hooks/api/useAuth'
import { useLinkGithub } from 'hooks/api/useAccount'
import ROUTES from 'config/constants/routes'

function Auth() {
  const location = useLocation()
  const { code, state, coupon_code: couponCode } = queryString.parse(location.search)

  const { mutate: loginWithGoogle, isSuccess: isSuccessGoogle } = useAuthWithGoogle()
  const { mutate: loginWithGithub, isSuccess: isSuccessGithub } = useAuthWithGithub()
  const { mutate: linkGithub, isSuccess: isSuccessLink } = useLinkGithub()

  useEffect(() => {
    if (code && couponCode && location.pathname.includes('auth/google/callback')) {
      loginWithGoogle({
        code,
        state: {
          state,
          coupon_code: couponCode
        }
      })
    }
    if (code && couponCode && location.pathname.includes('auth/github/callback') && state != 'sync') {
      loginWithGithub({
        code,
        state: {
          state,
          coupon_code: couponCode
        }
      })
    }
    if (code && !couponCode && location.pathname.includes('auth/google/callback')) {
      loginWithGoogle({ code, state })
    }
    if (code && !couponCode && location.pathname.includes('auth/github/callback') && state != 'sync') {
      loginWithGithub({ code, state })
    }
    if (code && location.pathname.includes('auth/github/callback') && state == 'sync') {
      linkGithub({ code, state })
    }
  }, [code, state])

  switch (true) {
  case isSuccessGoogle || isSuccessGithub: {
    if (couponCode) return <Navigate to={`${ROUTES.coupons}/${couponCode}`} />
    return <Navigate to={ROUTES.dashboard} />
  }
  case isSuccessLink: {
    return <Navigate to={ROUTES.settings} />
  }
  default:
    return null
  }
}

export default Auth

