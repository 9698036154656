import classNames from 'classnames'
import React, { useContext } from 'react'

import { CommonDataContext } from 'HOCs/withCommonData'
import { ModalContext } from 'HOCs/withModalProvider'
import { SettingsContext } from 'HOCs/withSettingsProvider'
import Banner from 'components/Banner/Banner'
import { HeaderAuth, HeaderNoAuth } from 'components/Header'
import Icon from 'components/Icons/Icon'
import Sidebar from 'components/Sidebar/Sidebar'
import { MODAL_TYPES } from 'config/constants/constants'
import { useToken } from 'hooks/api/useAuth'
import { useNavigate } from 'react-router'


const FloatingSearchButton = ({ openModal }) => {
  return (
    <button
      className="floating-search-icon"
      onClick={(event) => openModal(event, MODAL_TYPES.search)}
    >
      <div className="wrapper">
        <Icon className="searchIcon" search />
      </div>
    </button>
  )
}

const FloatingChatButton = ({ openModal }) => {
  return (
    <a href="https://t.me/botfatherdev_support_bot" className="floating-chat-icon">
      <div className="wrapper">
        <Icon className="chatIcon" chat />
      </div>
    </a>
  )
}



const withDashboardLayout = () => (Component) => {
  return function (props) {
    const { data: token, isLoading: isCheckingToken } = useToken()
    const { accountData } = useContext(SettingsContext)
    const { notificationData, banners } = useContext(CommonDataContext)
    const { onOpen: openModal } = useContext(ModalContext)

    if (isCheckingToken) {
      return null
    }


    return isCheckingToken ? null : (
      <>
        {token ? (
          <HeaderAuth
            currentTariff={accountData?.tariff}
            email={accountData?.email}
            firstName={accountData?.first_name}
            lastName={accountData?.last_name}
            avatar={accountData?.avatar}
            timezone={accountData?.timezone}
            notificationData={notificationData?.pages[0]}
          />
        ) : (
          <HeaderNoAuth />
        )}

        <div
          className={classNames('after-authorization', {
            'banner-offset': banners?.length > 0,
          })}
        >
          {banners && banners.length > 0 && (
            <Banner
              title={banners[0].title}
              slogan={banners[0].slogan}
              link={banners[0].link}
            />
          )}
          <div className="dashboard">
            <Sidebar isAuth={Boolean(token)} />
            <FloatingSearchButton openModal={openModal} />
            <FloatingChatButton openModal={openModal} />
            <div className="cnt">
              <Component {...props} isAuth={Boolean(token)} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withDashboardLayout
