import SpinnerLoader from 'components/Modal/SpinnerLoader'
import { useState } from 'react'
import { SSE } from 'sse'

const useStreamChatCompletion = (userId) => {
  const [messages, setMessages] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const sendUserMessage = async (message) => {
    const updatedMessages = [...messages, { content: message, role: 'user' }]
    setMessages(updatedMessages)
    await fetchData(updatedMessages)
  }

  const clear = () => {
    setMessages([])
    setError(null)
    setLoading(false)
  }

  const fetchData = async (messages) => {
    setLoading(true)

    // use only 10 last messages
    const requestMessages = messages.slice(-10)
    // check if some messages are longer than 1000 characters:
    for (let i = 0; i < requestMessages.length; i++) {
      if (requestMessages[i].content.length > 6000) {
        setError('Message is too long')
        console.log('Message is too long')
        return
      }
    }

    try {
      const token = localStorage.getItem('token')
      const response = new SSE(
        'https://botfather.dev/webhook/gpt/completion/BotfatherDEV',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          payload: JSON.stringify({
            messages: requestMessages,
            user_id: userId,
            model: 'gpt-4',
            stream: true,
          }),
        }
      )
      // Add a placeholder for the bot's response
      setMessages((prev) => [
        ...prev,
        {
          content: <SpinnerLoader />,
          role: 'assistant',
        },
      ])
      let accumulatedMessages = ''
      response.addEventListener('message', (e) => {
        if (e.data != '[DONE]') {
          let payload = JSON.parse(e.data)
          let text = payload.choices[0].delta.content
          accumulatedMessages += text
          if (text) {
            setMessages((prevMessages) => {
              return [
                ...prevMessages.slice(0, -1),
                {
                  content: accumulatedMessages,
                  role: 'assistant',
                },
              ]
            })
          }
        } else {
          response.close()
          setLoading(false)
        }
      })

      response.addEventListener('readystatechange', (e) => {
        if (e.readyState >= 2) {
          if (e.readyState == 2) {
            response.close()
            setLoading(false)
            // get error message from response
          }
          setLoading(false)
        }
      })

      response.stream()
    } catch (error) {
      console.log(error)
      setLoading(false)
      setError(error)
    }
  }

  return { messages, sendUserMessage, error, loading, clear }
}

export default useStreamChatCompletion
