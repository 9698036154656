import React, { memo, useState, useCallback, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import useOutsideClick from 'hooks/useOutsideClick'

const Select = memo((props) => {
  const { list, defaultItem, onSelect } = props

  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [active, setActive] = useState(defaultItem)

  const ref = useRef()
  useOutsideClick(ref, () => {
    if (isSelectOpen) setIsSelectOpen(false)
  })

  const handelSelect = useCallback(
    (item) => {
      onSelect(item.value)
      setActive(item)
      setIsSelectOpen(false)
    },
    [onSelect, setActive, setIsSelectOpen]
  )

  return (
    <div className={classNames('select', { active: isSelectOpen })} ref={ref} onClick={() => setIsSelectOpen(!isSelectOpen)}>
      <p className="select__val">{active?.label}</p>
      <ul className="select-list">
        {list.map((item) => (
          <li key={item.label + item.value} onClick={() => handelSelect(item)}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  )
})

Select.propTypes = {
  onSelect: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    })
  ),
  defaultItem: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.string,
  }),
}

export default Select
