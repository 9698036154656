import React, { memo } from 'react'

import cap from 'assets/images/main-page/course/cap.png'
import glasses from 'assets/images/main-page/course/glasses.png'
import hat from 'assets/images/main-page/course/hat.png'
import lamp from 'assets/images/main-page/course/lamp.png'
import { useTranslation } from 'react-i18next'

const images = { cap, lamp, hat, glasses }

const LevelSection = memo(({ title, levels }) => {
  const { t } = useTranslation()

  return (
    <section className="course section-padding">
      <div className="course__container container">
        <div className="course__title title">
          <h2>{t(title.title)}</h2>
        </div>
        {levels.map((level) => (
          <div className="course__block block" key={level.title}>
            <div className="block__img-wrap">
              <img src={images[level.iconName]} width="438" height="438" alt={images[level.iconName]} />
            </div>
            <div className="block__list-wrap list-wrap">
              <div className="block__title title">
                <h3>{t(level.title)}</h3>
              </div>
              <ul className="list langing-levels-section">
                {level.skills.map((skill) => (
                  <li key={skill.text}>{t(skill.text)}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>)
})

export default LevelSection
