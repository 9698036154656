import React from 'react'
const TariffArrowSvg = () => (
  <svg
    viewBox="0 0 42 32"
    width="40"
    height="50"
    xmlns="http://www.w3.org/2000/svg"
    className="tariff-arrow"
    fill={null}
  >
    <path
      d="M31.9,14.6l-4-8C27.7,6.2,27.4,6,27,6H5C4.6,6,4.3,6.2,4.1,6.6l-4,8l0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3L0,15
		c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.2,0.3l15,16c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l15-16
		C32,15.4,32.1,14.9,31.9,14.6z M16,8.9l3.2,5.1h-6.4L16,8.9z M10.9,13.2L7,8h7.2L10.9,13.2z M17.8,8H25l-3.9,5.2L17.8,8z M19.6,16
		L16,27.6L12.4,16H19.6z M13.8,27.1L3.3,16h7L13.8,27.1z M21.8,16h7L18.2,27.1L21.8,16z M29.4,14H23l3.8-5.1L29.4,14z M5.2,8.9L9,14
		H2.6L5.2,8.9z"
    />
    <path d="M16,4c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v2C15,3.6,15.4,4,16,4z" />
    <path
      d="M10.3,3.7c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0l-1-1c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
		L10.3,3.7z"
    />
    <path
      d="M21,4c0.3,0,0.5-0.1,0.7-0.3l1-1c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1,1c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0
		C20.5,3.9,20.7,4,21,4z"
    />
  </svg>
)

export default TariffArrowSvg
