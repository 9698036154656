import React, { memo } from 'react'
import Marquee from 'react-fast-marquee'
import useWindowDimensions from 'hooks/useWindowDimension'

const Banner = memo(({ title, slogan, link }) => {
  const { width } = useWindowDimensions()

  return (
    <a href={link} className="news-strip" target="_blank" rel="noreferrer">
      <span className="news-strip__duplicate-text">{slogan}</span>
      {width > 768 && (
        <Marquee className="news-strip__text" gradient={false}>
          <p>{title}</p>
        </Marquee>
      )}
      <span className="news-strip__duplicate-text news-strip__right-wrap">{slogan}</span>
    </a>
  )
})

export default Banner
