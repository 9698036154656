import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { useGetCouponData, useActivateCoupon } from 'hooks/api/useOtherRequests'
import { useToken } from 'hooks/api/useAuth'
import { ModalContext } from 'HOCs/withModalProvider'
import { MODAL_TYPES } from 'config/constants/constants'
import moment from 'services/moment'
import botImg from 'assets/images/other/bot.png'
import ROUTES from 'config/constants/routes'
import {Trans, useTranslation} from 'react-i18next'

function Coupon() {
  const { data: token } = useToken()
  const navigate = useNavigate()
  const { code } = useParams()
  const { data } = useGetCouponData(code)
  const { onOpen: openAuthModal, setData: setModalData } = useContext(ModalContext)

  const { mutate: activateCoupon, isSuccess } = useActivateCoupon()

  const untilDate = data?.until_date && moment(data.until_date).format('DD MMMM YYYY г.')

  if (isSuccess) {
    navigate(ROUTES.tariffs)
  }

  useEffect(() => {
    if (data && data.code) setModalData({ couponCode: data.code })
  }, [data, setModalData])

  return !data ? null : (
    <section className="coupons">
      <div className="container">
        <div className="coupons__desc-wrap">
          <div className="text-wrap">
            <p>
              <Trans i18nKey="coupon-text-1"/> <span>{data.discount}%</span>
            </p>
          </div>

          {token ? (
            <div className="text-wrap">
              <p>
                <Trans i18nKey="coupon-activate-text"/> <span><Trans i18nKey="word-activate"/></span>
              </p>
            </div>
          ) : (
            <div className="text-wrap">
              <p>

                <Trans i18nKey="coupon-activate-text-2"/> <span><Trans i18nKey="word-activate"/></span>
              </p>
            </div>
          )}

          <div className="text-wrap">
            <p>
              <Trans i18nKey="coupon-text-2"/> <span>{untilDate}</span>
            </p>
          </div>
        </div>
        <div className="coupons__img-wrap">
          <img src={botImg} alt="" />
        </div>
        <div className="coupons__btn-wrap">
          {token ? (
            <button type="button" className="coupons__btn btn btn_border-gossamer" onClick={() => activateCoupon({ code })}>
              <Trans i18nKey="word-activate"/>
            </button>
          ) : (
            <button
              type="button"
              className="coupons__btn btn btn_border-gossamer"
              onClick={(event) => openAuthModal(event, MODAL_TYPES.signin)}
            >
              <Trans i18nKey="word-authorization"/>
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Coupon
