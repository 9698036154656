import { useQuery, useMutation } from 'react-query'
import { useApi } from 'services/api/index'
import { streamsKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetStreams = () => {
  const { streams } = useApi()

  return useQuery(streamsKeys.streams(), () => streams.getStreams(), {
    onError: (error) => logger(error),
    select: (res) => res.data,
    enabled: localStorage.getItem('token') ? true : false,
    keepPreviousData: true
  })
}

export const useGetStreamDetails = (slug) => {
  const { streams } = useApi()

  return useQuery(streamsKeys.streamDetails(slug), () => streams.getStreamDetails(slug), {
    select: (res) => res.data,
    onError: (error) => logger(error),
    enabled: localStorage.getItem('token') ? true : false,
    keepPreviousData: true
  })
}

export const useBuyStream = (onSuccessTransfer, onFailureTransfer) => {
  const { streams } = useApi()

  return useMutation(streams.buyStream, {
    retry: false,
    onSuccess: () => onSuccessTransfer(),
    onError: (error) => {
      logger(error)
      onFailureTransfer()
    }
  })
}

export const useSubscribeStream = (onSuccessTransfer, onFailureTransfer) => {
  const { streams } = useApi()

  return useMutation(streams.subscribeStream, {
    retry: false,
    onSuccess: () => onSuccessTransfer(),
    onError: (error) => {
      logger(error)
      onFailureTransfer()
    }
  })
}
