import { SettingsContext } from 'HOCs/withSettingsProvider'
import classnames from 'classnames'
import Icon from 'components/Icons/Icon'
import { articleMarkdown } from 'config/markdownConfig'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import useStreamChatCompletion from 'hooks/api/useChatGPT'
import useToastNotification from 'hooks/useToastNotification'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import remarkToc from 'remark-toc'
import './ChatWidget.scss'

// Custom Markdown Renderer Component
const SafeMarkdown = ({ children }) => {
  return (
    <ReactMarkdown
      components={articleMarkdown}
      remarkPlugins={[remarkToc]}
      rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
    >
      {children}
    </ReactMarkdown>
  )
}

const ChatWidget = () => {
  const [isActive, setIsActive] = useState(false)
  const [newMessage, setNewMessage] = useState('')
  const inputRef = useRef(null)
  const messagesEndRef = useRef(null)
  const { t } = useTranslation()
  const { accountData } = useContext(SettingsContext)
  const { notify, notifyStatus } = useToastNotification()
  const {
    messages,
    sendUserMessage,
    loading: isWriting,
    clear: clearMessages,
  } = useStreamChatCompletion(Number(accountData?.telegram_id))

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus()
    }
  }, [isActive, messages])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sendMessage = () => {
    if (accountData?.tariff_id < 9) {
      notify(
        t('You need to buy a tariff to use this feature'),
        notifyStatus.error
      )
      return
    }

    if (newMessage.trim() === '') return
    setNewMessage('')
    // Focus the input after sending the message
    inputRef.current.focus()
    sendUserMessage(newMessage)
  }

  const clearHistory = () => {
    clearMessages()
  }

  // State to hold dimensions
  const [dimensions, setDimensions] = useState({ width: 500, height: 200 })

  // Ref for the resizable element
  const resizeRef = useRef(null)

  // Function to handle the resizing
  const handleResize = (e) => {
    if (resizeRef.current) {
      const dimensions = {
        width: Math.max(
          300,
          e.clientX - resizeRef.current.getBoundingClientRect().left
        ),
        height: Math.max(
          500,
          e.clientY - resizeRef.current.getBoundingClientRect().top
        ),
      }
      setDimensions(dimensions)
    }
  }

  // Stop resizing
  const stopResize = (e) => {
    document.removeEventListener('mousemove', handleResize)
    document.removeEventListener('mouseup', stopResize)
  }

  return (
    <div
      className="chat-widget"
      style={{
        right: isActive ? '20px' : '-1000%',
        width: `${dimensions.width}px`,
        height: `${dimensions.height}px`,
      }}
    >
      <button
        className={classnames('chat-toggle', {
          'chat-toggle__inactive': !isActive,
        })}
        onClick={() => setIsActive(!isActive)}
      >
        <Icon ai_chat />
        {isActive ? t('Close') : t('ai-chat-button')}
      </button>
      {isActive && (
        <div className="chat-header">
          <span className="header-text">
            <Icon ai_chat />
            <Trans>ai-chat-header</Trans>
          </span>
          <div className="clear-history" onClick={clearHistory}>
            <Icon bin />
          </div>
        </div>
      )}

      <div className={`chat-content ${isActive ? 'active' : ''}`}>
        <div className="messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              {typeof message.content === 'string' ? (
                <SafeMarkdown>{message.content}</SafeMarkdown>
              ) : (
                message.content
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="input-box">
        <div className="input-area">
          <textarea
            ref={inputRef}
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={t('Type a message...')}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault()
                setNewMessage((prev) => prev + '\n')
              } else if (e.key === 'Enter' && !isWriting) {
                e.preventDefault()
                sendMessage()
              } else if (e.key === 'Escape') {
                setIsActive(false)
              }
            }}
          />
        </div>
        <button
          onClick={sendMessage}
          disabled={!newMessage.trim() || isWriting}
        >
          {t('Send')}
        </button>
        <div className="footer-description">
          <p>
            <b>Shift + Enter</b> - {t('New line')}
          </p>
          <p>
            <b>Esc</b> - {t('Close')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ChatWidget
