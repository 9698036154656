import React, {useContext} from 'react'
import {compose} from 'redux'

import Avatar from './AvatarForm'
import ProfileForm from './ProfileForm'
import InterfaceSettings from './InterfaceSettings'
import SocialSettings from './SocialSettings'

import {useGetAccountSettings} from 'hooks/api/useAccount'
import {useUpdateProfile, useGetPycharmCoupon} from 'hooks/api/useAccount'

import withAuth from 'HOCs/withAuth'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import {SettingsContext} from 'HOCs/withSettingsProvider'

function Setting() {
  const {data} = useGetAccountSettings()
  const {mutate: updateProfile} = useUpdateProfile()
  const {mutate: getPycharmCoupon} = useGetPycharmCoupon()
  const {theme, onThemeChange, onCursorChange, cursor} = useContext(SettingsContext)
  return (
    <>
      {data && (
        <section className="profile-settings">
          <div className="profile-settings__container container">
            <Avatar avatarUrl={data.avatar}/>
            <ProfileForm updateProfile={updateProfile} user={data}/>
            <SocialSettings telegramId={data.telegram_id} githubId={data.github_id}/>
            <InterfaceSettings theme={theme} onThemeChange={onThemeChange} onCursorChange={onCursorChange}
              cursor={cursor} pycharm_coupon={data?.pycharm_coupon?.code} PycharmGetUserUrl={getPycharmCoupon}/>
          </div>
        </section>
      )}
    </>
  )
}

export default compose(withDashboardLayout(), withAuth())(Setting)
