import React, { useMemo, createContext } from 'react'

import { useToken } from 'hooks/api/useAuth'
import { useGetNotificationData } from 'hooks/api/useAccount'
import { useGetBanners } from 'hooks/api/useOtherRequests'

const CommonDataContext = createContext(null)

const withCommonData = () => (Component) => {
  return function (props) {
    const { data: token } = useToken()
    const { data: notificationData } = useGetNotificationData()
    const { data: banners } = useGetBanners()

    const memoizedCommonDataContext = useMemo(() => {
      if (!token) return {}
      return { notificationData, banners }
    }, [token, notificationData, banners])

    return (
      <CommonDataContext.Provider value={memoizedCommonDataContext}>
        <Component {...props} />
      </CommonDataContext.Provider>
    )
  }
}

export { withCommonData as default, CommonDataContext }
