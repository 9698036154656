import React, {memo} from 'react'
import {Link} from 'react-router-dom'
import ROUTES from 'config/constants/routes'
import {Trans, useTranslation} from 'react-i18next'

export const NotAuthContent = memo(() => {

  return (
    <section className="dashboard-main">
      <div className="dashboard-main__container container">
        <p>
          <Trans i18nKey="unauthorized-message"/>

        </p>
        <Link to={ROUTES.main}>
          <Trans i18nKey="return-to-main-page"/>
        </Link>
      </div>
    </section>
  )
})

export const NotAvailableContent = memo(() => {
  return (
    <section className="dashboard-main">
      <div className="dashboard-main__container container">
        <p>
          <Trans i18nKey="unauthorized-message-no-access"/>
        </p>
      </div>
    </section>
  )
})
