import React, { memo, useRef } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import useOutsideClick from 'hooks/useOutsideClick'
import ROUTES from 'config/constants/routes'
import moment from 'services/moment'
import {Trans, useTranslation} from 'react-i18next'

const NotificationMenu = memo(({ isOpen, toggleOpen, data, onRead, timezone }) => {
  const ref = useRef()
  useOutsideClick(ref, () => {
    if (isOpen) toggleOpen()
  })
  const { t } = useTranslation()

  return (
    <ul ref={ref} className={classNames('notification-list', { active: isOpen })}>
      {data?.unreadCount === 0 ? (
        <div className="notification_empty">
          <Trans i18nKey="notification-empty"/>
        </div>
      ) : (
        data?.data?.map((notification, index) => {
          if (index > 1) return null
          if (notification.read === true) return null

          const date = moment.dateWithTimezone(notification.created_at, timezone).calendar()

          return (
            <li key={notification.id}>
              <button type="button" className="link-message" onClick={() => onRead(notification.id)}>
                <div className="link-message__left-side">
                  <p className="link-message__desc">{notification.message}</p>
                  <time className="link-message__date">{date}</time>
                </div>
              </button>
            </li>
          )
        })
      )}

      <li className="show-all">
        <Link to={ROUTES.notification}>
          <Trans i18nKey="notification-show-all"/>
        </Link>
      </li>
    </ul>
  )
})

NotificationMenu.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.any,
  timezone: PropTypes.string,
  toggleOpen: PropTypes.func,
  onRead: PropTypes.func
}

export default NotificationMenu
