import classNames from 'classnames'
import StateRatings from './StateRatings'
import styles from './Ratings.module.css'
import SitesRatings from './SitesRatings'
import {fetchQuery} from '../api/axiosQuery'
import React, {useEffect, useState} from 'react'
import {compose} from 'redux'

import withAuth from 'HOCs/withAuth'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import {useGetAccountSettings} from '../../../hooks/api/useAccount'
import {Trans, useTranslation} from 'react-i18next'

const Ratings = () => {
  const {data} = useGetAccountSettings()
  const [idx, setIdx] = useState(0)
  const [total, setTotal] = useState(0)
  const [user, setUser] = useState({})
  const [members, setMembers] = useState([])
  const [list, setList] = useState(0)
  const {t, i18n} = useTranslation()

  useEffect(() => {
    fetchQuery('total_users')
      .then(totals => setTotal(
        createNumberFormat(
          totals.record.total
        )
      ))
  }, [])

  useEffect(() => {
    // Only run the effect if `data` is defined and has an `id` property
    if (data && data.id) {
      fetchQuery(`my_rating/${data.id}`)
        .then(user => setUser(user))
    }
  }, [data])  // This will run the effect whenever `data` changes

  useEffect(() => {
    fetchQuery('all_users')
      .then(member => {
        setMembers(member.records)
      })
  }, [])

  const classes = {
    contents: classNames(
      styles.rating__content,
      styles.rating__content_news
    ),
    containers: classNames(
      styles.rating__container,
      styles.rating__container_news
    )
  }

  const createNumberFormat = (number) => (
    new Intl.NumberFormat(i18n.language).format(number || 0)
  )

  return (
    <>
      {data && (
        <div className={styles.rating}>
          <div className={styles.rating__main}>
            <div className={styles.rating__block}>
              <h2 className={styles.rating__title}>
                <Trans i18nKey="my-rating-title"/>
              </h2>
              <StateRatings
                user={user}
                styles={styles}
                isMyRating={true}
                classes={classes}
                total={total}
                numberFormat={createNumberFormat}
              />
            </div>
            <div className={styles.rating__block}>
              <div className={styles.rating__flex}>
                <h2 className={styles.rating__title}>
                  <Trans i18nKey="rating-members"/>
                </h2>
                <p className={styles.rating__total}>
                  <Trans i18nKey="rating-total"/>
                  {total}</p>
              </div>
              <div className={styles.rating__tables}>
                {members.map((member, id) =>
                  <StateRatings
                    key={id}
                    list={idx}
                    index={id}
                    user={member}
                    styles={styles}
                    isMyRating={false}
                    classes={classes}
                    numberFormat={createNumberFormat}
                  />
                )}
              </div>
            </div>
          </div>
          <SitesRatings
            idx={idx}
            setIdx={setIdx}
            styles={styles}
            list={list}
          />
        </div>
      )}
    </>
  )
}


export default compose(withDashboardLayout(), withAuth())(Ratings)
