import React, {memo, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {FreeMode, Mousewheel, Navigation, Scrollbar} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/scss/navigation'

import ROUTES from 'config/constants/routes'
import Icon from 'components/Icons/Icon'

const SliderItem = memo(({name, image, id, slug, hasAccess, currentLessonId}) => {
  const imageUrl = `${process.env.REACT_APP_API_URL}${image}`
  const isCurrentLesson = currentLessonId === id
  switch (true) {
  case hasAccess:
    return (
      <Link to={`${ROUTES.lessonDetail}/${slug}`} className="link">
        <div className={`img-wrap${isCurrentLesson ? ' isCurrent' : 'd'}`}>
          <img src={imageUrl} width="193" height="108" alt=""/>
        </div>
        <span className="lesson-number">{name}</span>
      </Link>
    )
  case !hasAccess:
  default:
    return (
      <div className="link blocked">
        <div className="img-wrap pointer-events_none">
          <div className="blocked-icon">
            <Icon blocked/>
          </div>
          <img src={imageUrl} width="193" height="108" alt=""/>
        </div>
        <span className="lesson-number pointer-events_none">{name}</span>
      </div>
    )
  }
})

const LessonSlider = memo(({data, currentLessonId}) => {
  const initialSlideIndex = useMemo(() => {
    const currentLesson = data.find((lesson) => lesson.id === currentLessonId)
    return data.indexOf(currentLesson)
  }, [data, currentLessonId])

  return (
    <Swiper
      className="slider"
      slidesPerView={3}
      direction={'horizontal'}
      centeredSlides={true}
      loop={false}
      spaceBetween={20}
      initialSlide={initialSlideIndex}
      breakpoints={{
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
          direction: 'horizontal'
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
          direction: 'horizontal',
          navigation: false
        },
        1541: {
          slidesPerView: 4,
          spaceBetween: 50,
          direction: 'vertical',
          navigation: false
        }
      }}
      freeMode={true}
      scrollbar={true}
      mousewheel={true}
      navigation={false}
      modules={[Navigation, FreeMode, Scrollbar, Mousewheel]}
    >
      {data.map((lesson) => (
        <SwiperSlide key={lesson.id}>
          <SliderItem hasAccess={lesson.access} id={lesson.id} slug={lesson.slug} name={lesson.name}
            image={lesson.preview_image} currentLessonId={currentLessonId}/>
        </SwiperSlide>
      ))}
    </Swiper>
  )
})

export default LessonSlider
