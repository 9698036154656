import React from 'react'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { useGetNews } from 'hooks/api/useNews'
import Preview, { previewTypes } from 'components/Preview/Preview'

function News({ isAuth }) {
  const { data } = useGetNews()
  const news = data?.data

  return !data ? null : (
    <section className="news">
      <div className="news__container container">
        <div className="list-articles">
          {news.map(({ meta, ...rest }) => (
            <Preview
              key={rest.id}
              type={previewTypes.news}
              title={meta?.title || rest.title}
              description={meta?.description}
              slug={rest.slug}
              createdAt={rest.created_at}
              imgUrl={rest.preview_image}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default withDashboardLayout()(News)
