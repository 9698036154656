import rocketImg from 'assets/images/main-page/study/rocket.png'
import ROUTES from 'config/constants/routes'
import React, { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const CourseProve = memo(({ data }) => {
  const { t } = useTranslation()
  return (
    <section className="study section-padding">
      <div className="container">
        <div className="study__title title">
          <h2>{t(data.title)}</h2>
        </div>
        <div className="about-study">
          <div className="about-study__desc-wrap">
            {data.courseProveLeft.map((item) => (
              <p key={item.text}>{t(item.text)}</p>
            ))}
          </div>
          <div className="about-study__img-wrap">
            <img src={rocketImg} width="556" height="556" alt="rocket" />
          </div>
          <div className="about-study__desc-wrap">
            {data.courseProveRight.map((item) => (
              <p key={item.text}>{t(item.text)}</p>
            ))}
          </div>
        </div>
        <div className="study__btn-wrap">
          <Link to={ROUTES.courseProgram} className="btn btn_border-pattensBlue">
            <Trans i18nKey="word-more" />
          </Link>
        </div>
      </div>
    </section>
  )
})

export default CourseProve
