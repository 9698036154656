import { useState } from 'react'

const normalizeOptions = {
  onlyNumbers: /[^0-9.]/g,
}

const useInputState = (normalize) => {
  const [inputValue, setInputValue] = useState('')

  const handleOnChange = (event) => {
    const value = event.target.value.replace(normalize, '')
    setInputValue(value)
  }

  return [inputValue, handleOnChange, setInputValue]
}

export { normalizeOptions }
export default useInputState
