import { useQuery } from 'react-query'
import { useApi } from 'services/api/index'
import { dashboardKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetDashboardData = () => {
  const { dashboard } = useApi()

  return useQuery(dashboardKeys.dashboard(), () => dashboard.getDashboardData(), {
    select: (res) => res.data,
    onError: (error) => logger(error)
  })
}
