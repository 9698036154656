import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { useGetCourseProgramData } from 'hooks/api/useOtherRequests'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { articleMarkdown } from 'config/markdownConfig'

function CourseProgram() {
  const { data } = useGetCourseProgramData()

  return (
    <>
      {data && (
        <section className="course-program">
          <div className="container">
            <div className="title">
              <h1>{data.data.title}</h1>
              <div>{data.data.date}</div>
            </div>
            <div className="course-program__desc-wrap">
              <ReactMarkdown
                components={articleMarkdown}
                remarkPlugins={[remarkToc]}
                rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                children={data.data.content}
                // skipHtml={true}
                linkTarget={(href, children, title) => {
                  if (href[0] !== '#') return '_blank'
                }}
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default withDashboardLayout()(CourseProgram)
