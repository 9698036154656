import React, { memo, useState, useCallback, useRef, useContext } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import BurgerButton from 'components/Buttons/BurgerButton'
import useOutsideClick from 'hooks/useOutsideClick'
import { ModalContext } from 'HOCs/withModalProvider'
import { MODAL_TYPES } from 'config/constants/constants'
import ROUTES from 'config/constants/routes'
import { Trans, useTranslation } from 'react-i18next'

const Header = memo(({ onScrollTo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen])

  const menuRef = useRef()
  useOutsideClick(menuRef, () => {
    if (isMenuOpen) setIsMenuOpen(false)
  })

  const { onOpen: openModal } = useContext(ModalContext)

  return (
    <header className="main-header">
      <div className="container">
        <BurgerButton
          isActive={isMenuOpen}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        <nav className="nav-wrap">
          <div className={classNames('burger-menu', { 'is-open': isMenuOpen })}>
            <div className="burger-menu__container" ref={menuRef}>
              <ul className="burger-list">
                <li>
                  <button
                    type="button"
                    onClick={() => onScrollTo('ad', closeMenu)}
                  >
                    <Trans i18nKey="order-bot" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => onScrollTo('author', closeMenu)}
                  >
                    <Trans i18nKey="course-author" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => onScrollTo('review', closeMenu)}
                  >
                    <Trans i18nKey="reviews" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => onScrollTo('tariff', closeMenu)}
                  >
                    <Trans i18nKey="registration" />
                  </button>
                </li>
              </ul>
              <div className="burger-menu__btns-wrap">
                <button
                  type="button"
                  className="btn"
                  onClick={(event) => openModal(event, MODAL_TYPES.login)}
                >
                  <Trans i18nKey="login" />
                </button>
                <button
                  type="button"
                  className="btn btn_pattensBlue"
                  onClick={(event) => openModal(event, MODAL_TYPES.signin)}
                >
                  <Trans i18nKey="registration" />
                </button>
              </div>
            </div>
          </div>
          <div className="menu">
            <ul className="nav-list">
              <li>
                <Link
                  className='nav-list__link'
                  to={ROUTES.news}>Блог</Link>
              </li>
              <li>
                <Link
                  className='nav-list__link'
                  to={ROUTES.courseProgram}>
                  <Trans i18nKey="course-program" />
                </Link>
              </li>
            </ul>
            <div className="nav-wrap__btns-wrap">
              <button
                type="button"
                className="btn"
                onClick={(event) => openModal(event, MODAL_TYPES.login)}
              >
                <Trans i18nKey="login" />
              </button>
              <button
                type="button"
                className="btn btn_pattensBlue"
                onClick={(event) => openModal(event, MODAL_TYPES.signin)}
              >
                <Trans i18nKey="registration" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
})

export default Header
