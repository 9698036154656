import useToastNotification from 'hooks/useToastNotification'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Onboarding.scss'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router'

const VideoPage = () => {
  const { notify, notifyStatus } = useToastNotification()
  const { t } = useTranslation()
  const navigate = useNavigate()

  // URL ids for YouTube videos
  const videoIds = ['dQw4w9WgXcQ', 'C0DPdy98e4c']
  const titleContent = t('onboarding.title')
  const textContent = t('onboarding.text')
  const copiedLink = t('onboarding.copiedLink')

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href)
      console.log('Link copied to clipboard!')
      notify(copiedLink, notifyStatus.success)
    } catch (err) {
      console.error('Failed to copy link: ', err)
    }
  }

  const videoIframes = videoIds.map((id) => (
    <iframe
      key={id}
      title={`YouTube video ${id}`}
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ))

  return (
    <div className="onboarding-video-page">
      <div className="video-content">
        <h1 className="onboarding-video-page__title">{titleContent}</h1>
        <ReactMarkdown className="onboarding-video-page__text">
          {textContent}
        </ReactMarkdown>
        <div className="videos">{videoIframes}</div>
        <div className="onboarding-video-page__button">
          <button
            type="button"
            className="btn btn_pattensBlue"
            onClick={() => navigate('/dashboard/tariffs')}
          >
            {t('onboarding.button-tariffs')}
          </button>{' '}
          <button
            type="button"
            className="btn"
            onClick={() => navigate('/dashboard')}
          >
            {t('onboarding.button-dashboard')}
          </button>
        </div>
        <p className="return-link">
          {t('onboarding.returnLink')}
          <pre onClick={copyLinkToClipboard}>{window.location.href}</pre>
        </p>
      </div>
    </div>
  )
}

export default VideoPage
