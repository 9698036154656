import Copy from 'components/Copy/Copy'
import React, { memo } from 'react'
import { Trans } from 'react-i18next'


const InterfaceSettings = memo(({ onThemeChange, theme, onCursorChange, cursor, pycharm_coupon, PycharmGetUserUrl }) => {
  const isDarkMode = theme === 'dark'

  return (
    <div className="site-setting">
      <div className="site-setting__wrap">
        <Trans i18nKey="site-setting-custom-cursor" />
        <label className="switch">
          <input type="checkbox" onChange={onCursorChange} checked={cursor} />
          <span></span>
        </label>
      </div>
      <div className="site-setting__wrap">
        <Trans i18nKey="site-setting-dark-theme" />
        <label className="switch">
          <input type="checkbox" onChange={onThemeChange} checked={isDarkMode} />
          <span></span>
        </label>
      </div>

      <div className="site-setting__wrap">
        <div>
          <span className="site-setting__label">
            <Trans i18nKey="site-setting-pycharm-coupon" />
          </span>
          <span className="site-setting__value">
            {pycharm_coupon ?
              <Copy value={pycharm_coupon} label={pycharm_coupon} button={false} /> : '-'
            }
          </span>
        </div>
        {!pycharm_coupon ? (
          <button type="button" className="btn btn_border-pacificBlue" onClick={PycharmGetUserUrl}>
            <Trans i18nKey="site-setting-pycharm-coupon-get" />
          </button>
        ) :
          (
            <button type="button" className="btn btn_border-pacificBlue" onClick={() => window.open('https://www.jetbrains.com/store/redeem/', '_blank')}>
              <Trans i18nKey="coupon-activate" />
            </button>
          )
        }
      </div>
    </div>
  )
})

export default InterfaceSettings
