import { ModalContext } from 'HOCs/withModalProvider'
import classNames from 'classnames'
import { MODAL_TYPES } from 'config/constants/constants'
import React, { memo, useContext } from 'react'

import botImg from 'assets/images/main-page/intro/bot.png'
import { useTranslation } from 'react-i18next'

const IntroSection = memo(({ data }) => {
  const { t } = useTranslation()
  const { onOpen: openModal } = useContext(ModalContext)

  return (
    <section className="intro section-padding-b">
      <div className="intro__container container">
        <div className="intro__wrap">
          <div className="left-block">
            <div className="intro__title title">
              <h1 dangerouslySetInnerHTML={{ __html: t(data.title) }} />
            </div>
            <p className="left-block__text" dangerouslySetInnerHTML={{ __html: t(data.description) }} />
          </div>
          <div className="right-block">
            <div className="chat">
              <div className="chat__img-wrap">
                <img src={botImg} width="496" height="400" alt="" />
              </div>

              {data.botMessages.map((message, index) => (
                <div
                  key={t(message.text)}
                  className={classNames('chat__message', {
                    chat__message_triangle: data.botMessages.length === index + 1,
                  })}
                >
                  <p>{t(message.text)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="intro__btn-wrap">
          <button type="button" className="btn btn_border-pacificBlue" onClick={(event) => openModal(event, MODAL_TYPES.signin)}>
            {t(data.buttonName)}
          </button>
        </div>
      </div>
    </section>
  )
})

export default IntroSection
