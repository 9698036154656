import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { SettingsContext } from 'HOCs/withSettingsProvider'
import LinkTelegramNotification from 'components/LinkTelegramNotification/LinkTelegramNotification'
import { NotAuthContent } from 'components/NoContent'
import Preview, { previewTypes } from 'components/Preview/Preview'
import ROUTES from 'config/constants/routes'
import { useGetDashboardData } from 'hooks/api/useDashboard'
import { useGetLessons } from 'hooks/api/useLessons'
import { useGetNews } from 'hooks/api/useNews'
import { Trans, useTranslation } from 'react-i18next'
import DashboardSlider from './DashboardSlider'

function Dashboard({ isAuth }) {
  const { accountData } = useContext(SettingsContext)
  const hasTelegram = Boolean(accountData?.telegram_id)
  const { i18n } = useTranslation()

  const { data: news } = useGetNews(1, 3)
  const { data: lessonsData } = useGetLessons()
  const { data: dashboardData } = useGetDashboardData()

  return isAuth ? (
    <section className="dashboard-main">
      <div className="dashboard-main__container container">
        {!hasTelegram && <LinkTelegramNotification />}
        {news && (
          <>
            <div className="title">
              <h2>
                <Trans i18nKey="dashboard-news-title" />
              </h2>

              <Link to={ROUTES.news} className="btn btn_border-darkCerulean">
                <Trans i18nKey="dashboard-news-list" />
              </Link>
            </div>
            <div className="list-articles news-list">
              {news.data.map(({ meta, ...rest }) => (
                <Preview
                  key={rest.id}
                  type={previewTypes.news}
                  title={meta?.title || rest.title}
                  description={meta?.description}
                  slug={rest.slug}
                  createdAt={rest.created_at}
                  imgUrl={rest.preview_image}
                />
              ))}
            </div>
          </>
        )}

        {lessonsData &&
          dashboardData &&
          lessonsData.map((lessons) => (
            <DashboardSlider
              language={i18n.language}
              key={lessons.id}
              data={lessons}
              lastViewedLessonsId={
                dashboardData.lastViewedLessonsIds[lessons.type]
              }
            />
          ))}

        {dashboardData && (
          <div className="about-course">
            <div className="about-course__title title">
              <h2>{dashboardData.data.title}</h2>
            </div>
            <div
              className="about-course__text"
              dangerouslySetInnerHTML={{
                __html: dashboardData.data.description,
              }}
            />
          </div>
        )}
      </div>
    </section>
  ) : (
    <NotAuthContent />
  )
}

export default withDashboardLayout()(Dashboard)
