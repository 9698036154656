import './SpinnerLoader.scss' // Make sure to import the CSS file you'll create

const SpinnerLoader = () => {
  return (
    <div className="preloader-spinner">
      <div className="spinner"></div>
    </div>
  )
}

export default SpinnerLoader
