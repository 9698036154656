import React from 'react'

const Preloader = () => {
  return (
    <div className="pre-loader-wrap">
      <div className="pre-loader"></div>
    </div>
  )
}

export default Preloader
