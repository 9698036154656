import React from 'react'
import { Navigate } from 'react-router-dom'
import { useToken } from 'hooks/api/useAuth'
import ROUTES from 'config/constants/routes'

const withNoAuth = () => (Component) => {
  return (props) => {
    const tokenSync = localStorage.getItem('token')
    const { data: tokenAsync } = useToken()

    if (tokenAsync || tokenSync) return <Navigate to={ROUTES.dashboard} />

    return <Component {...props} />
  }
}

export default withNoAuth
