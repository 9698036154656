import React, { useContext, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'
import ReactPlayer from 'react-player'

import { NotAuthContent } from 'components/NoContent'
import Icon from 'components/Icons/Icon'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import { SettingsContext } from 'HOCs/withSettingsProvider'
import {
  useBuyStream,
  useGetStreamDetails,
  useSubscribeStream,
} from 'hooks/api/useStreams'
import useOutsideClick from 'hooks/useOutsideClick'
import moment from 'moment'
import ROUTES from 'config/constants/routes'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { streamVideoUrl } from 'config/videoUrl'
import { Trans } from 'react-i18next'

function StreamDetail({ isAuth }) {
  const { slug } = useParams()
  const { accountData } = useContext(SettingsContext)
  const timezone = accountData?.timezone

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const closePopup = () => setIsPopupOpen(false)
  const openPopup = () => setIsPopupOpen(true)

  const onSuccessBuy = () => closePopup()

  const { data, refetch } = useGetStreamDetails(slug)
  const stream = data?.data
  const availableSubscriptionNumber = data?.user_streams_available
  const hasAvailableSubscriptions = availableSubscriptionNumber > 0
  const accessDenied = stream?.access === false
  const hasAccessToPastStreams =
		moment(stream?.date_start).isBefore(accountData?.tariff_start_date) &&
		data?.tariff_id >= 9

  const { mutate: buyStream, isSuccess: isBuySuccess } = useBuyStream(
    onSuccessBuy,
    closePopup
  )
  const { mutate: subscribeStream, isSuccess: isSubscribeSuccess } =
		useSubscribeStream(onSuccessBuy, closePopup)

  const date = moment.dateWithTimezone(stream?.date_start, timezone, 'LLL')
  const isDateInPast = moment.isPast(stream?.date_start)
  const streamStartsInFuture = isDateInPast === false
  // const streamDateLabel = isDateInPast ? `Эфир проходил ${date}` : `Начало ${date}`;
  console.log('date', date)
  const streamDateLabel = isDateInPast ? (
    <Trans i18nKey="stream-was" values={{ date }} />
  ) : (
    <Trans i18nKey="stream-starts" values={{ date }} />
  )

  const ref = useRef()
  useOutsideClick(ref, () => {
    if (isPopupOpen) closePopup()
  })

  const handleBuyStream = () => {
    if (hasAvailableSubscriptions) subscribeStream({ id: stream.id })
    if (!hasAvailableSubscriptions) buyStream({ id: stream.id })
  }

  useEffect(() => {
    if (isSubscribeSuccess || isBuySuccess) refetch()
  }, [isSubscribeSuccess, isBuySuccess, refetch])

  switch (true) {
  case !isAuth:
    return <NotAuthContent />
  case accessDenied && !hasAccessToPastStreams:
    return (
      <section className="details-ether">
        {stream && (
          <>
            <div className="details-ether__container container">
              <div className="details-ether__title title">
                <h2>{stream.name}</h2>
              </div>
              <div className="ether-wrap">
                <div className="ether blocked">
                  <Link to={ROUTES.streams} className="ether__link">
                    <span className="indicator" />
                    <Trans i18nKey="word-streams" />
                  </Link>

                  <div className="blocked-wrap">
                    <div className="blocked-icon">
                      <Icon blockedBig />
                    </div>
                    <button
                      type="button"
                      className="btn btn_border-darkCerulean"
                      onClick={openPopup}
                    >
                      <Trans i18nKey="word-buy-access" />
                    </button>
                  </div>
                </div>
                <div className="about-ether">
                  <div className="about-ether__text">
                    <ReactMarkdown
                      remarkPlugins={[remarkToc]}
                      rehypePlugins={[
                        [rehypeHighlight, rehypeHighlightConfig],
                      ]}
                    >
                      {stream.preview_description}
                    </ReactMarkdown>
                  </div>
                  <div
                    className={classNames('status', {
                      'status_not-available': isDateInPast,
                      status_available: !isDateInPast,
                    })}
                  >
                    {streamDateLabel}
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames('pop-up', { active: isPopupOpen })}>
              <div className="pop-up__wrap" ref={ref}>
                <div className="title pop-up__title">
                  <h2>
                    {hasAvailableSubscriptions ? (
                      <Trans i18nKey="subscribe-ether" />
                    ) : (
                      <Trans i18nKey="buy-ether" />
                    )}
                  </h2>
                </div>
                <div className="pop-up__cnt">
                  {hasAvailableSubscriptions ? (
                    <p>
                      <Trans i18nKey="subscribe-ether-text-1" />
                      <strong>{availableSubscriptionNumber}</strong>{' '}
                      <Trans i18nKey="subscribe-ether-text-2" />
                      <strong>
                        <Trans i18nKey="word-subscribe" />
                      </strong>
                    </p>
                  ) : (
                    <p>
                      <Trans i18nKey="buy-ether-text-1" />
                      <strong>
                        <Trans i18nKey="word-buy" />
                      </strong>
                    </p>
                  )}
                </div>
                {!hasAvailableSubscriptions && (
                  <div className="entry-field">
                    <Trans i18nKey="word-amount" />
                    <strong>{stream.price}</strong>
                    <strong>$</strong>
                  </div>
                )}
                <div className="btns-wrap">
                  <button type="button" onClick={closePopup}>
                    <Trans i18nKey="word-cancel" />
                  </button>
                  <button
                    type="button"
                    className="btn btn_pattensBlue"
                    onClick={handleBuyStream}
                  >
                    {hasAvailableSubscriptions ? (
                      <Trans i18nKey="word-subscribe" />
                    ) : (
                      <Trans i18nKey="word-buy" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    )

  case isAuth && !accessDenied && streamStartsInFuture:
    return (
      <section className="details-ether">
        {stream && (
          <div className="details-ether__container container">
            <div className="details-ether__title title">
              <h2>{stream.name}</h2>
            </div>
            <div className="ether-wrap">
              <div className="ether unblocked poster">
                <Link to={ROUTES.streams} className="ether__link">
                  <span className="indicator" />
                  <Trans i18nKey="word-streams" />
                </Link>
                <div className="unblocked-wrap">
                  <Icon successBig />
                  <p className="unblocked-wrap__date">{streamDateLabel}</p>
                  <p>
                    <Trans i18nKey="access-purchased" />
                  </p>
                </div>
              </div>
              <div className="about-ether">
                <div className="about-ether__text">
                  <p>
                    <ReactMarkdown
                      remarkPlugins={[remarkToc]}
                      rehypePlugins={[
                        [rehypeHighlight, rehypeHighlightConfig],
                      ]}
                    >
                      {stream.preview_description}
                    </ReactMarkdown>
                  </p>
                  <p>
                    <a href={`${stream.chat_url}`}>
                      <Trans i18nKey="link-to-chat-with-ether" />
                    </a>
                  </p>
                </div>
                <div
                  className={classNames('status', {
                    'status_not-available': isDateInPast,
                    status_available: !isDateInPast,
                  })}
                >
                  {streamDateLabel}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    )

  case isAuth &&
			(!accessDenied || hasAccessToPastStreams) &&
			!streamStartsInFuture:
    return (
      <section className="details-ether">
        {stream && (
          <div className="details-ether__container container">
            <div className="details-ether__title title">
              <h2>{stream.name}</h2>
            </div>
            <div className="ether-wrap">
              <div className="ether video">
                <ReactPlayer
                  url={streamVideoUrl(stream.id)}
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        onContextMenu: (e) => e.preventDefault(),
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="about-ether">
                <div className="about-ether__text">
                  <ReactMarkdown
                    remarkPlugins={[remarkToc]}
                    rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                    // children={stream.description}
                  >
                    {stream.description}
                  </ReactMarkdown>
                </div>
                <div
                  className={classNames('status', {
                    'status_not-available': isDateInPast,
                    status_available: !isDateInPast,
                  })}
                >
                  {streamDateLabel}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    )
  default:
    return null
  }
}

export default withDashboardLayout()(StreamDetail)
