import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/uk'

// moment().locale('ru');

moment.toDefaultFormat = function (date) {
  return moment(date).format('DD MMMM YYYY')
}

moment.toDefaultFormatShort = function (date) {
  return moment(date).format('YYYY.MM.DD')
}

moment.isPast = function (date) {
  const difference = moment(date).diff(moment())
  if (Math.sign(difference) === 1) return false
  if (Math.sign(difference) === -1) return true
}

moment.dateWithTimezone = function (date, timezone, format) {
  if (!date) return ''
  if (format) return moment(date).utc().utcOffset(Number(timezone)).format(format)
  return moment(date).utc().utcOffset(Number(timezone))
}

export default moment
