import React, { memo } from 'react'
import { Formik, Field } from 'formik'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'
import classNames from 'classnames'
import { useGetTestResults } from 'hooks/api/useLessons'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { Trans, useTranslation } from 'react-i18next'

const getInitialValues = (array) => {
  const initialValues = {}

  array.forEach((element, index) => {
    initialValues[index] = null
  })
  return initialValues
}

const Test = memo(({ data, error, fieldName }) => {
  const errorStatus = error?.isCorrectAnswer
  const { t } = useTranslation()
  return (
    <div
      className={classNames('test-block__item', {
        valid: errorStatus === true,
        invalid: errorStatus === false,
      })}
    >
      <div
        className={classNames('test-block__status', {
          valid: errorStatus === true,
          invalid: errorStatus === false,
        })}
      >
        {errorStatus === true && t('word-correct-answer')}
        {errorStatus === false && t('word-wrong-answer')}
      </div>
      <div className="test-block__title title">
        <div className="text">
          <ReactMarkdown
            remarkPlugins={[remarkToc]}
            rehypePlugins={[rehypeHighlight]}
          >
            {data.description}
          </ReactMarkdown>
        </div>
      </div>

      <div className="answers">
        {data.answers.map((answer, index) => (
          <label className="btn-radio" key={index + answer.name}>
            <span className="btn-radio__text">
              <ReactMarkdown
                remarkPlugins={[remarkToc]}
                rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                children={answer.name}
              />
            </span>
            <Field type="radio" name={fieldName} value={answer.name} />
          </label>
        ))}
      </div>
    </div>
  )
})

const TestForm = memo(
  ({ tests, lessonId, nextLessonSlug, setLessons, onTestPassed }) => {
    const { data, mutate: getTestResults } = useGetTestResults(
      nextLessonSlug,
      onTestPassed
    )
    const results = data?.data?.data
    return (
      <Formik
        initialValues={getInitialValues(tests)}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const answers = Object.entries(values).map((answer) => answer[1])
          getTestResults({ answers, id: lessonId })
        }}
      >
        {(props) => {
          return (
            <form className="test-block" onSubmit={props.handleSubmit}>
              <div className="test-block__wrap">
                {tests.map((question, index) => (
                  <Test
                    key={question.id}
                    fieldName={index}
                    data={question}
                    error={results?.find((i) => i.id === question.id)}
                  />
                ))}
              </div>
              <div className="test-block__btn-wrap">
                <button type="submit" className="btn btn_pattensBlue">
                  <Trans i18nKey="word-send" />
                </button>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }
)

export default TestForm
