import React, { memo } from 'react'
import classNames from 'classnames'
import useCursor from 'hooks/useCursor'

const CursorOutline = memo(() => {
  const { mousePosition, onHover } = useCursor()

  return (
    <div
      className={classNames('cursor-outline', { active: onHover })}
      style={{
        left: `${mousePosition.x}px`,
        top: `${mousePosition.y}px`,
      }}
    ></div>
  )
})

export default CursorOutline
