import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from 'config/constants/routes'
import botImg from 'assets/images/main-page/intro/bot.png'
import {Trans, useTranslation} from 'react-i18next'

const Main = memo(() => {
  return (
    <section className="error404">
      <div className="container">
        <div className="error404__cnt-wrap">
          <div className="error404__img-wrap">
            <img src={botImg} alt="" />
          </div>
          <div className="error404__right-side">
            <div className="title">
              <h1>404</h1>
            </div>
            <div className="error404__desc">
              <p><Trans i18nKey="error404-text-1"/></p>
              <p><Trans i18nKey="error404-text-2"/></p>
            </div>
          </div>
        </div>
        <Link to={ROUTES.main} className="btn btn_border-pacificBlue error404__btn">
          <Trans i18nKey="error404-button"/>
        </Link>
      </div>
    </section>
  )
})

export default Main
