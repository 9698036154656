import React, { useState } from 'react'
import './IntroTest.scss'
import { useNavigate } from 'react-router'
import ROUTES from 'config/constants/routes'

const questions = [
  {
    header:
      'Цей короткий тест оцінить ваші знання Python, щоб зрозуміти чи підходить вам курс. Не хвилюйтеся, якщо ви не впевнені у відповіді - просто виберіть "Не знаю"',
    question: 'Почнемо?',
    options: ['Так'],
  },

  {
    question: 'What is the correct way to create a function in Python?',
    options: [
      'function myFunction():',
      'def myFunction():',
      'create myFunction():',
      'function: myFunction()',
      'I don`t know',
    ],
    correct: 1,
  },
  {
    question: 'How do you insert COMMENTS in Python code?',
    options: [
      '/* This is a comment */',
      '// This is a comment',
      '# This is a comment',
      '<!-- This is a comment -->',
      'I don`t know',
    ],
    correct: 2,
  },
  {
    question: 'Which one is NOT a legal variable name?',
    options: ['my-var', 'my_var', 'myVar', 'MyVar', 'I don`t know'],
    correct: 0,
  },
  {
    question: 'How do you create a variable with the numeric value 5?',
    options: [
      'x = 5',
      'x = int(5)',
      'Both the other answers are correct',
      'I don`t know',
    ],
    correct: 2,
  },
  {
    question: 'What is the correct file extension for Python files?',
    options: ['.py', '.pyth', '.pt', '.pyt', 'I don`t know'],
    correct: 0,
  },

  {
    header: 'Будь ласка, допоможіть нам краще зрозуміти вашу ситуацію',
    question: 'Ким ви працюєте, або навчаєтесь?',
    options: [
      '👨‍💼 Підприємець/власник IT бізнесу',
      '👨‍💼 Підприємець/власник МАЛОГО бізнесу в іншій сфері',
      '👨‍💼 Підприємець/власник ВЕЛИКОГО бізнесу в іншій сфері',
      '💼 Працюю в IT компанії',
      '💼 Працюю в іншій сфері',
      '💼 Працюю в IT та навчаюсь',
      '💼 Працюю в іншій сфері та навчаюсь',
      '💼 Працюю в іншій сфері, але хочу перейти в IT',
      '🎓 Студент/учень, не працюю',
    ],
  },
]

const TestComponent = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [answers, setAnswers] = useState({})
  const navigate = useNavigate()
  const handleNavigation = (direction) => {
    if (direction === 'next' && currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1)
    } else if (direction === 'prev' && currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1)
    }
  }

  const handleAnswer = (index) => {
    setAnswers({ ...answers, [currentQuestion]: index })
  }

  const handleSubmit = () => {
    navigate(ROUTES.onBoarding)
  }

  const isAnswerSelected = currentQuestion in answers
  const currentAnswer = answers[currentQuestion]
  const questionComponents = questions.map((q, index) => (
    <div
      key={q.question}
      className={`question ${index === currentQuestion ? 'active' : ''}`}
    >
      <div className="question-text">{q.question}</div>
      {q.options.map((option, idx) => (
        <label key={idx} className="option">
          <div>{option}</div>
          <input
            type="radio"
            name={`question_${currentQuestion}`}
            checked={currentAnswer === idx}
            onChange={() => handleAnswer(idx)}
          />
        </label>
      ))}
    </div>
  ))
  const progress = ((currentQuestion + 1) / questions.length) * 100

  return (
    <div className="test-container">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="test-content">
        <div className="page-number">
          {currentQuestion + 1} / {questions.length}
        </div>
        <div className="test-content__header">
          {questions[currentQuestion].header}
        </div>

        {questionComponents[currentQuestion]}

        <div className="spacer"></div>
        <div className="navigation-buttons">
          {currentQuestion > 0 && (
            <button className="prev" onClick={() => handleNavigation('prev')}>
              Previous
            </button>
          )}
          {currentQuestion < questions.length - 1 && (
            <button
              className="next"
              onClick={() => handleNavigation('next')}
              disabled={!isAnswerSelected}
            >
              Next Question
            </button>
          )}
        </div>
      </div>
      <div className="submit-container">
        {currentQuestion === questions.length - 1 && (
          <button
            className="submit"
            onClick={handleSubmit}
            disabled={!isAnswerSelected}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  )
}

export default TestComponent
