import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'
import {Trans, useTranslation} from 'react-i18next'

import moment from 'services/moment'
import ROUTES from 'config/constants/routes'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'

const previewTypes = {
  news: 'news',
  streams: 'streams'
}

const Preview = memo(({ title, description, slug, createdAt, type, startDate, timezone, imgUrl }) => {
  const defaultImage = `${process.env.REACT_APP_API_URL}/uploads/images/default/article.png`
  const image = imgUrl ? `${process.env.REACT_APP_API_URL}/${imgUrl}` : defaultImage
  switch (true) {
  case type === previewTypes.news: {
    const date = moment.toDefaultFormat(createdAt)

    return (
      <article className="article">
        <div className="article__cnt">
          <Link to={`${ROUTES.news}/${slug}`}>
            <div className="article__img-wrap pointer-events_none">
              <img src={image} width="400" height="225" alt="" />
            </div>
            <div className="article__right-side pointer-events_none">
              <div className="article__title title">
                <h3>{title}</h3>
              </div>
              <p className={'article__desc' + (!description ? '--min_height' : '')}>{description}</p>
              <time className={'public-date' + (!description ? '--min_height' : '')}>{date}</time>
            </div>
          </Link>
        </div>
      </article>
    )
  }
  case type === previewTypes.streams: {
    const date = moment.dateWithTimezone(startDate, timezone, 'LLL')
    const isDateInPast = moment.isPast(startDate)
    const streamDateLabel = (isDateInPast ? <Trans i18nKey="stream-was" values={{date}}/> : <Trans i18nKey="stream-start" values={{date}}/>)

    return (
      <article className="article">
        <div className="article__cnt">
          <Link to={`${ROUTES.streams}/${slug}`}>
            <div className="article__img-wrap pointer-events_none">
              <img src={image} width="400" height="225" alt="" />
            </div>
            <div className="article__right-side pointer-events_none">
              <div className="article__wrap">
                <div className="article__title title">
                  <h3>{title}</h3>
                </div>
                <div className="article__desc">
                  <ReactMarkdown
                    remarkPlugins={[remarkToc]}
                    rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                    children={description}
                  />
                </div>
              </div>
              <div
                className={classNames('status', {
                  'status_not-available': isDateInPast,
                  status_available: !isDateInPast
                })}
              >
                {streamDateLabel}
              </div>
            </div>
          </Link>
        </div>
      </article>
    )
  }
  default:
    return null
  }
})

export { previewTypes }
export default Preview
