import React, { useCallback, useContext } from 'react'

import { NotAuthContent } from 'components/NoContent'

import { useGetWebinars } from 'hooks/api/useWebinars'

function Webinars({ isAuth }) {
  const { data } = useGetWebinars()
  console.log(data)
  return isAuth ? <>webinars</> : <NotAuthContent />
}

export default Webinars
