import botImg from 'assets/images/main-page/reviews-section/bot.png'
import { TrustBoxReviewSection } from 'components/TrustPilot/TrustPilotWidget'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

const ReviewsSection = forwardRef(({ review, comments }, ref) => {
  const { t } = useTranslation()
  return !review && !comments ? null : (
    <section className="reviews-section section-padding" ref={ref}>
      <div className="reviews-section__container container">
        <div className="reviews-section__title title">
          <h2>{t(review.title)}</h2>
        </div>
        <TrustBoxReviewSection />
        <div className="reviews">
          <div className="img-wrap">
            <img src={botImg} width="370" height="295" alt="" />
          </div>
        </div>

        <div className="img-wrap-adapt">
          <img src={botImg} width="370" height="295" alt="" />
        </div>
        <div
          className="reviews-section__result"
          dangerouslySetInnerHTML={{ __html: t(review.description) }}
        />
      </div>
    </section>
  )
})

export default ReviewsSection
