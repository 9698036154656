import moment from 'services/moment'

export const TIMEZONES = [
  {
    label: 'GMT (-11:00) Мидуэй',
    value: '-11'
  },
  {
    label: 'GMT (-10:00) Гонолулу',
    value: '-10'
  },
  {
    label: 'GMT (-08:00) Анкоридж',
    value: '-8'
  },
  {
    label: 'GMT (-07:00) Лос-Анджелес',
    value: '-7'
  },
  {
    label: 'GMT (-06:00) Денвер',
    value: '-6'
  },
  {
    label: 'GMT (-05:00) Чикаго',
    value: '-5'
  },
  {
    label: 'GMT (-04:00) Торонто',
    value: '-4'
  },
  {
    label: 'GMT (-03:00) Буэнос-Айрес',
    value: '-3'
  },
  {
    label: 'GMT (-02:00) Нуук',
    value: '-2'
  },
  {
    label: 'GMT (-01:00) Кабо-Верде',
    value: '-1'
  },
  {
    label: 'UTC (00:00) Лондон',
    value: '0'
  },
  {
    label: 'GMT (+01:00) Берлин',
    value: '1'
  },
  {
    label: 'GMT (+02:00) Київ ❤',
    value: '2'
  },
  {
    label: 'GMT (+03:00) Москва',
    value: '3'
  },
  {
    label: 'GMT (+04:00) Дубай',
    value: '4'
  },
  {
    label: 'GMT (+05:00) Ташкент',
    value: '5'
  },
  {
    label: 'GMT (+06:00) Дакка',
    value: '6'
  },
  {
    label: 'GMT (+07:00) Новосибирск',
    value: '7'
  },
  {
    label: 'GMT (+08:00) Шанхай',
    value: '8'
  },
  {
    label: 'GMT (+09:00) Токио',
    value: '9'
  },
  {
    label: 'GMT (+10:00) Сидней',
    value: '10'
  },
  {
    label: 'GMT (+11:00) Магадан',
    value: '11'
  }
]

export const DAYS = [
  { value: '01', label: 1 },
  { value: '02', label: 2 },
  { value: '03', label: 3 },
  { value: '04', label: 4 },
  { value: '05', label: 5 },
  { value: '06', label: 6 },
  { value: '07', label: 7 },
  { value: '08', label: 8 },
  { value: '09', label: 9 },
  { value: '10', label: 10 },
  { value: '11', label: 11 },
  { value: '12', label: 12 },
  { value: '13', label: 13 },
  { value: '14', label: 14 },
  { value: '15', label: 15 },
  { value: '16', label: 16 },
  { value: '17', label: 17 },
  { value: '18', label: 18 },
  { value: '19', label: 19 },
  { value: '20', label: 20 },
  { value: '21', label: 21 },
  { value: '22', label: 22 },
  { value: '23', label: 23 },
  { value: '24', label: 24 },
  { value: '25', label: 25 },
  { value: '26', label: 26 },
  { value: '27', label: 27 },
  { value: '28', label: 28 },
  { value: '29', label: 29 },
  { value: '30', label: 30 },
  { value: '31', label: 31 }
]

export const MONTHS = [
  { value: '01', label: 'Январь' },
  { value: '02', label: 'Февраль' },
  { value: '03', label: 'Март' },
  { value: '04', label: 'Апрель' },
  { value: '05', label: 'Май' },
  { value: '06', label: 'Июнь' },
  { value: '07', label: 'Июль' },
  { value: '08', label: 'Август' },
  { value: '09', label: 'Сентябрь' },
  { value: '10', label: 'Октябрь' },
  { value: '11', label: 'Ноябрь' },
  { value: '12', label: 'Декабрь' }
]

export const getYears = () => {
  return Array.from({ length: 60 }).map((item, index) => {
    const year = moment()
      .subtract(10 + index, 'years')
      .get('year')
      .toString()
    return { label: year, value: year }
  })
}
