import PropTypes from 'prop-types'
import React, { memo } from 'react'
import './Icons.scss'

const Icon = memo((props) => {
  const {
    className,
    github,
    google,
    news,
    home,
    lessons,
    ethers,
    starFull,
    successBig,
    blocked,
    blockedBig,
    arrowDownSm,
    bell,
    rating,
    search,
    tick,
    bin,
    calendar,
    chat,
    ai_chat,

    width,
    height,
  } = props

  switch (true) {
  case google:
    return (
      <svg
        className={className}
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5336 4.45543C13.8657 4.45543 15.9751 5.65306 17.1841 7.30328L20.0496 4.41493C18.3358 2.18458 15.0373 0.416016 11.5336 0.416016C5.39887 0.416016 0.367188 5.38455 0.367188 11.5244C0.367188 17.6643 5.39887 22.6328 11.5336 22.6328C16.6192 22.6328 20.8973 19.2196 22.209 14.554C22.4914 13.5844 22.6328 12.5745 22.6328 11.5244V10.5146H12.5426V14.5532H17.8904C16.7805 16.9366 14.3386 18.5934 11.5336 18.5934C7.63894 18.5934 4.40325 15.4223 4.40325 11.5244C4.40325 7.62648 7.63894 4.45543 11.5336 4.45543Z"
        />
      </svg>
    )

  case github:
    return (
      <svg
        className={className}
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9632 18.5582C14.9632 20.5524 14.9461 22.1572 14.9461 22.6481C14.9461 22.9663 15.116 23.1644 15.5734 23.2878C15.6082 23.2972 15.6018 23.3652 15.5657 23.3652H6.93894C6.90291 23.3652 6.89648 23.297 6.93127 23.2876C7.39014 23.1638 7.56158 22.9646 7.56158 22.648C7.56158 22.2928 7.5482 21.1187 7.54151 19.8734C3.39816 20.7749 2.524 18.1147 2.524 18.1147C1.84624 16.3925 0.870561 15.9338 0.870561 15.9338C-0.481407 15.0091 0.97208 15.028 0.97208 15.028C2.46745 15.1332 3.25528 16.564 3.25528 16.564C4.58352 18.8423 6.73908 18.1841 7.58892 17.8032C7.72267 16.8397 8.10868 16.1821 8.53482 15.8098C5.96283 15.5171 3.28873 14.6794 2.22556 11.6372C2.2049 11.5776 2.18419 11.5167 2.16476 11.4553C2.10519 11.2698 2.05166 11.0757 2.00425 10.8737C1.99023 10.8135 1.97685 10.7527 1.96411 10.6906C1.82431 10.0306 1.74891 9.2854 1.74891 8.44408C1.74891 6.81743 2.33126 5.48758 3.28385 4.44371C3.12945 4.06837 2.61941 2.55239 3.42789 0.499287C3.42789 0.499287 4.67893 0.0990282 7.52446 2.02743C8.7129 1.69715 9.98703 1.53163 11.2527 1.52558C12.5182 1.53163 13.793 1.69709 14.9832 2.02743C17.8263 0.0990282 19.0749 0.499287 19.0749 0.499287C19.8859 2.55239 19.3758 4.06837 19.2208 4.44371C20.1758 5.48758 20.7539 6.81737 20.7539 8.44408C20.7539 9.28605 20.6785 10.0313 20.5393 10.6913C20.5266 10.7533 20.5125 10.8142 20.4986 10.8744C20.4512 11.0763 20.3983 11.2698 20.3387 11.4554C20.3193 11.5168 20.2992 11.5776 20.2779 11.6372C19.2141 14.685 16.5327 15.5111 13.9535 15.7982C14.4872 16.2606 14.9632 17.1676 14.9632 18.5582Z"
        />
      </svg>
    )
  case home:
    return (
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1621_1905)">
          <path
            d="M19.6643 9.10439L16.6332 6.07329V2.57187C16.6332 1.93876 16.1201 1.42568 15.4861 1.42568C14.8535 1.42568 14.3405 1.93876 14.3405 2.57187V3.78063L12.0836 1.52366C10.9678 0.408433 9.02771 0.410411 7.91442 1.52568L0.335548 9.10439C-0.111849 9.55268 -0.111849 10.278 0.335548 10.7256C0.783148 11.1738 1.50992 11.1738 1.95736 10.7256L9.5355 3.14672C9.78238 2.90114 10.2175 2.90114 10.4631 3.14599L18.0425 10.7256C18.2673 10.9497 18.5603 11.0612 18.8532 11.0612C19.1468 11.0612 19.4403 10.9496 19.6643 10.7256C20.1119 10.2781 20.1119 9.55272 19.6643 9.10439Z"
            fill="#004F71"
          />
          <path
            d="M10.3981 5.3224C10.1779 5.10233 9.82136 5.10233 9.60182 5.3224L2.93543 11.9868C2.83018 12.092 2.77051 12.2357 2.77051 12.3856V17.2463C2.77051 18.387 3.69534 19.3118 4.83592 19.3118H8.13646V14.2003H11.8627V19.3118H15.1633C16.3038 19.3118 17.2287 18.387 17.2287 17.2464V12.3856C17.2287 12.2357 17.1695 12.092 17.0637 11.9868L10.3981 5.3224Z"
            fill="#004F71"
          />
        </g>
        <defs>
          <clipPath id="clip0_1621_1905">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  case lessons:
    return (
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1621_1913)">
          <path
            d="M19.609 5.01386L10.195 1.69233C10.0688 1.6478 9.93121 1.6478 9.80508 1.69233L0.391016 5.01386C0.156954 5.09644 0.000312968 5.31753 4.68243e-07 5.56573C-0.000312032 5.81394 0.155821 6.03538 0.389688 6.11851L9.80375 9.46526C9.86723 9.48784 9.93363 9.49913 10 9.49913C10.0664 9.49913 10.1328 9.48784 10.1962 9.46526L19.6103 6.11851C19.8442 6.03538 20.0003 5.81394 20 5.56573C19.9997 5.31753 19.843 5.09644 19.609 5.01386V5.01386Z"
            fill="#004F71"
          />
          <path
            d="M18.5927 12.8349V7.724L17.4208 8.1406V12.8349C17.0678 13.0381 16.83 13.4188 16.83 13.8553C16.83 14.2918 17.0678 14.6725 17.4208 14.8756V17.7552C17.4208 18.0788 17.6831 18.3411 18.0067 18.3411C18.3303 18.3411 18.5927 18.0788 18.5927 17.7552V14.8757C18.9456 14.6726 19.1835 14.2918 19.1835 13.8553C19.1835 13.4188 18.9457 13.0381 18.5927 12.8349Z"
            fill="#004F71"
          />
          <path
            d="M9.99999 10.671C9.79886 10.671 9.60077 10.6368 9.4112 10.5694L4.1189 8.68799V11.2664C4.1189 11.8973 4.77698 12.4307 6.07487 12.852C7.20616 13.2192 8.60015 13.4214 9.99999 13.4214C11.3998 13.4214 12.7938 13.2192 13.9251 12.852C15.223 12.4307 15.8811 11.8973 15.8811 11.2664V8.68799L10.5888 10.5694C10.3992 10.6368 10.2011 10.671 9.99999 10.671V10.671Z"
            fill="#004F71"
          />
        </g>
        <defs>
          <clipPath id="clip0_1621_1913">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  case ethers:
    return (
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 7.53125C8.63871 7.53125 7.53125 8.63871 7.53125 9.99996C7.53125 11.1593 8.3348 12.1341 9.41406 12.3979V19.414C9.41406 19.7376 9.67641 20 10 20C10.3236 20 10.5859 19.7376 10.5859 19.414V12.3979C11.6652 12.1341 12.4688 11.1594 12.4688 9.99996C12.4688 8.63871 11.3613 7.53125 10 7.53125Z"
          fill="#004F71"
        />
        <path
          d="M10 3.76562C6.56234 3.76562 3.76562 6.56234 3.76562 9.99996C3.76562 11.7711 4.52297 13.4648 5.8434 14.6468C6.08449 14.8626 6.45488 14.8421 6.67078 14.6009C6.88656 14.3598 6.86609 13.9894 6.62496 13.7736C5.55258 12.8136 4.9375 11.4382 4.9375 9.99996C4.9375 7.20852 7.20855 4.9375 10 4.9375C12.7915 4.9375 15.0625 7.20852 15.0625 9.99996C15.0625 11.4382 14.4474 12.8137 13.3749 13.7736C13.1338 13.9894 13.1133 14.3598 13.3291 14.601C13.4448 14.7303 13.605 14.7961 13.7659 14.7961C13.905 14.7961 14.0447 14.7469 14.1565 14.6468C15.477 13.4648 16.2344 11.7711 16.2344 9.99996C16.2344 6.56234 13.4377 3.76562 10 3.76562Z"
          fill="#004F71"
        />
        <path
          d="M17.0711 2.92895C15.1823 1.0402 12.6711 0 10 0C7.32887 0 4.81766 1.0402 2.92887 2.92895C1.0402 4.81774 0 7.32891 0 9.99996C0 13.9768 2.35508 17.5754 5.99984 19.1677C6.29648 19.2973 6.6418 19.1619 6.77133 18.8654C6.90086 18.5688 6.76555 18.2234 6.46898 18.0939C3.25113 16.688 1.17188 13.5109 1.17188 9.99996C1.17188 5.13215 5.13215 1.17188 10 1.17188C14.8679 1.17188 18.8281 5.13215 18.8281 9.99996C18.8281 13.5109 16.7489 16.6879 13.5311 18.0939C13.2346 18.2235 13.0992 18.5689 13.2288 18.8654C13.325 19.0856 13.5402 19.2169 13.766 19.2169C13.8443 19.2169 13.9239 19.2011 14.0003 19.1677C17.645 17.5753 20 13.9767 20 9.99996C20 7.32891 18.9598 4.81774 17.0711 2.92895Z"
          fill="#004F71"
        />
      </svg>
    )
  case news:
    return (
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#004F71"
          d="M15.9,2.8V0H0v16.9C0,18.6,1.4,20,3.1,20h13.8c1.7,0,3.1-1.4,3.1-3.1V2.8H15.9z M13.1,15.2H2.8v-2.1h10.3L13.1,15.2
	L13.1,15.2z M13.1,11H2.8V9h10.3L13.1,11L13.1,11z M13.1,4.8v2.1H2.8V4.8V2.8h10.3L13.1,4.8L13.1,4.8z M17.9,16.9c0,0.6-0.5,1-1,1
	c-0.6,0-1-0.5-1-1V4.8h2.1V16.9z"
        />
      </svg>
    )
  case blocked:
    return (
      <svg
        className={className}
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2857 7.33333H14.2381V5.2381C14.2381 2.30476 11.9333 0 8.99997 0C6.06664 0 3.76188 2.30476 3.76188 5.2381V7.33333H2.71426C1.56188 7.33333 0.619019 8.27619 0.619019 9.42857V19.9048C0.619019 21.0571 1.56188 22 2.71426 22H15.2857C16.4381 22 17.3809 21.0571 17.3809 19.9048V9.42857C17.3809 8.27619 16.4381 7.33333 15.2857 7.33333ZM8.99997 16.7619C7.84759 16.7619 6.90473 15.819 6.90473 14.6667C6.90473 13.5143 7.84759 12.5714 8.99997 12.5714C10.1524 12.5714 11.0952 13.5143 11.0952 14.6667C11.0952 15.819 10.1524 16.7619 8.99997 16.7619ZM12.2476 7.33333H5.75235V5.2381C5.75235 3.45714 7.21902 1.99048 8.99997 1.99048C10.7809 1.99048 12.2476 3.45714 12.2476 5.2381V7.33333Z"
          fill="#E53434"
        />
      </svg>
    )
  case blockedBig:
    return (
      <svg
        width="43"
        height="55"
        viewBox="0 0 43 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.214 18.3333H34.595V13.0952C34.595 5.7619 28.8331 0 21.4997 0C14.1664 0 8.40451 5.7619 8.40451 13.0952V18.3333H5.78546C2.90451 18.3333 0.547363 20.6905 0.547363 23.5714V49.7619C0.547363 52.6429 2.90451 55 5.78546 55H37.214C40.095 55 42.4521 52.6429 42.4521 49.7619V23.5714C42.4521 20.6905 40.095 18.3333 37.214 18.3333ZM21.4997 41.9048C18.6188 41.9048 16.2616 39.5476 16.2616 36.6667C16.2616 33.7857 18.6188 31.4286 21.4997 31.4286C24.3807 31.4286 26.7378 33.7857 26.7378 36.6667C26.7378 39.5476 24.3807 41.9048 21.4997 41.9048ZM29.6188 18.3333H13.3807V13.0952C13.3807 8.64286 17.0474 4.97619 21.4997 4.97619C25.9521 4.97619 29.6188 8.64286 29.6188 13.0952V18.3333Z"
          fill="#E53434"
        />
      </svg>
    )
  case starFull:
    return (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.52447 0.463525C8.67415 0.00287008 9.32585 0.00287008 9.47553 0.463525L11.1329 5.56434C11.1998 5.77035 11.3918 5.90983 11.6084 5.90983H16.9717C17.4561 5.90983 17.6575 6.52964 17.2656 6.81434L12.9266 9.96681C12.7514 10.0941 12.678 10.3198 12.745 10.5258L14.4023 15.6266C14.552 16.0873 14.0248 16.4704 13.6329 16.1857L9.29389 13.0332C9.11865 12.9059 8.88135 12.9059 8.70611 13.0332L4.3671 16.1857C3.97524 16.4704 3.448 16.0873 3.59768 15.6266L5.25503 10.5258C5.32197 10.3198 5.24864 10.0941 5.07339 9.96681L0.734384 6.81434C0.342527 6.52964 0.543915 5.90983 1.02828 5.90983H6.39159C6.6082 5.90983 6.80018 5.77035 6.86712 5.56434L8.52447 0.463525Z"
          fill="#009EE2"
        />
      </svg>
    )
  case arrowDownSm:
    return (
      <svg
        className="pointer-events_none"
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.70752 1.56097L9.0002 8.39024L17.2929 1.56097"
          stroke="#009EE2"
          strokeWidth="2"
        />
      </svg>
    )
  case bell:
    return (
      <svg
        className="pointer-events_none"
        width="27"
        height="32"
        viewBox="0 0 27 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1581 0.87735C13.9191 0.87735 14.5359 1.49421 14.5359 2.25513V4.1524C19.0211 4.83309 22.4581 8.70553 22.4581 13.3807V19.3393L24.8772 20.7468C25.9538 21.3731 26.4763 22.6437 26.152 23.8462C25.8276 25.0487 24.737 25.8841 23.4915 25.8841H2.75582C1.51034 25.8841 0.419701 25.0487 0.0953377 23.8462C-0.229026 22.6437 0.293536 21.3731 1.37006 20.7468L3.78916 19.3393V13.3807C3.78916 8.6816 7.26151 4.79346 11.7803 4.14219V2.25513C11.7803 1.49421 12.3972 0.87735 13.1581 0.87735ZM6.52136 20.373C6.53677 20.2897 6.54473 20.2044 6.54473 20.118V13.3807C6.54473 9.74731 9.49021 6.80183 13.1236 6.80183C16.7571 6.80183 19.7026 9.74731 19.7026 13.3807V20.118C19.7026 20.2044 19.7105 20.2897 19.7259 20.373C19.7995 20.7706 20.0427 21.122 20.3994 21.3295L23.4915 23.1286H2.75582L5.84793 21.3295C6.20462 21.122 6.44782 20.7706 6.52136 20.373ZM16.1203 28.3642C16.1203 30.0002 14.7941 31.3264 13.1581 31.3264C11.5221 31.3264 10.1959 30.0002 10.1959 28.3642H16.1203Z"
          fill="#004F71"
        />
      </svg>
    )
  case successBig:
    return (
      <svg
        width="55"
        height="43"
        viewBox="0 0 55 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.9832 8.08046L20.806 42.6597L0.0378418 21.8743L7.81922 14.0994L20.7636 27.0544L47.1596 0.3479L54.9832 8.08046Z"
          fill="#34E590"
        />
      </svg>
    )
  case rating:
    return (
      <svg
        className="123"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="15" width="3" height="3" fill="#004F71" />
        <rect x="8" y="12" width="3" height="6" fill="#004F71" />
        <rect x="14" y="11" width="3" height="7" fill="#004F71" />
        <path d="M16 5 L20 7 L16 9 Z" fill="#004F71" />
        <rect x="6" y="6" width="11" height="2" fill="#004F71" />
        <defs>
          <clipPath id="clip0_1621_1913">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )

  case search:
    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M11 11L9 9M10 5.5C10 7.98528 7.98528 10 5.5 10C3.01472 10 1 7.98528 1 5.5C1 3.01472 3.01472 1 5.5 1C7.98528 1 10 3.01472 10 5.5Z"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  case tick:
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="8"
        viewBox="0 0 10 8"
        className="tick-icon"
      >
        <rect width="10" height="8" fill="#292929" />
        <g id="Frame 55">
          <rect width="1918" height="2893" transform="translate(-396 -857)" />
          <g id="Frame 56">
            <rect x="-85.5" y="-292.5" width="640" height="519" rx="19.5" />
            <g id="Frame 65">
              <path
                id="Rectangle 21"
                d="M8.96274 0.825046L3.92349 5.8643L0.999811 2.94062"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
      </svg>
    )

  case bin:
    return (
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="red"
          d="M13.9907,1.31133017e-07 C14.8816,1.31133017e-07 15.3277,1.07714 14.6978,1.70711 L13.8556,2.54922 C14.421,3.15654 14.8904,3.85028 15.2448,4.60695 C15.8028,5.79836 16.0583,7.109 15.9888,8.42277 C15.9193,9.73654 15.5268,11.0129 14.8462,12.1388 C14.1656,13.2646 13.2178,14.2053 12.0868,14.8773 C10.9558,15.5494 9.67655,15.9322 8.3623,15.9918 C7.04804,16.0514 5.73937,15.7859 4.55221,15.2189 C3.36505,14.652 2.33604,13.8009 1.55634,12.7413 C0.776635,11.6816 0.270299,10.446 0.0821822,9.14392 C0.00321229,8.59731 0.382309,8.09018 0.928918,8.01121 C1.47553,7.93224 1.98266,8.31133 2.06163,8.85794 C2.20272,9.83451 2.58247,10.7612 3.16725,11.556 C3.75203,12.3507 4.52378,12.989 5.41415,13.4142 C6.30452,13.8394 7.28602,14.0385 8.27172,13.9939 C9.25741,13.9492 10.2169,13.6621 11.0651,13.158 C11.9133,12.6539 12.6242,11.9485 13.1346,11.1041 C13.6451,10.2597 13.9395,9.30241 13.9916,8.31708 C14.0437,7.33175 13.8521,6.34877 13.4336,5.45521 C13.178,4.90949 12.8426,4.40741 12.4402,3.96464 L11.7071,4.69779 C11.0771,5.32776 9.99996,4.88159 9.99996,3.99069 L9.99996,1.31133017e-07 L13.9907,1.31133017e-07 Z M1.499979,4 C2.05226,4 2.499979,4.44772 2.499979,5 C2.499979,5.55229 2.05226,6 1.499979,6 C0.947694,6 0.499979,5.55228 0.499979,5 C0.499979,4.44772 0.947694,4 1.499979,4 Z M3.74998,1.25 C4.30226,1.25 4.74998,1.69772 4.74998,2.25 C4.74998,2.80229 4.30226,3.25 3.74998,3.25 C3.19769,3.25 2.74998,2.80228 2.74998,2.25 C2.74998,1.69772 3.19769,1.25 3.74998,1.25 Z M6.99998,0 C7.55226,0 7.99998,0.447716 7.99998,1 C7.99998,1.55229 7.55226,2 6.99998,2 C6.44769,2 5.99998,1.55229 5.99998,1 C5.99998,0.447716 6.44769,0 6.99998,0 Z"
        />
      </svg>
    )

  case calendar:
    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 9H21M7 3V5M17 3V5M6 12H8M11 12H13M16 12H18M6 15H8M11 15H13M16 15H18M6 18H8M11 18H13M16 18H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" strokeWidth="1" strokeLinecap="round" />
      </svg>
    )

  case chat:
    return (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22Z" strokeWidth="1.5" />
        <path opacity="0.5" d="M8 12H8.009M11.991 12H12M15.991 12H16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )

  case ai_chat:
    return (
      <svg className='chat-ai-icon'
        width={32} height={32} version="1.1" viewBox="0 0 32 32" ><title /><desc /><defs /><g fill="none" fillRule="evenodd" id="Page-1" strokeWidth="1"><g fill="#929292" id="icon-39-stars"><path d="M13.76,15.64 L16,15 L13.76,14.36 L13.76,14.36 L12.5,11 L11.24,14.36 L9,15 L11.24,15.64 L12.5,19 L13.76,15.64 L13.76,15.64 Z M23.7142857,9.23809524 L22.5,6 L21.2857143,9.23809524 L19,10 L21.2857143,10.7619048 L22.5,14 L23.7142857,10.7619048 L26,10 L23.7142857,9.23809524 L23.7142857,9.23809524 L23.7142857,9.23809524 Z M21.7142857,21.2380952 L20.5,18 L19.2857143,21.2380952 L17,22 L19.2857143,22.7619048 L20.5,26 L21.7142857,22.7619048 L24,22 L21.7142857,21.2380952 L21.7142857,21.2380952 L21.7142857,21.2380952 Z M14.4594595,13.4864865 L12.5,8 L10.5405405,13.4864865 L6,15 L10.5405405,16.5135135 L12.5,22 L14.4594595,16.5135135 L19,15 L14.4594595,13.4864865 L14.4594595,13.4864865 L14.4594595,13.4864865 Z" id="stars" /></g></g></svg>
    )

  default:
    return null
  }
})

Icon.propTypes = {
  className: PropTypes.string,
  google: PropTypes.bool,
  github: PropTypes.bool,
  home: PropTypes.bool,
  news: PropTypes.bool,
  lessons: PropTypes.bool,
  rating: PropTypes.bool,
  ethers: PropTypes.bool,
  starFull: PropTypes.bool,
  blocked: PropTypes.bool,
  blockedBig: PropTypes.bool,
  successBig: PropTypes.bool,
  bell: PropTypes.bool,
  arrowDownSm: PropTypes.bool,
  search: PropTypes.bool,
  bin: PropTypes.bool,
  calendar: PropTypes.bool,
  chat: PropTypes.bool,

  width: PropTypes.number,
  height: PropTypes.number,
}

Icon.defaultProps = {
  className: '',
}

export default Icon
