import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useApi } from 'services/api/index'
import { authKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useToken = () => {
  return useQuery(authKeys.token(), () => {
    return localStorage.getItem('token')
  })
}

export const useAuthWithGoogle = () => {
  const { auth, setToken } = useApi()
  const queryClient = useQueryClient()

  return useMutation(auth.authWithGoogle, {
    retry: false,
    onSuccess: (data) => {
      const token = data.data.token
      setToken(token)
      queryClient.setQueryData(authKeys.token(), token)
    },
    onError: (error) => logger(error),
  })
}

export const useAuthWithGithub = () => {
  const { auth, setToken } = useApi()
  const queryClient = useQueryClient()

  return useMutation(auth.authWithGithub, {
    retry: false,
    onSuccess: (data) => {
      const token = data.data.token
      setToken(token)
      queryClient.setQueryData(authKeys.token(), token)
    },
    onError: (error) => logger(error),
  })
}
