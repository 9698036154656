import React from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'

import moment from 'services/moment'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import { useGetNewsDetails } from 'hooks/api/useNews'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { articleMarkdown } from 'config/markdownConfig'

function NewsDetails({ isAuth }) {
  const { slug } = useParams()

  const { data } = useGetNewsDetails(slug)
  const { meta, title, content, created_at: createdAt } = data ?? {}
  const date = moment.toDefaultFormat(createdAt)

  return (
    <>
      {data && (
        <section className="article-details">
          <div className="article-details__container container">
            <div className="article-details__title title">
              <h1>{meta?.title || title}</h1>
            </div>
            <p className="article-details__short-desc">{meta?.description}</p>
            <time className="article-details__public-date public-date">{date}</time>
            <div className="article-desc">
              <ReactMarkdown
                components={articleMarkdown}
                remarkPlugins={[remarkToc]}
                rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                children={content}
                linkTarget={(href, children, title) => {
                  if (href[0] !== '#') return '_blank'
                }}
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default withDashboardLayout()(NewsDetails)
