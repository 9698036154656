export const landingKeys = {
  landing: () => ['landing']
}

export const dashboardKeys = {
  dashboard: () => ['dashboard']
}

export const lessonsKeys = {
  lessons: (params) => ['lessons', params],
  lessonsById: (id) => ['lessonsById', id],
  lesson: (slug) => ['lessons', slug]
}

export const webinarsKeys = {
  webinars: () => ['webinars']
}

export const authKeys = {
  token: () => ['token']
}

export const accountKeys = {
  accountSettings: () => ['accountSettings'],
  notification: () => ['notification'],
  affiliatePrograme: () => ['affiliatePrograme'],
  affiliateProgrameAbout: () => ['affiliateProgrameAbout']
}

export const tariffKeys = {
  tariffs: () => ['tariffs']
}

export const newsKeys = {
  news: (params) => ['news', params],
  newDetails: (slug) => ['newsDetails', slug]
}

export const streamsKeys = {
  streams: () => ['streams'],
  streamDetails: (slug) => ['streamDetails', slug]
}

export const otherKeys = {
  banners: () => ['banners'],
  coupon: (code) => ['coupon', code],
  faq: () => ['faq'],
  termsOfService: () => ['termsOfService'],
  refundPolicy: () => ['refundPolicy'],
  privacyPolicy: () => ['privacyPolicy'],
  courseProgram: () => ['courseProgram'],
  paymentSecurityPolicy: () => ['paymentSecurityPolicy'],
  getCurrencies: () => ['getCurrencies'],
}
