import React, { memo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const BurgerButton = memo(({ onClick, isActive }) => (
  <button type="button" className={classNames('burger', { active: isActive })} onClick={onClick}>
    <div className="burger__wrap">
      <span className="burger__top-line line"></span>
      <span className="burger__bottom-line line"></span>
    </div>
  </button>
))

BurgerButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

export default BurgerButton
