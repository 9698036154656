import React, { useContext, useEffect, useMemo } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { ModalContext } from 'HOCs/withModalProvider'
import { SettingsContext } from 'HOCs/withSettingsProvider'
import LessonPreview from 'components/LessonPreview/LessonPreview'
import LinkTelegramNotification from 'components/LinkTelegramNotification/LinkTelegramNotification'
import { NotAuthContent } from 'components/NoContent'
import { MODAL_TYPES } from 'config/constants/constants'
import ROUTES from 'config/constants/routes'
import { useGetDashboardData } from 'hooks/api/useDashboard'
import { useBuyLesson, useGetLessons } from 'hooks/api/useLessons'
import { useTranslation } from 'react-i18next'

function Lessons({ isAuth }) {
  const { type } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data: dashboardData } = useGetDashboardData()

  const { accountData } = useContext(SettingsContext)
  const hasTelegram = Boolean(accountData?.telegram_id)

  const { data } = useGetLessons()
  const {
    data: buyLessonResponse,
    mutate: buyLesson,
    isSuccess,
  } = useBuyLesson()


  const lessons = useMemo(() => {
    if (!data) return null
    if (!type) {
      return data[0] // Return the first category if no type is specified
    }
    return data.find((item) => item.type === type)
  }, [data, type])

  const lessonsBlocks = useMemo(() => {
    if (!lessons) return null

    const blocks = []
    lessons.lessons.forEach((element) => {
      const currentIndex = element.name.trim().match(/^\d*/g)
      if (blocks.find((block) => block.index === currentIndex[0])) return
      blocks.push({
        index: currentIndex[0],
        data: lessons.lessons.filter(
          (item) => item.name.trim().match(/^\d*/g)[0] == currentIndex[0]
        ),
      })
    })

    return blocks.sort((a, b) => a.index - b.index)
  }, [lessons])

  const {
    onOpen: openModal,
    setSubmitCallback,
    setData,
  } = useContext(ModalContext)

  const handleBuy = (event, item) => {
    openModal(event, MODAL_TYPES.buyLesson)
    setData(item)
    setSubmitCallback(() => buyLesson)
  }

  useEffect(() => {
    if (isSuccess && buyLessonResponse)
      navigate(`${ROUTES.lessonDetail}/${buyLessonResponse.data.slug}`)
  }, [isSuccess])

  const lastViewedLessonsId = dashboardData?.lastViewedLessonsIds[lessons?.type]

  return isAuth ? (
    <section className="section-lessons">
      {data && (
        <div className="section-lessons__container container">
          {!hasTelegram && <LinkTelegramNotification />}

          <div className="section-lessons__tabs">
            {data.map((item) => (
              <NavLink
                key={item.id}
                to={`${ROUTES.lessons}/${item.type}`}
                className={({ isActive }) =>
                  isActive || (!type && item.type === 'basic') ? 'active' : ''
                }
              >
                {t(item.name)}
              </NavLink>
            ))}
          </div>

          {lessonsBlocks?.map((block) => {
            return (
              <div className="list-articles" key={block.index}>
                {block.data.map((lesson) => {
                  return (
                    <LessonPreview
                      key={lesson.id}
                      accessStatus={lesson.status}
                      expiredAt={lesson.expiredAt}
                      id={lesson.id}
                      slug={lesson.slug}
                      previousLessonSlug={lesson.previousLessonSlug}
                      name={lesson.name}
                      image={lesson.preview_image}
                      description={lesson.preview_description || lesson.description}
                      buyLesson={(event) => handleBuy(event, lesson)}
                      lastViewedLessonsId={lastViewedLessonsId}
                      isWatched={lesson.isWatched}
                      createdAt={lesson.created_at}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      )}
    </section>
  ) : (
    <NotAuthContent />
  )
}

export default withDashboardLayout()(Lessons)
