import React, {memo} from 'react'
import {Field, Formik} from 'formik'
import classNames from 'classnames'

import Input from 'components/Input/Input'
import Select from 'components/Select/Select'

import {LANGUAGES, PROFILE_FORM_FIELD} from 'config/constants/constants'
import {DAYS, getYears, MONTHS, TIMEZONES} from 'config/constants/dateTimeConstants'
import {parseBirthDate, stringifyBirthDate} from 'utils/handleBirthDate'
import {normalizeUserName} from 'utils/normalizeUserName'
import {Trans, useTranslation} from 'react-i18next'

const ProfileForm = memo(({user, updateProfile}) => {
  const {birthDay, birthMonth, birthYear} = parseBirthDate(user?.birthday)
  const {t, i18n} = useTranslation()

  const {
    firstName,
    lastName
  } = normalizeUserName(user?.first_name, user?.last_name, user?.additional_email || user?.email)

  return (
    <Formik
      initialValues={{
        [PROFILE_FORM_FIELD.firstName.name]: firstName || '',
        [PROFILE_FORM_FIELD.lastName.name]: lastName || '',
        [PROFILE_FORM_FIELD.email.name]: user.additional_email || user.email || '',
        [PROFILE_FORM_FIELD.timezone.name]: user.timezone || '',

        [PROFILE_FORM_FIELD.birthDay.name]: birthDay || '',
        [PROFILE_FORM_FIELD.birthMonth.name]: birthMonth || '',
        [PROFILE_FORM_FIELD.birthYear.name]: birthYear || '',
        [PROFILE_FORM_FIELD.language.name]: user.language || 'uk-UA',
      }}
      validate={(values) => {
        const errors = {}
        if (values.email.length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = t('error-email')
        }
        return errors
      }}
      onSubmit={(values) => {
        const data = {
          first_name: values.firstName,
          last_name: values.lastName,
          additional_email: values.email,
          timezone: values.timezone,
          birthday: stringifyBirthDate(values.birthDay, values.birthMonth, values.birthYear),
          language: values.language
        }

        for (const item in data) {
          if (data[item] === '' || data[item] === null || data[item] === undefined) delete data[item]
        }

        updateProfile(data)
        if (user.language !== i18n.language) {
          i18n.changeLanguage(user.language)
        }
      }}
    >
      {({errors, ...props}) => {
        const isDisabledSubmit =
                    props.values[PROFILE_FORM_FIELD.firstName.name] === '' &&
                    props.values[PROFILE_FORM_FIELD.lastName.name] === '' &&
                    props.values[PROFILE_FORM_FIELD.email.name] === ''

        return (
          <form className="data" onSubmit={props.handleSubmit}>
            <div className="data__wrap">
              <Input
                id={PROFILE_FORM_FIELD.firstName.id}
                title={t('profile-first-name')}
                name={PROFILE_FORM_FIELD.firstName.name}
                type="text"
                value={props.values[PROFILE_FORM_FIELD.firstName.name]}
                onChange={props.handleChange}
              />
              <Input
                id={PROFILE_FORM_FIELD.lastName.id}
                title={t('profile-last-name')}
                name={PROFILE_FORM_FIELD.lastName.name}
                type="text"
                value={props.values[PROFILE_FORM_FIELD.lastName.name]}
                onChange={props.handleChange}
              />
            </div>

            <div className="data__wrap">
              <div className="data__item">
                <Input
                  id={PROFILE_FORM_FIELD.email.id}
                  name={PROFILE_FORM_FIELD.email.name}
                  type="text"
                  title="E-mail"
                  placeholder="E-mail"
                  value={props.values[PROFILE_FORM_FIELD.email.name]}
                  onChange={props.handleChange}
                  className="email"
                  hasError={Boolean(errors.email)}
                  errorMessage={errors.email}
                />
              </div>

              <div className="data__item">
                <p>
                  <Trans i18nKey="profile-timezone"/>
                </p>
                <div className="timezone">
                  <Field name={PROFILE_FORM_FIELD.timezone.name}>
                    {({field, form}) => (
                      <Select
                        list={TIMEZONES}
                        defaultItem={TIMEZONES.find((timezone) => timezone.value === user.timezone)}
                        onSelect={(value) => form.setFieldValue(PROFILE_FORM_FIELD.timezone.name, value)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className="date-birth">
              <p>
                <Trans i18nKey="profile-birthday"/>
              </p>
              <div className="wrap">
                <div className="date-birth__wrap">
                  <div className="date-birth__number date-birth__item">
                    <Field name={PROFILE_FORM_FIELD.birthDay.name}>
                      {({field, form}) => (
                        <Select
                          list={DAYS}
                          defaultItem={DAYS.find((day) => day.value === birthDay)}
                          onSelect={(value) => form.setFieldValue(PROFILE_FORM_FIELD.birthDay.name, value)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="date-birth__mounth date-birth__item">
                    <Field name={PROFILE_FORM_FIELD.birthMonth.name}>
                      {({field, form}) => (
                        <Select
                          list={MONTHS}
                          defaultItem={MONTHS.find((month) => month.value === birthMonth)}
                          onSelect={(value) => form.setFieldValue(PROFILE_FORM_FIELD.birthMonth.name, value)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="date-birth__year date-birth__item">
                    <Field name={PROFILE_FORM_FIELD.birthYear.name}>
                      {({field, form}) => {
                        const years = getYears()
                        return (
                          <Select
                            list={years}
                            defaultItem={years.find((year) => year.value === birthYear)}
                            onSelect={(value) => form.setFieldValue(PROFILE_FORM_FIELD.birthYear.name, value)}
                          />
                        )
                      }}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div className="data__wrap">
              <div className="data__item">
                <p>
                  <Trans i18nKey="profile-language"/>
                </p>
                <div className="language-select">
                  <Field name={PROFILE_FORM_FIELD.language.name}>
                    {({field, form}) => (
                      <Select
                        list={LANGUAGES}
                        defaultItem={LANGUAGES.find((lang) => lang.value === user?.language)}
                        onSelect={(value) => form.setFieldValue(PROFILE_FORM_FIELD.language.name, value)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>

            <div className="data__btn-wrap">
              <button
                type="submit"
                disabled={isDisabledSubmit}
                className={classNames('btn btn_pattensBlue', {disabled: isDisabledSubmit})}
              >
                <Trans i18nKey="profile-save"/>
              </button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
})

export default ProfileForm
