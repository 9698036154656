import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

const RoadmapSection = memo(({ data }) => {
  const { t } = useTranslation()
  return (
    <section className="after-course section-padding">
      <div className="container">
        <div className="after-course__title title">
          <h2>{t(data.title)}</h2>
        </div>
        <div className="steps-wrap">
          <div className="steps-t">
            {data.items.map((item) => (
              <p key={item.text} dangerouslySetInnerHTML={{ __html: t(item.text) }} />
            ))}
          </div>
          <div className="steps-b">
            <div className="step">
              <span className="step__round"></span>
            </div>
            <span className="step__line"></span>
            <div className="step">
              <span className="step__round"></span>
            </div>
            <span className="step__line"></span>
            <div className="step">
              <span className="step__round"></span>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
})

export default RoadmapSection
