import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import defaultAvatar from 'assets/images/other/small-bot.png'
import BurgerButton from 'components/Buttons/BurgerButton'
import Icon from 'components/Icons/Icon'
import NotificationMenu from 'components/NotificationMenu/NotificationMenu'
import ROUTES from 'config/constants/routes'
import { useReadNotification } from 'hooks/api/useAccount'
import useOutsideClick from 'hooks/useOutsideClick'
import { Trans } from 'react-i18next'
import { useApi } from 'services/api/index'
import { normalizeUserName } from 'utils/normalizeUserName'
import TariffLink from '../TariffItem/TariffLink'
import './HeaderAuth.scss'
import Toggle from './Toggle'

import { SettingsContext } from 'HOCs/withSettingsProvider'
import { ModalContext } from '../../HOCs/withModalProvider'

const HeaderAuth = memo((props) => {
  const { firstName, lastName, avatar, email, currentTariff, notificationData, timezone } = props

  const navigate = useNavigate()

  const { mutate: readNotification, isSuccess } = useReadNotification()

  const { fullName } = normalizeUserName(firstName, lastName, email)

  const image = avatar ? `${process.env.REACT_APP_API_URL}${avatar}` : defaultAvatar

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const { logout } = useApi()

  const settingRef = useRef()
  useOutsideClick(settingRef, () => {
    if (isSettingsOpen) setIsSettingsOpen(false)
  })

  const onReadNotification = (id) => readNotification({ id })
  const { theme, onThemeChange, onCursorChange, cursor } = useContext(SettingsContext)

  useEffect(() => {
    if (isSuccess) {
      navigate(`${ROUTES.notification}`)
    }
  }, [isSuccess, navigate])

  return (
    <header className="header-authorized">
      <div className="container header-authorized__container">
        <TariffLink currentTariff={currentTariff} setIsMenuOpen={setIsMenuOpen} />
        <nav className="user-nav">
          <div className="left-wrap">
            <button
              type="button"
              className={notificationData?.unreadCount > 0 ? 'notification flashing' : 'notification'}
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
            >
              <Icon className="bellIcon" bell />
              {notificationData?.unreadCount > 0 && (
                <span className="indicator" />
              )}
            </button>
          </div>
          <Toggle toggled={theme === 'dark'} onClick={onThemeChange} />

          <div className="user">
            <Link to={ROUTES.settings} className="user__name">
              {fullName}
            </Link>
            <Link to={ROUTES.settings} className="user__img-wrap">
              <img src={image} width="61" height="51" alt="" />
            </Link>
            <button type="button" className="user__btn-arrow btn-arrow"
              onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
              <Icon arrowDownSm />
            </button>
          </div>
          <BurgerButton isActive={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)} />
        </nav>
        <ul className={classNames('burger-menu', { 'is-open': isMenuOpen })}>
          <li>
            <div className="user">
              <Link to={ROUTES.settings} className="user__img-wrap">
                <img src={image} width="61" height="51" alt="" />
              </Link>
              <div>
                <Link to={ROUTES.settings} className="user__name">
                  {fullName}
                </Link>
                <TariffLink currentTariff={currentTariff} setIsMenuOpen={setIsMenuOpen} />
              </div>
            </div>
          </li>
          <li>
            <NavLink to={ROUTES.settings} className="burger-menu__link">
              <Trans i18nKey="header-settings" />
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.affiliateProgram} className="burger-menu__link">
              <Trans i18nKey="header-affiliate-program" />
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.balance} className="burger-menu__link">
              <Trans i18nKey="header-balance" />
            </NavLink>
          </li>
          <button type="button" className="burger-menu__link" onClick={logout}>
            <Trans i18nKey="header-logout" />
          </button>
        </ul>

        <NotificationMenu
          data={notificationData}
          isOpen={isNotificationsOpen}
          timezone={timezone}
          toggleOpen={() => setIsNotificationsOpen(!isNotificationsOpen)}
          onRead={onReadNotification}
        />

        <ul ref={settingRef} className={classNames('list-settings', { active: isSettingsOpen })}>
          <li>
            <NavLink to={ROUTES.settings}>
              <Trans i18nKey="header-settings" />
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.affiliateProgram}>
              <Trans i18nKey="header-affiliate-program" />
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.tariffs}>
              <Trans i18nKey="tariffs-header-button" />
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.balance}>
              <Trans i18nKey="header-balance" />
            </NavLink>
          </li>
          <li>
            <button type="button" className='button--red' onClick={logout}>
              <Trans i18nKey="header-logout" />
            </button>
          </li>
        </ul>
      </div>
    </header>
  )
})

HeaderAuth.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.string,
  email: PropTypes.string,
  notificationData: PropTypes.any,
  currentTariff: PropTypes.object
}

export default HeaderAuth
