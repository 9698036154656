export const stringifyBirthDate = (day, month, year) => {
  if (!day && !month && !year) return
  return `${day}.${month}.${year}`
}

export const parseBirthDate = (date) => {
  if (!date) return {}

  const dateArray = date.split('.')
  return {
    birthDay: dateArray[0],
    birthMonth: dateArray[1],
    birthYear: dateArray[2],
  }
}
