import {useQuery, useQueryClient, useMutation, useInfiniteQuery} from 'react-query'
import {useApi} from 'services/api/index'
import {accountKeys, newsKeys, otherKeys} from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetAccountSettings = (isDisabled) => {
  const {account} = useApi()

  return useQuery(accountKeys.accountSettings(), () => account.getAccountSettings(), {
    onError: (error) => logger(error),
    select: (res) => res.data.data,
    keepPreviousData: true,
    enabled: isDisabled ? false : true
  })
}

export const useUpdateProfile = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()

  return useMutation(account.updateProfile, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: () => {
      queryClient.invalidateQueries(accountKeys.accountSettings())
    }
  })
}

export const useUpdateSettings = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()

  return useMutation(account.updateSettings, {
    retry: false,
    onError: (error) => {
      logger(error)
      queryClient.invalidateQueries(accountKeys.accountSettings())
    }
  })
}

export const useUploadAvatar = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()

  return useMutation(account.uploadAvatar, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: () => queryClient.invalidateQueries(accountKeys.accountSettings())
  })
}

export const useAddTelegram = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()

  return useMutation(account.addTelegram, {
    retry: false,
    onError: (error) => logger(error),
    onSettled: () => {
      queryClient.invalidateQueries(accountKeys.accountSettings())
    }
  })
}

export const useRemoveTelegram = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()
  const queryKey = accountKeys.accountSettings()

  return useMutation(account.removeTelegram, {
    retry: false,
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey)

      const prevSettings = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (settings) => {
        settings.data.data.telegram_id = null
        return settings
      })

      return {prevSettings}
    },
    onError: (error) => {
      logger(error)
      queryClient.invalidateQueries(queryKey)
    }
  })
}

export const useLinkGithub = () => {
  const {account} = useApi()

  return useMutation(account.linkGithub, {
    retry: false,
    onError: (error) => logger(error)
  })
}

export const useGetPycharmCoupon = () => {
  const {account} = useApi()

  return useMutation(account.getPycharmCoupon, {
    retry: false,
    onError: (error) => logger(error),
    onSuccess: async (res) => {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  })
}

export const useUnlinkGithub = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()
  const queryKey = accountKeys.accountSettings()

  return useMutation(account.unlinkGithub, {
    retry: false,
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey)

      const prevSettings = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (settings) => {
        settings.data.data.github_id = null
        return settings
      })

      return {prevSettings}
    },

    onError: (error) => {
      logger(error)
      queryClient.invalidateQueries(queryKey)
    }
  })
}

export const useGetNotificationData = () => {
  const {account} = useApi()

  return useInfiniteQuery(accountKeys.notification(), account.getNotificationData, {
    enabled: localStorage.getItem('token') ? true : false,
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
    onError: (error) => logger(error),
    select: (res) => ({
      pageParams: res.pageParams,
      pages: res.pages.map((page) => ({...page.data}))
    }),
    getNextPageParam: (lastPage, allPages) => {
      const hasNextPage = lastPage.data.hasMorePages
      const nextPage = lastPage.data.page + 1
      return hasNextPage ? {page: nextPage, items: 4} : undefined
    }
  })
}

export const useReadNotification = () => {
  const {account} = useApi()
  const queryClient = useQueryClient()
  const queryKey = accountKeys.notification()

  return useMutation(account.readNotification, {
    retry: false,
    onMutate: async (data) => {
      await queryClient.cancelQueries(queryKey)

      const prevData = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) => {
        const id = data.id
        const newData = {...old}

        newData.pages.forEach((page) => {
          page.data.data.forEach((notification) => {
            if (notification.id == id) {
              notification.read = true
            }
          })
        })

        return newData
      })

      return {prevData}
    },
    onError: (error) => {
      logger(error)
      queryClient.invalidateQueries(queryKey)
    }
  })
}

export const useGetAffiliatePrograme = () => {
  const {account} = useApi()

  return useQuery(accountKeys.affiliatePrograme(), () => account.getAffiliatePrograme(), {
    onError: (error) => logger(error),
    select: (res) => res.data,
    keepPreviousData: true
  })
}

export const useGetAffiliateProgrameAbout = () => {
  const {account} = useApi()

  return useQuery(accountKeys.affiliateProgrameAbout(), () => account.getAffiliateProgrameAbout(), {
    onError: (error) => logger(error),
    select: (res) => res.data.data,
    keepPreviousData: true
  })
}

export const useTransferBonusMoney = (onSuccessTransfer, onFailureTransfer) => {
  const {account} = useApi()
  const queryClient = useQueryClient()
  const queryKey = accountKeys.affiliatePrograme()

  return useMutation(account.transferBonusMoney, {
    retry: false,
    onSuccess: () => onSuccessTransfer(),
    onError: (error) => {
      logger(error)
      onFailureTransfer()
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  })
}

export const useAddFunds = () => {
  const {account} = useApi()

  return useMutation(account.addFunds, {
    retry: false,
    onError: (error) => logger(error)
  })
}

export const useGetCurrencies = () => {
  const {account} = useApi()

  return useQuery(otherKeys.getCurrencies(), () => account.getCurrencies(), {
    onError: (error) => logger(error),
    select: (res) => res.data.currencies,
    keepPreviousData: true
  })
}