import classNames from 'classnames'
import Icon from 'components/Icons/Icon'
import { githubRedirectUrl, googleRedirectUrl } from 'config/authRedirectUrl'
import { MODAL_TYPES } from 'config/constants/constants'
import useOutsideClick from 'hooks/useOutsideClick'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import ROUTES from '../../config/constants/routes'
import { useGetLessons } from '../../hooks/api/useLessons'
import { useDebounce } from '../../hooks/useDebounce'
import './Modal.scss'
import SpinnerLoader from './SpinnerLoader'

const LessonCard = ({ slug, preview_description, preview_image, name }) => {
  const previewImage = useMemo(
    () => `${process.env.REACT_APP_API_URL}${preview_image}`,
    [preview_image]
  )

  return (
    <div className="modal-lesson-card">
      <div className="modal-lesson-card__content">
        <a
          href={`${ROUTES.lessonDetail}/${slug}`}
          className="modal-lesson-card__link"
        >
          <div className="modal-lesson-card__image">
            <img src={previewImage} alt={name} />
          </div>
        </a>
        <a
          href={`${ROUTES.lessonDetail}/${slug}`}
          className="modal-lesson-card__link"
        >
          <h3 className="modal-lesson-card__title">{name}</h3>
        </a>
      </div>
      <div className="modal-lesson-card__content">
        <ReactMarkdown
          children={preview_description?.slice(0, 400)}
          className="modal-lesson-card__description"
        />
      </div>
    </div>
  )
}

const Modal = memo(({ type, isOpen, onClose, state, data, onSubmit }) => {
  const couponeCode = data?.couponCode
  const utm_source = data?.utm_source
  const utm_medium = data?.utm_medium
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const googleUrl = googleRedirectUrl(
    data?.invitingCode,
    couponeCode,
    utm_source,
    utm_medium,
    currentLanguage
  )
  const githubUrl = githubRedirectUrl(
    data?.invitingCode,
    couponeCode,
    utm_source,
    utm_medium,
    currentLanguage
  )
  const ref = useRef()
  const [searchResults, setSearchResults] = useState([])

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [isSearching, setIsSearching] = useState(false)
  const { data: lessonsData } = useGetLessons()
  const searchInputRef = useRef(null)

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(false)
      handleSearch(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm])

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
    // Set isSearching to true only if the input is not empty
    if (event.target.value) {
      setIsSearching(true)
    } else {
      setIsSearching(false)
    }
  }

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [])

  function handleSearch(value) {
    if (!value) {
      return
    }
    const searchTerm = value?.toLowerCase()
    // Initialize an array to collect filtered lessons
    let filteredLessons = []

    // Iterate over each category and filter its lessons
    lessonsData?.forEach((category) => {
      const categoryFilteredLessons = category.lessons.filter(
        (lesson) =>
          lesson && (
            lesson.name.toLowerCase().includes(searchTerm) ||
            lesson.description.toLowerCase().includes(searchTerm)
          )
      )

      // Concatenate the filtered lessons from this category to the main array
      filteredLessons = filteredLessons.concat(categoryFilteredLessons)
    })

    setSearchResults(filteredLessons)
  }

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose()
      if (type === MODAL_TYPES.invoice && data?.onCloseCallback) {
        data.onCloseCallback()
      }
    }
  })




  switch (type) {
  case MODAL_TYPES.signin:
  case MODAL_TYPES.login:
    return (
      <div className={`modal log-in ${state}`}>
        <div ref={ref} className="modal__wrap log-in">
          <button className='modal__close' onClick={onClose} type='button'>
              X
          </button>
          <div className='wrapper__socials'>
            <div className="banner">
              <h2><Trans>login-banner-header</Trans></h2>
              <p><Trans>✅ Переглядай навчальні відео та вивчай теоретичний матеріал</Trans></p>
              <p><Trans>✅ Виконуй практичні завдання</Trans></p>
              <p><Trans >✅ Приєднуйтесь до нашого чату в Telegram для обговорення та підтримки</Trans></p>
              <div className="icon-banner">🎓</div>
            </div>
            <div className="title modal__title">
              <h2>{
                type === MODAL_TYPES.login
                  ? t('login-modal')
                  : t('registration-modal')
              }</h2>
            </div>
            <div className="modal__cnt">
              <a href={googleUrl} className={classNames(
                'icon-link',
              )}>
                <Icon google className="icon" />
                <span className="text">Google</span>
              </a>
              <a href={githubUrl} className={classNames(
                'icon-link',
              )}>
                <Icon github className="icon" />
                <span className="text">Github</span>
              </a>
            </div>
            <div className="registration-checkboxes">
              <label>
                <span>
                  <Trans i18nKey="agree-to-conditions" components={[
                    <a href="https://development.botfather.dev/blog/terms-of-service" key={0} target="_blank" rel="noopener noreferrer">terms of service</a>,
                    <a href="https://development.botfather.dev/blog/privacy-policy" key={1} target="_blank" rel="noopener noreferrer">privacy policy</a>
                  ]} />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div >
    )
  case MODAL_TYPES.signinToBuy:
    return (
      <div className={`modal log-in ${state}`}>
        <div ref={ref} className="modal__wrap">
          <div className="title modal__title">
            <h2>{t('registration-modal')}</h2>
          </div>
          <div className="modal__cnt">
            <a href={googleUrl}>
              <Icon google />
                google
            </a>
            <a href={githubUrl}>
              <Icon github />
                github
            </a>
          </div>
        </div>
      </div>
    )

  case MODAL_TYPES.ad:
    return (
      <div className={`modal order-bot ${state}`}>
        <div ref={ref} className="modal__wrap">
          <div className="title modal__title">
            <h2>{t('order-bot-modal')}</h2>
          </div>
          <div className="modal__cnt">
            <p>
              {t('order-bot-modal-text')}
              <a href="mailto:latand@botfather.dev">@latand</a>
            </p>
          </div>
        </div>
      </div>
    )
  case MODAL_TYPES.invoice: {
    const link = `<a href="${data.link}" target="_blank">${data.link}</a>`
    const message = t('invoice-modal-text', { link })
    return (
      <div className={`modal popup-pay ${state}`}>
        <div ref={ref} className="modal__wrap">
          <div className="title modal__title">
            <h2>{t('waiting-for-payment')}</h2>
          </div>
          <div className="modal__cnt">
            <p dangerouslySetInnerHTML={{ __html: message }} />
            <p>{t('after-payment-refresh-page')}</p>
          </div>
        </div>
      </div>
    )
  }
  case MODAL_TYPES.buyLesson: {
    const handleSubmit = () => {
      onSubmit({ id: data.id })
      onClose()
    }
    return (
      <div className={`modal buy-lesson ${state}`}>
        <div className="modal__wrap" ref={ref}>
          <div className="title modal__title">
            <h2>{data.name}</h2>
          </div>
          <div className="modal__cnt">
            <p>
              <Trans i18nKey="lesson-cost" />
              <strong>{data.price} $</strong>
            </p>
            <p>
              <Trans>
                  Щоб купити натисніть <strong>Купити</strong>. З вашого рахунку
                  буде знята необхідна сума.
              </Trans>
            </p>
          </div>
          <div className="btns-wrap">
            <button type="button" onClick={onClose}>
              {t('word-cancel')}
            </button>
            <button
              type="button"
              className="btn btn_pattensBlue"
              onClick={handleSubmit}
            >
              {t('word-buy')}
            </button>
          </div>
        </div>
      </div>
    )
  }
  // ...

  case MODAL_TYPES.search:
    return (
      <div className={`modal search ${state}`}>
        <div ref={ref} className="modal__wrap search">
          <div className="modal__cnt">
            <div className="title modal__title">
              <h2>{t('search-modal')}</h2>
            </div>
            <form
              className="search-form"
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              <input
                ref={searchInputRef}
                type="text"
                placeholder={t('search-placeholder')}
                onChange={handleChange}
              />
            </form>
          </div>
          <div className="search-results">
            {isSearching ? (
              <SpinnerLoader />
            ) : (
              searchResults.map((lesson) => (
                <LessonCard key={lesson.id} {...lesson} />
              ))
            )}
          </div>
        </div>
      </div>
    )

  case MODAL_TYPES.loader:
    return (
      <div className={`modal loader ${state}`}>
        <div ref={ref} className="modal__wrap">
          <div className="modal__cnt">
            <div className="title modal__title">
              <h2>{t('loader-modal')}</h2>
            </div>
            <SpinnerLoader />
          </div>
        </div>
      </div>
    )

  case MODAL_TYPES.no_subscription:
    return (
      <div className={`modal no-subscription ${state}`}>
        <div ref={ref} className="modal__wrap">
          <div className="modal__cnt">
            <div className="title modal__title">
              <h2>{t('no-subscription-modal')}</h2>
            </div>
            <a href={`https://botfather.dev${ROUTES.tariffs}`}
              className='btn__tariff'>
              {t('buy-subscription')}</a>
            <p>{t('no-subscription-modal-text')}</p>
          </div>
        </div>
      </div>
    )

  default:
    return null
  }
})

Modal.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  state: PropTypes.string,
  data: PropTypes.shape({
    invitingCode: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_source: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

export default Modal
