import React, { useContext } from 'react'
import classNames from 'classnames'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import { SettingsContext } from 'HOCs/withSettingsProvider'
import {
  useGetNotificationData,
  useReadNotification,
} from 'hooks/api/useAccount'
import moment from 'services/moment'

import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { Trans, useTranslation } from 'react-i18next'

function Notification() {
  const { accountData } = useContext(SettingsContext)
  const timezone = accountData?.timezone

  const { mutate: readNotification } = useReadNotification()
  const { data, fetchNextPage, hasNextPage } = useGetNotificationData()

  const pages = data?.pages
  const unreadNotificationsNumber = data?.pages[0]?.unreadCount
  const { t } = useTranslation()

  return (
    <section className="section-notification">
      <div className="section-notification__container container">
        {data && (
          <>
            <div className="section-notification__title">
              <h2>
                {unreadNotificationsNumber === 0
                  ? t('notification-empty')
                  : t('notification-unread', { unreadNotificationsNumber })}
              </h2>
            </div>

            {pages.map((page) => (
              <ul key={page.page}>
                {page.data.map((notification) => {
                  const date = moment
                    .dateWithTimezone(notification.created_at, timezone)
                    .calendar()

                  return (
                    <li
                      key={notification.id}
                      className={classNames('notification-item', {
                        unread: notification.read === false,
                      })}
                      onClick={() => readNotification({ id: notification.id })}
                    >
                      <div className="notification-item__left">
                        <div className="notification-item__text">
                          <ReactMarkdown
                            remarkPlugins={[remarkToc]}
                            rehypePlugins={[
                              [rehypeHighlight, rehypeHighlightConfig],
                            ]}
                          >
                            {notification.message}
                          </ReactMarkdown>
                        </div>
                        <div className="public-date">{date}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            ))}

            <button
              type="button"
              className={classNames('btn btn_border-pacificBlue load-more', {
                disabled: !hasNextPage,
              })}
              disabled={!hasNextPage}
              onClick={fetchNextPage}
            >
              <Trans i18nKey="notification-load-more" />
            </button>
          </>
        )}
      </div>
    </section>
  )
}

export default withDashboardLayout()(Notification)
