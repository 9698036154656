import React, { useContext } from 'react'

import { NotAuthContent } from 'components/NoContent'
import Preview, { previewTypes } from 'components/Preview/Preview'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import { SettingsContext } from 'HOCs/withSettingsProvider'
import { useGetStreams } from 'hooks/api/useStreams'

function Streams({ isAuth }) {
  const { data } = useGetStreams()
  const streams = data?.data

  const { accountData } = useContext(SettingsContext)
  const timezone = accountData?.timezone

  return isAuth ? (
    <section className="ethers">
      <div className="ethers__container container">
        <div className="list-articles">
          {streams &&
            streams.map((stream) => (
              <Preview
                key={stream.id}
                type={previewTypes.streams}
                title={stream.name}
                description={stream.preview_description}
                slug={stream.slug}
                startDate={stream.date_start}
                timezone={timezone}
                imgUrl={stream.preview_image}
              />
            ))}
        </div>
      </div>
    </section>
  ) : (
    <NotAuthContent />
  )
}

export default withDashboardLayout()(Streams)
