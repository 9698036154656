import React, { memo, useRef } from 'react'

import defaultAvatar from 'assets/images/other/avatar.png'
import { useUploadAvatar } from 'hooks/api/useAccount'
import {Trans, useTranslation} from 'react-i18next'

const AvatarForm = memo(({ avatarUrl }) => {
  const { mutate: uploadAvatar } = useUploadAvatar()
  const ref = useRef()

  const image = avatarUrl ? `${process.env.REACT_APP_API_URL}${avatarUrl}` : defaultAvatar

  const onFileUpload = (event) => {
    const formData = new FormData()
    formData.set('avatar', event.target.files[0])
    uploadAvatar(formData)
  }

  return (
    <div className="photo-setting">
      <div className="photo-setting__img-wrap">
        <img src={image} width="183" height="183" alt="User" />
      </div>
      <input ref={ref} type="file" name="file" className="hidden" onChange={onFileUpload} />
      <button type="button" className="btn btn_border-pacificBlue" onClick={() => ref.current.click()}>
        <Trans i18nKey="word-change-photo"/>
      </button>
    </div>
  )
})

export default AvatarForm
