import { useQuery, useMutation } from 'react-query'
import { useApi } from 'services/api/index'
import { otherKeys } from 'services/queryClient/queryKeys'
import logger from 'services/logger'

export const useGetBanners = () => {
  const { other } = useApi()

  return useQuery(otherKeys.banners(), () => other.getBanners(), {
    select: (res) => res.data.data,
    keepPreviousData: true,
    enabled: localStorage.getItem('token') ? true : false,
    onError: (error) => logger(error)
  })
}

export const useGetCouponData = (code) => {
  const { other } = useApi()

  return useQuery(otherKeys.coupon(code), () => other.getCouponData(code), {
    select: (res) => res.data.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}

export const useActivateCoupon = () => {
  const { other } = useApi()

  return useMutation(other.activateCoupon, {
    retry: false,
    onError: (error) => logger(error)
  })
}

export const useGetFAQData = () => {
  const { other } = useApi()

  return useQuery(otherKeys.faq(), () => other.getFAQData(), {
    select: (res) => res.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}

export const useGetTermsOfServiceData = () => {
  const { other } = useApi()

  return useQuery(otherKeys.termsOfService(), () => other.getTermsOfServiceData(), {
    select: (res) => res.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}

export const useGetRefundPolicyData = () => {
  const { other } = useApi()

  return useQuery(otherKeys.refundPolicy(), () => other.getRefundPolicyData(), {
    select: (res) => res.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}

export const useGetPrivacyPolicyData = () => {
  const { other } = useApi()

  return useQuery(otherKeys.privacyPolicy(), () => other.getPrivacyPolicyData(), {
    select: (res) => res.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}

export const useGetPaymentSecurityPolicyData = () => {
  const { other } = useApi()

  return useQuery(otherKeys.paymentSecurityPolicy(), () => other.getPaymentSecurityPolicyData(), {
    select: (res) => res.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}

export const useGetCourseProgramData = () => {
  const { other } = useApi()

  return useQuery(otherKeys.courseProgram(), () => other.getCourseProgram(), {
    select: (res) => res.data,
    keepPreviousData: true,
    onError: (error) => logger(error)
  })
}
