import Icon from 'components/Icons/Icon'
import ROUTES from 'config/constants/routes'
import React from 'react'
import { Trans } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

const Sidebar = ({ isAuth }) => {
  const location = useLocation()

  function getNavLinkClass(path) {
    // TODO fix active class
    const isActive = location.pathname === path
    // console.log(isActive, location.pathname, path);
    // return isActive ? 'sidebar-nav__item active' : 'sidebar-nav__item';
    return 'sidebar-nav__item'
  }

  return (
    <aside className="sidebar">
      <nav className="sidebar-nav">
        <ul className="sidebar-nav__list">

          <li className={getNavLinkClass(ROUTES.dashboard)}>
            <NavLink to={isAuth ? ROUTES.dashboard : ROUTES.main} end>
              <div className="sidebar-nav__item-icon">
                <Icon home className="pointer-events_none" />
              </div>
              <Trans i18nKey="sidebar-main" />
            </NavLink>
          </li>
          <li className={getNavLinkClass(ROUTES.profile)}>
            <NavLink to={ROUTES.lessons}>
              <div className="sidebar-nav__item-icon">
                <Icon lessons className="pointer-events_none" />
              </div>
              <Trans i18nKey="sidebar-lessons" />
            </NavLink>
          </li>

          <li className={getNavLinkClass(ROUTES.profile)}>
            <NavLink to={ROUTES.rating}>
              <div className="sidebar-nav__item-icon">
                <Icon rating className="pointer-events_none" />
              </div>
              <Trans i18nKey="sidebar-rating" />
            </NavLink>
          </li>
          <li className={getNavLinkClass(ROUTES.profile)}>
            <NavLink to={ROUTES.streams}>
              <div className="sidebar-nav__item-icon">
                <Icon ethers className="pointer-events_none" />
              </div>
              <Trans i18nKey="sidebar-ethers" />
            </NavLink>
          </li>
          <li className={getNavLinkClass(ROUTES.profile)}>
            <NavLink to={ROUTES.news}>
              <div className="sidebar-nav__item-icon">
                <Icon news className="pointer-events_none" />
              </div>
              <Trans i18nKey="sidebar-news" />
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  )
}

export default Sidebar
