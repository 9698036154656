import React, { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Navigation } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss/navigation'

import LessonPreview from 'components/LessonPreview/LessonPreview'
import { status } from 'config/constants/lessonStatus'
import ROUTES from 'config/constants/routes'
import useWindowDimensions from 'hooks/useWindowDimension'
import { Trans } from 'react-i18next'

const DashboardSlider = memo(({ data, lastViewedLessonsId, language }) => {
  const filteredList = useMemo(() => {
    return data.lessons.filter((item) => item.status === status.available || item.status === status.testingRequired)
  }, [data.lessons])

  const lastViewedLessonIndex = useMemo(() => {
    if (!lastViewedLessonsId) return 0

    const lastViewedLesson = filteredList.find((lesson) => lesson.id === lastViewedLessonsId)
    const index = filteredList.indexOf(lastViewedLesson)
    return index
  }, [filteredList, lastViewedLessonsId])

  const { width } = useWindowDimensions()

  return (
    <div className="dashboard-main__wrap">
      <div className="title">
        <h2>
          <Trans>
            {data.name}
          </Trans>{' '}<Trans i18nKey="word-lessons" /></h2>
        {width > 600 ? (
          <Link to={`${ROUTES.lessons}/${data.type}`} className="btn btn_border-darkCerulean">
            <Trans i18nKey="dashboard-go-to-lessons" />
          </Link>
        ) : (
          <Link to={`${ROUTES.lessons}/${data.type}`} className="btn-arrow btn-arrow_right" />
        )}
      </div>

      <div className="dashboard-main__slider">
        <Swiper
          className="swiper-lessons"
          spaceBetween={20}
          // slidesPerView={1}
          initialSlide={lastViewedLessonIndex}
          centeredSlides={false}
          loop={filteredList.length > 1}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          modules={[Navigation]}
          breakpoints={{
            600: {
              loop: filteredList.length > 2,
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: false,
              initialSlide: lastViewedLessonIndex
            },
            768: {
              loop: filteredList.length > 2,
              slidesPerView: 2,
              spaceBetween: 61,
              centeredSlides: false,
              initialSlide: lastViewedLessonIndex
            },
            1366: {
              loop: filteredList.length > 3,
              initialSlide: lastViewedLessonIndex - 1,
              slidesPerView: 3,
              spaceBetween: 61,
              centeredSlides: false
            }
          }}
        >
          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />

          {filteredList.map((lesson) => (
            <SwiperSlide key={lesson.id}>
              <LessonPreview
                id={lesson.id}
                slug={lesson.slug}
                accessStatus={lesson.status}
                name={lesson.name}
                image={lesson.preview_image}
                expiredAt={lesson.expiredAt}
                previousLessonSlug={lesson.previousLessonSlug}
                isDashboardPreview
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
})

export default DashboardSlider
