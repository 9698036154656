import React from 'react'
import classNames from 'classnames'
import { ReactComponent as ArrowIcon } from 'assets/images/rating/arrow.svg'

const SitesRatings = ({ styles, idx, setIdx, list }) => {
  const backward = classNames(styles.rating__site, styles.rating__site_backward)

  const forward = classNames(styles.rating__site, styles.rating__site_forward)

  const backStart = () => {
    const index = idx - 1
    setIdx(index)
    console.log(idx, 'назад')
  }

  const forStart = () => {
    const index = idx + 1
    setIdx(index)
    console.log(idx, 'вперед', list.length)
  }

  return (
    <div className={styles.rating__sites}>
      <button
        type="button"
        className={backward}
        disabled={idx ? false : true}
        onClick={backStart}
      >
        <ArrowIcon />
      </button>
      <button
        type="button"
        className={forward}
        disabled={idx === list.length - 1 ? true : false}
        onClick={forStart}
      >
        <ArrowIcon />
      </button>
    </div>
  )
}

export default SitesRatings
