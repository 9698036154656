import React from 'react'
import { ReactComponent as StarIcon } from 'assets/images/rating/star.svg'
import defaultAvatar from '../../../assets/images/other/small-bot.png'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'

const StateRatings = ({
  user,
  total,
  styles,
  isMyRating,
  classes,
  numberFormat,
  index,
  list,
}) => {
  const toggleColors = (progress) => {
    if (progress >= 0 && progress < 20) return 'var(--progress-one)'
    if (progress >= 20 && progress < 60) return 'var(--progress-two)'
    if (progress >= 60 && progress < 100) return 'var(--progress-three)'
    if (progress === 100) return 'var(--progress-four)'
  }

  const colors = [
    {
      star: '#F57F17',
      back: '#FBC02D',
    },
    {
      star: '#5D727B',
      back: '#97B1BD',
    },
    {
      star: '#6D5416',
      back: '#9E791D',
    },
  ]

  return (
    <>
      {user && (
        <div
          className={classNames(
            styles.rating__table,
            styles.rating__table_color
          )}
        >
          <div className={styles.rating__container}>
            <img
              className={styles.rating__avatar}
              src={
                user.avatar
                  ? `${process.env.REACT_APP_API_URL}${user.avatar}`
                  : defaultAvatar
              }
              alt="Avatar"
            />
            <div className={classes.contents}>
              <p
                className={classNames(
                  styles.rating__text,
                  styles.rating__text_name
                )}
              >
                {user.name}
              </p>
              {isMyRating ? (
                <p className={styles.rating__text}>
                  <b>{numberFormat(user.place)}</b>
                  <Trans i18nKey="rating-place-of" />
                  <b>{total}</b>
                </p>
              ) : (
                <>
                  {index < 3 && list === 0 ? (
                    <div
                      className={styles.rating__place}
                      style={{
                        background: colors[index].back,
                      }}
                    >
                      <StarIcon
                        className={styles.rating__icon}
                        style={{
                          fill: colors[index].star,
                        }}
                      />
                      <p
                        className={classNames(
                          styles.rating__top,
                          styles.rating__top_color
                        )}
                      >
                        {numberFormat(user.place)} место
                      </p>
                    </div>
                  ) : (
                    <p className={styles.rating__text}>
                      <b>{numberFormat(user.place)} место</b>
                    </p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={classes.containers}>
            <div className={styles.rating__content}>
              <p className={styles.rating__name}>
                {/*{t('rating-points')}*/}
                <Trans i18nKey="rating-points" />
              </p>
              <p className={styles.rating__text}>
                <b>{numberFormat(user.scores)}</b>
              </p>
            </div>
            <div className={styles.rating__content}>
              <p className={styles.rating__name}>
                {/*{t('rating-portfolio')}*/}
                <Trans i18nKey="rating-portfolio" />
              </p>
              <p className={styles.rating__text}>
                <b>+ {numberFormat(user.portfolio)}</b>
              </p>
            </div>
          </div>

          <div className={classes.contents}>
            <p className={styles.rating__text}>
              {/*{t('rating-progress')}*/}
              <Trans i18nKey="rating-progress" />
            </p>
            <div className={styles.rating__progresses}>
              <div
                className={styles.rating__progress}
                style={{
                  background: toggleColors(user.progress),
                  width: `${user.progress}%`,
                }}
              >
                <span className={styles.rating__percent}>{user.progress}%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default StateRatings
