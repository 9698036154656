import React, { forwardRef, useMemo, useState } from 'react'

import emojiDrop from 'assets/images/main-page/author-course/emoji-drop.png'
import emojiFire from 'assets/images/main-page/author-course/emoji-fire.png'
import emojiHands from 'assets/images/main-page/author-course/emoji-hands.png'
import emojiHat from 'assets/images/main-page/author-course/emoji-hat.png'
import emojiLens from 'assets/images/main-page/author-course/emoji-lens.png'
import emojiMoney from 'assets/images/main-page/author-course/emoji-money.png'
import emojiTime from 'assets/images/main-page/author-course/emoji-time.png'
import emojiUser from 'assets/images/main-page/author-course/emoji-user.png'
import head from 'assets/images/main-page/author-course/head.png'
import { useTranslation } from 'react-i18next'

const images = {
  head,
  'emoji-time': emojiTime,
  'emoji-hat': emojiHat,
  'emoji-fire': emojiFire,
  'emoji-user': emojiUser,
  'emoji-money': emojiMoney,
  'emoji-lens': emojiLens,
  'emoji-hands': emojiHands,
  'emoji-drop': emojiDrop,
}

const AboutAuthorSection = forwardRef((props, ref) => {
  const { data } = props

  const skillsOnScreen = 5
  const skillsPageNumber = Math.ceil(data.skills.length / skillsOnScreen)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  const paginatedData = useMemo(() => {
    return data.skills.filter((skill, index) => index < page * skillsOnScreen && index >= (page - 1) * skillsOnScreen)
  }, [page])

  const onShowMore = () => {
    if (skillsPageNumber === page) return setPage(1)
    else setPage((page) => page + 1)
  }

  return (
    <section className="author-course section-padding" ref={ref}>
      <div className="container">
        <div className="author-course__title title">
          <h2>
            {t(data.title)} <span>{t(data.fullName)}</span>
          </h2>
        </div>
        <div className="author-course__wrap">
          <div className="author-course__img-wrap">
            <img src={head} width="430" height="430" alt="head" />
          </div>
          <ul className="list">
            {paginatedData.map((item) => (
              <li key={item.text}>
                <img src={images[item.iconName]} width="45" height="45" alt={item.iconName} />
                <p>{t(item.text)}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="author-course__link-wrap">
          <button type="button" onClick={onShowMore}>
            {t('Что ещё')} <span className="btn-arrow btn-arrow_right"></span>
          </button>
        </div>
      </div>
    </section>
  )
})

export default AboutAuthorSection
