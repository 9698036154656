export function transformApiValidation(data) {
  if (data.message) return [{ message: data.message }]

  if (data.messages) return data.messages
}

// export function transformApiValidation(errors) {

//   if (!Array.isArray(errors)) return {};

//   return errors.reduce((acc, error) => {
//     acc[error.field] = error.message;
//     return acc;
//   }, {});
// }
